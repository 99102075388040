import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Grid, Paper } from '@material-ui/core';
import HomeHeader from './ui_elements/home/HomeHeader';
import Meta from "./Meta";
// import MortgageCalculator from "mortgage-calculator-react";
import NewMortgageCalculator from "./MortgageCalculator";
import Calculators from "./Calculators";
import Newsletter from "./ui_elements/Newsletter";
import TopicsAndArticles from "./TopicsAndArticles";
import Faqs from "./Faqs";
import { House, AttachMoney, LocalAtm, CreditCard, DriveEta, HomeWork } from '@material-ui/icons';

import ReactPWAInstallProvider from "react-pwa-install";
import PWA from "./PWAinstall";

const styles = theme => ({
  grid: {
    minWidth: "100vw"
    // flexGrow: 1,
    // maxWidth: '1000px',
    // margin: '0 auto',
    // padding: "10px"
  },
  paper: {
    // padding: theme.spacing(2),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
  },
  text: {
    fontWeight: "100",
    padding: "25px",
    backgroundColor: "#263238",
    color: "white",
    fontSize: "12px"
  },
  image: {
    height: "auto",
    width: "100vw",
  },
  items: {
    fontWeight: "100",
    fontSize: "12px",
    padding: "8px"
  }
})


class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: '',
      state: (this.props.location !== undefined && this.props.location.pathname !== undefined) ? this.props.location.pathname.split('/')[1].split('/')[0] : null,
      navigation: this.props.location
    };
  }

  componentDidMount = () => {
    this.setState({
      isLoaded: true
    })
  }

  render() {
    const { error, isLoaded } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      const { classes } = this.props;
      return (
        <div className={classes.root}>
          <Meta
            screen={"address"}
            title={`${this.state.state} Mortgage Calculator`}
            description={"Check out the web's best free mortgage calculator to save money on your home loan today. Estimate your monthly payments with PMI, taxes, homeowner's insurance, HOA fees, current loan rates & more. Also offers loan performance graphs, biweekly savings comparisons and easy to print amortization schedules. Our calculator includes amoritization tables, bi-weekly savings estimates, refinance info, current rates and helpful tips. This site also provides users with many helpful online financial planning tools."}
            keywords={"calculator, mortgage calculator, home, mortgage rates, mortgage payment calculator, simple mortgage calculator, mortgage loan calculator, refinance calculator, mortgage rate calculator, loan calculator, insurance, amortization, easy, mortgage, assessment, loan, annual, amount, payments, monthly, fixed, PMI, rates"}
            image={"https://images.unsplash.com/photo-1451934403379-ffeff84932da?ixlib=rb-1.2.1&auto=format&fit=crop&w=1242&q=80"}
            url={`https://Bankzia.com`}
          />
          <HomeHeader />

          <Grid className={classes.grid} container spacing={0}>
            <Grid item xs={12}>
              <Calculators
                title={"Maximize your savings with our financial calculators"}
                byline={"Our team offers several great calculators to assist with your next home, auto, boat, rental, lease, or affordability calculation."}
                loanType={"home"}
                layout={"minimal"}
                minimalTitle={"Which mortgage is right for you?"}
                minimalByline={"Is a 15 Year or 30 Year mortgage in your families future?"}
                image={"https://images.unsplash.com/photo-1606787842514-a7998e6bee38?ixid=MXwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"}
                data={[{
                  name: "Mortgage",
                  byline: "Calculate your future mortgage payments.",
                  icon: "",
                  type: "home"
                }, {
                  name: "Mortgage affordability",
                  byline: "Find a home price that best suits your budget.",
                  icon: "",
                  type: "home"
                }, {
                  name: "Amortization",
                  byline: "Determine how much of your monthly mortgage payment goes towards interest/",
                  icon: "",
                  type: "home"
                }, {
                  name: "Rental affordability",
                  byline: "Find a rental unit that best suits your budget using our rental affordability calculator.",
                  icon: "",
                  type: "home"
                }, {
                  name: "Auto",
                  byline: "Calculate your future car loan.",
                  icon: "",
                  type: "auto"
                }, {
                  name: "Auto affordability",
                  byline: "Determine what auto loan best fits your budget.",
                  icon: "",
                  type: "auto"
                }, {
                  name: "Auto lease affordability",
                  byline: "Find an auto lease that best suits your budget.",
                  icon: "",
                  type: "auto"
                }]}
              />
              <Calculators
                title={"min rev"}
                byline={"Our team offers several great calculators to assist with your next home, auto, boat, rental, lease, or affordability calculation."}
                loanType={"home"}
                layout={"minimal"}
                direction={'reverse'}
                minimalTitle={"Which mortgage is right for you?"}
                minimalByline={"Is a 15 Year or 30 Year mortgage in your families future?"}
                image={"https://images.unsplash.com/photo-1606787842514-a7998e6bee38?ixid=MXwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"}
                data={[{
                  name: "Mortgage",
                  byline: "Calculate your future mortgage payments.",
                  icon: "",
                  type: "home"
                }, {
                  name: "Mortgage affordability",
                  byline: "Find a home price that best suits your budget.",
                  icon: "",
                  type: "home"
                }, {
                  name: "Amortization",
                  byline: "Determine how much of your monthly mortgage payment goes towards interest/",
                  icon: "",
                  type: "home"
                }, {
                  name: "Rental affordability",
                  byline: "Find a rental unit that best suits your budget using our rental affordability calculator.",
                  icon: "",
                  type: "home"
                }, {
                  name: "Auto",
                  byline: "Calculate your future car loan.",
                  icon: "",
                  type: "auto"
                }, {
                  name: "Auto affordability",
                  byline: "Determine what auto loan best fits your budget.",
                  icon: "",
                  type: "auto"
                }, {
                  name: "Auto lease affordability",
                  byline: "Find an auto lease that best suits your budget.",
                  icon: "",
                  type: "auto"
                }]}
              />
              <NewMortgageCalculator title={"Compare current mortgage rates for today."} navigation={this.state.navigation} />
              <Calculators
                title={"Calculators."}
                byline={"Our team offers several great calculators to assist with your next home, auto, boat, rental, lease, or affordability calculation."}
                loanType={"auto"}
                layout={"grid"}
                data={[{
                  name: "Mortgage",
                  byline: "Calculate your future mortgage payments.",
                  icon: <House />,
                  type: "home"
                }, {
                  name: "Mortgage affordability",
                  byline: "Find a home price that best suits your budget.",
                  icon: <House />,
                  type: "home"
                }, {
                  name: "Amortization",
                  byline: "Determine how much of your monthly mortgage payment goes towards interest/",
                  icon: "",
                  type: "home"
                }, {
                  name: "Rental affordability",
                  byline: "Find a rental unit that best suits your budget using our rental affordability calculator.",
                  icon: "",
                  type: "home"
                }, {
                  name: "Auto",
                  byline: "Calculate your future car loan.",
                  icon: "",
                  type: "auto"
                }, {
                  name: "Auto affordability",
                  byline: "Determine what auto loan best fits your budget.",
                  icon: "",
                  type: "auto"
                }, {
                  name: "Auto lease affordability",
                  byline: "Find an auto lease that best suits your budget.",
                  icon: "",
                  type: "auto"
                }]}
              />
              <Faqs
                layout={"grid"}
                title={"FAQ's Grid"}
                byline={"Do you have some questions?"}
                titleTextColor={"black"}
                cellTextColor={"black"}
                backgroundColor={"white"}
                cellColor={"#f2f2f2"}
                faqs={JSON.stringify(
                  [{
                    title: "faq 1",
                    body: "this is 1 the body"
                  }, {
                    title: "faq 2",
                    body: "this is 2 the body"
                  }, {
                    title: "faq 3",
                    body: "this is 3 the body"
                  }, {
                    title: "faq 4",
                    body: "this is 4 the body"
                  }])
                }
              />
              <Faqs
                layout={"accordion"}
                title={"FAQ's Accordion"}
                byline={"Do you have some questions?"}
                backgroundColor={"white"}
                cellColor={"white"}
                faqs={JSON.stringify([{
                  title: "What are today's mortgage rates?",
                  body: ""
                }, {
                  title: "Will mortgage rates keep dropping?",
                  body: ""
                }, {
                  title: "faq 3",
                  body: "this is 3 the body"
                }, {
                  title: "faq 4",
                  body: "this is 4 the body"
                }])
                }
              />
              <Faqs
                layout={"expanded"}
                title={"FAQ's list"}
                byline={"Do you have some questions?"}
                backgroundColor={"white"}
                cellColor={"#f2f2f2"}
                faqs={JSON.stringify([{
                  title: "What are today's mortgage rates?",
                  body: ""
                }, {
                  title: "Will mortgage rates keep dropping?",
                  body: ""
                }, {
                  title: "faq 3",
                  body: "this is 3 the body"
                }, {
                  title: "faq 4",
                  body: "this is 4 the body"
                }])
                }
              />
              <TopicsAndArticles
                title={"New articles published daily"}
                byline={"Read some of our latest articles to help guide you on your next home buying adventure."}
                contentType={"articles"}
                layout={"grid"}
                titleTextColor={"black"}
                cellTextColor={"black"}
                backgroundColor={"#eee"}
                cellColor={"white"}
                data={[{
                  name: "How requesting a credit limit increase affects your credit",
                  author: "Tina Jones",
                  date: "1/16/2021",
                  image: "https://source.unsplash.com/1600x900/?credit",
                  href: "/article/how-requesting-a-credit-limit-increase-affects-your-credit"
                }, {
                  name: "When to know if you should refinance your home loan",
                  author: "Hanks Toms",
                  date: "1/15/2021",
                  image: "https://source.unsplash.com/1600x900/?house",
                  href: "/article/When-to-know-if-you-should-refinance-your-home-loan"
                }, {
                  name: "Foreclosures fell to a record low in 2020",
                  author: "Paul Jones",
                  date: "1/13/2021",
                  image: "https://source.unsplash.com/1600x900/?foreclosure",
                  href: "/article/When-to-know-if-you-should-refinance-your-home-loan"
                }]}
              />
              <TopicsAndArticles
                title={"Popular Topics"}
                byline={"Check out some of our most popular topics."}
                loanType={"articles"}
                layout={"minimal"}
                titleTextColor={"black"}
                cellTextColor={"black"}
                backgroundColor={"white"}
                cellColor={"white"}
                direction={'reverse'}
                minimalTitle={"Get on track with your finances today"}
                minimalByline={"Give one of our free calculators a try to help direct your next financial move."}
                image={"https://source.unsplash.com/1600x900/?loan"}
                data={[{
                  name: "Purchase a home",
                  icon: <House />,
                  href: "/topics/home-purchase"
                }, {
                  name: "Refinance a home",
                  icon: <AttachMoney />,
                  href: "/topics/refinance"
                }, {
                  name: "Purchase a car",
                  icon: <DriveEta />,
                  href: "/topics/refinance"
                }, {
                  name: "Lease a car",
                  icon: <CreditCard />,
                  href: "/topics/refinance"
                }, {
                  name: "Save for retirement",
                  icon: <LocalAtm />,
                  href: "/topics/refinance"
                }, {
                  name: "Rent or lease a home",
                  icon: <HomeWork />,
                  href: "/topics/refinance"
                }]}
              />
              <TopicsAndArticles
                title={"Popular Topics"}
                byline={"Check out some of our most popular topics."}
                loanType={"articles"}
                layout={"minimal"}
                titleTextColor={"black"}
                cellTextColor={"black"}
                backgroundColor={"white"}
                cellColor={"white"}
                minimalTitle={"Get on track with your finances today"}
                minimalByline={"Give one of our free calculators a try to help direct your next financial move."}
                image={"https://source.unsplash.com/1600x900/?loan"}
                data={[{
                  name: "Purchase a home",
                  icon: <House />,
                  href: "/topics/home-purchase"
                }, {
                  name: "Refinance a home",
                  icon: <AttachMoney />,
                  href: "/topics/refinance"
                }, {
                  name: "Purchase a car",
                  icon: <DriveEta />,
                  href: "/topics/refinance"
                }, {
                  name: "Lease a car",
                  icon: <CreditCard />,
                  href: "/topics/refinance"
                }, {
                  name: "Save for retirement",
                  icon: <LocalAtm />,
                  href: "/topics/refinance"
                }, {
                  name: "Rent or lease a home",
                  icon: <HomeWork />,
                  href: "/topics/refinance"
                }]}
              />
              <TopicsAndArticles
                title={"New Articles"}
                byline={"Check out some of our newest articles."}
                layout={"minimal"}
                direction={'reverse'}
                titleTextColor={"black"}
                cellTextColor={"black"}
                backgroundColor={"white"}
                cellColor={"white"}
                minimalTitle={"Get on track with your finances today"}
                minimalByline={"Give one of our free calculators a try to help direct your next financial move."}
                image={"https://source.unsplash.com/1600x900/?loan"}
                data={[{
                  name: "How requesting a credit limit increase affects your credit",
                  author: "Tina Jones",
                  date: "1/16/2021",
                  image: "https://source.unsplash.com/1600x900/?credit",
                  href: "/article/how-requesting-a-credit-limit-increase-affects-your-credit"
                }, {
                  name: "When to know if you should refinance your home loan",
                  author: "Hanks Toms",
                  date: "1/15/2021",
                  image: "https://source.unsplash.com/1600x900/?house",
                  href: "/article/When-to-know-if-you-should-refinance-your-home-loan"
                }, {
                  name: "Foreclosures fell to a record low in 2020",
                  author: "Paul Jones",
                  date: "1/13/2021",
                  image: "https://source.unsplash.com/1600x900/?foreclosure",
                  href: "/article/When-to-know-if-you-should-refinance-your-home-loan"
                }, {
                  name: "Is now the time to buy?",
                  author: "Hanks Toms",
                  date: "1/11/2021",
                  image: "https://source.unsplash.com/1600x900/?purchase",
                  href: "/article/When-to-know-if-you-should-refinance-your-home-loan"
                }, {
                  name: "How to know if you are sitting on equity",
                  author: "Tina Jones",
                  date: "1/10/2021",
                  image: "https://source.unsplash.com/1600x900/?equity",
                  href: "/article/When-to-know-if-you-should-refinance-your-home-loan"
                }]}
              />
              <Newsletter />
              <Paper className={classes.text}>
                {/* <h2 style={{ textTransform: "capitalize" }}>{this.state.state} Nestzia Mortgage Calculator</h2> */}
                {/* <h3>Calculate your future mortgage today!</h3> */}
                {/* <MortgageCalculator/> */}
                <ReactPWAInstallProvider enableLogging>
                  <PWA />
                </ReactPWAInstallProvider>
              </Paper>
            </Grid>
          </Grid>
        </div>
      )
    }
  }
}
export default withStyles(styles, { withTheme: true })(Home);