import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Grid, Link } from '@material-ui/core';

const styles = theme => ({
    cardOverlay: {
        height: '140px',
        width: "220px",
        backgroundSize: "cover",
        backgroundPosition: "center",
        color: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        border: "0px solid transparent",
        borderRadius: "0px",
        borderBottomWidth: "8px",
        borderBottomColor: "white",
        '&:hover': {
            // backgroundColor: 'white',
            boxShadow: "0 9px 9px 0 rgba(21,21,21,.14), 0 16px 20px -5px rgba(21,21,21,.18)",
            transition: "border-color .1s ease-in,box-shadow .1s ease-in,color .1s ease-in",
            borderBottomWidth: "8px",
            borderBottomColor: "#0157ff",
            cursor: "pointer",
            // color: "#0157ff"
        },
      },

    headerCopy: {
        width: "220px",
        margin: "auto"
    },
    articleHeading: {
        color: "white",
        fontSize: "16px",
        fontWeight: "400",
        fontFamily: '"DM Serif Display",Garimond,serif',
        // letterSpacing: "0px",
        textAlign: "left",
        padding: "10px 15px 4px 15px",
        textShadow: "1px 2px #00143d",
        position: "relative",
        top: "25px",
        '&:hover': {
            textDecoration: "none"
        },

    },
    topic: {
        maxWidth: "200px",
        margin: "20px",
    },
})


class NavbarArticle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
    }

    // componentDidMount() {
    // PLACEHOLDER
    // }

    render() {
        const { classes } = this.props;
        console.log(this.state.data)
        return (
            <Grid item key={this.state.data.key} md={4} xs={12} className={classes.topic}>
                <Link href={`/article/${this.state.data.slug}`} className={classes.articleHeading}>
                <div style={{ backgroundImage: `linear-gradient(rgba(150, 150, 150, 0.3) 10%, rgba(0, 0, 0, 0.6) 70%), url(${this.state.data.thumbnail})` }} className={classes.cardOverlay}>
                    <div className={classes.headerCopy}>
                        <p className={classes.articleHeading}>{this.state.data.title}</p>
                    </div>
                </div>
                </Link>
            </Grid>
        )
    }
}
export default withStyles(styles, { withTheme: true })(NavbarArticle);

//   export default HomeHeader;