import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from '@material-ui/core';
import ArticleItem from "./article/ArticleListItem";
import ArticleFeatured from "./article/ArticleFeatured";
import postlist from "../../../posts.json";
import { v4 as uuidv4 } from 'uuid';

const styles = theme => ({
    root: {
        // marginTop: "30px",
        // marginBottom: "30px",
        maxWidth: "1100px",
        margin: "0 auto",
        flexGrow: 1,
        // border: "1px solid #eee",
        borderRadius: "0px",
        padding: "15px 30px 25px 30px",
        '@media screen and (max-width: 900px)': {
            border: "1px solid #eee",
            marginTop: "20px",
            margin: "0 auto",
            width: "90%",
            padding: "15px 0 20px 0",
        },
    },
    title: {
        fontFamily: "Chronicle Display,serif",
        fontSize: "35px",
        maxWidth: "80%",
        textAlign: "left",
        padding: "10px",
        '@media screen and (max-width: 700px)': {
            fontSize: "30px",
            maxWidth: "90%",
            padding: "15px"
        },
    },
    description: {
        fontSize: "15px",
        maxWidth: "70%",
        textAlign: "left",
        fontWeight: "400",
        padding: "10px",
        marginTop: "-15px",
        marginBottom: "15px",
        textTransform: "uppercase",
        '@media screen and (max-width: 700px)': {
            padding: "15px",
            maxWidth: "90%",
        },
    },

})

class Blogroll extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            index: -1,
            post: null,
            featuredtitle: ""
        };
    }

    setIndex() {
        let index = (this.state.index > postlist.length) ? 0 : this.state.index + 1;
        if (postlist[index] !== undefined) {
            this.setState({
                index: index,
                featuredtitle: postlist[index].title,
                post: postlist[index]
            });
        } else {
            this.setState({
                index: -1,
            });
        }
    }

    componentDidMount = () => {
        this.setIndex();
        this.loop = setInterval(() => this.setIndex(), 5000);
    }
    componentWillUnmount() {
        clearInterval(this.loop);
    }

    // handleHover(post) {
    //     alert(post)
    //     this.setState({
    //         post: post,
    //         featuredtitle: post.title,
    //     });
    // }

    render() {
        const { classes } = this.props;
        // alert(this.state.post)
        return (
            <div className={classes.root} style={{ backgroundColor: this.state.backgroundColor }}>
                <Grid container>
                    <Grid item md={7} sm={12}>
                        <Typography className={classes.title} variant="h2" component="h2" style={{ color: this.state.cellTextColor }}>{this.state.title}</Typography>
                        <Typography className={classes.description} variant="p" component="p" style={{ color: this.state.cellTextColor }}>{this.state.byline}</Typography>
                        <Grid container className={classes.container} spacing={2}>
                            {postlist.map((article, i) => (
                                (article.status === "Published") ?
                                    <Grid item md={12} onMouseEnter={() => this.setState({ featuredtitle: article.title, post: article })}>
                                        <ArticleItem key={uuidv4()} data={article} active={this.state.featuredtitle} />
                                    </Grid>
                                    : <></>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item md={5} sm={12}>
                        {(this.state.post !== null) ?
                            <ArticleFeatured key={uuidv4()} data={this.state.post} />
                        : <></>}
                    </Grid>
                </Grid>
            </div>
        )
    }
}
export default withStyles(styles, { withTheme: true })(Blogroll);