import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Card, CardMedia, Link } from '@material-ui/core';
import Meta from "../components/Meta";
import postlist from "../posts.json";
import authorlist from "../authors.json";
import { Redirect } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

// import { House, AttachMoney, LocalAtm, CreditCard, DriveEta, HomeWork, Policy, MonetizationOn } from '@material-ui/icons';

// import ReactPWAInstallProvider from "react-pwa-install";
// import PWA from "../components/PWAinstall";

const styles = theme => ({
    root: {
        marginBottom: "30px"
    },
    container: {
        maxWidth: "70%",
        margin: "0 auto",
        '@media screen and (max-width: 700px)': {
            maxWidth: "99%",
        }
    },
    title: {
        fontFamily: "'DM Serif Display',Garamond,serif",
        fontWeight: 600,
        lineHeight: 1,
        fontSize: "2.3rem",
        textAlign: "left",
        letterSpacing: "-1px",
        maxWidth: "1300px",
        width: "80%",
        margin: "0 auto",
        padding: "50px 20px 15px 20px",
        color: "black",
        textTransform: "capitalize",
        // marginTop: "60px",
        '@media screen and (max-width: 700px)': {
            // width: "95%",
            fontSize: "2rem"
        }
    },
    description: {
        borderBottom: "1px solid black",
        textAlign: "left",
        letterSpacing: "-1px",
        maxWidth: "1300px",
        width: "80%",
        margin: "0 auto",
        marginBottom: "-40px",
        padding: "0px 20px 15px 20px",
        color: "black",
        '@media screen and (max-width: 700px)': {
            // width: "95%",
            fontSize: "15px"
        }
    },
    topicContainer: {
        maxWidth: "1000px",
        magin: "0 auto",
    },
    topic: {
        maxWidth: "300px",
        margin: "15px",
    },
    card: {
        minHeight: "440px"
    },
    media: {
        height: '250px', // 16:9
        backgroundAttachment: "static", // "fixed"
        backgroundPosition: "center",
        '@media screen and (max-width: 700px)': {
            height: "250px",
            //   height: "100vh"
        }
    },
    articleHeading: {
        fontSize: "16px",
        fontWeight: "400",
        letterSpacing: "0px",
        textAlign: "left",
        padding: "10px 15px 4px 15px",
        color: "black"
    },
    articleByline: {
        fontSize: "13px",
        letterSpacing: "0px",
        textTransform: "capitalize",
        textAlign: "left",
        padding: "5px 15px 1px 15px",
        color: "black"
    },

})

class Authors extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: '',
            slug: (this.props.location !== undefined && this.props.location.pathname !== undefined) ? this.props.location.pathname.split('/')[2].split('/')[0].replace(/-/g, ' ') : null,
            navigation: this.props.location,
            posts: postlist,
            authors: authorlist,
            // topicPosts: [],
            authorsPosts: []
        };
        console.log(this.state.slug)
    }

    componentDidMount = () => {
        const getData = async () => {
            this.state.authors.map((author) => {
                if (this.state.slug.includes(author.data.first.toLowerCase())){
                    this.setState({
                        author: `${author.data.first} ${author.data.last}`,
                        seo_title: author.data.seo_title,
                        thumbnail: author.data.thumbnail,
                        bio: author.content
                    })
                }
                return author
            })
        }
        getData().then(() => {
            this.state.posts.map((post) => (
                (post.author !== undefined && post.author !== null)? 
                    post.author.map((author) => {
                    if (String(author.toLowerCase()) === String(this.state.slug.toLowerCase())) {
                        this.state.authorsPosts.push(post)
                        this.setState({
                            isLoaded: true,
                        })
                        return true
                    }
                    return true
                })
                : null
            ))
        })
    }

    render() {
        const { error, isLoaded } = this.state;
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            const { classes } = this.props;
            if (this.state.authorsPosts.length > 0) {
                return (
                    <div className={classes.root}>
                        <Meta
                            screen={"topic"}
                            title={`Author - ${this.state.author}`}
                            description={`Author - ${this.state.bio}`}
                            keywords={`Author - ${this.state.seo_title}`}
                            image={this.state.thumbnail}
                            url={`https://bankzia.com/author/${this.state.navigation.pathname.split('/')[2].split('/')[0]}`}
                        />
                        <Grid container>
                            <Grid item md={12} className={classes.container}>
                                <Typography className={classes.title} variant="h1" component="h1">{this.state.slug}</Typography>
                                <Typography className={classes.description} variant="p" component="p">Nestzia offers a wide variety of financial calculators to help our readers make more educated financial decisions.</Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="center" style={{ marginTop: "80px" }}>
                            {this.state.authorsPosts.map((post, i) => (
                                <Grid key={uuidv4()} item md={4} xs={12} className={classes.topic}>
                                    <Card className={classes.card} variant="outlined">
                                        <Link href={window.location.origin + "/article/" + post.slug}>
                                            <CardMedia image={(post.thumbnail.includes('https://'))? post.thumbnail : "https://bankzia.com" + post.thumbnail} className={classes.media} />
                                            <div className={classes.overlay}>
                                                <Typography className={classes.articleHeading} variant="h5" component="h5">{post.title}</Typography>
                                                <Typography className={classes.articleByline} variant="p" component="p"><strong>By:</strong> {post.author} | <strong>Published:</strong> {post.date}</Typography>
                                                <Typography className={classes.articleByline} variant="p" component="p" style={{ paddingBottom: "25px" }}>{post.excerpt.substring(0, 100)}</Typography>
                                            </div>
                                        </Link>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>


                        {/* <Faqs
                        layout={"expanded"}
                        // title={"Calculators"}
                        // byline={"Nestzia offers a wide variety of financial calculators to help our readers make more educated financial decisions."}
                        backgroundColor={"white"}
                        cellColor={"white"}
                        faqs={JSON.stringify([{
                            title: "Buying a home, refinancing, renting, renovating?",
                            body: "Our relestate calculators make it easy to make smarter financial decision the next time you purchase a home, refinance for a lower rate, rent an apartment, or decide to do any renovations."
                        }])
                        }
                    /> */}
                    </div>
                )
            } else {
                return <Redirect to="/404" />
            }
        }
    }
}
export default withStyles(styles, { withTheme: true })(Authors);