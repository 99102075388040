import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Grid, TextField, FormControlLabel, Switch } from '@material-ui/core';
// import AdSense from 'react-adsense';
import { Doughnut } from 'react-chartjs-2';

// import FavoritesButton from './FavoritesButton';
// import { StickyContainer, Sticky } from 'react-sticky';
// import { Share } from '@material-ui/icons';
// import { v4 as uuidv4 } from 'uuid';

const styles = theme => ({
    root: {
        // flexGrow: 1,
        // maxWidth: "100%",
        // overflowX: "hidden"
    },
    container: {
        maxWidth: "1300px",
        margin: "0 auto",
        padding: "10px 0 155px 0"
    },
    row: {
        marginTop: "15px",
        marginBottom: "15px"
    },
    paper: {
        color: "blue",
        fontSize: "11px",
        fontWight: "200"
    },
    control: {
        padding: theme.spacing(2),
    },
    heading: {
        fontFamily: "'DM Serif Display',Garamond,serif!important",
        fontWeight: 600,
        lineHeight: 1,
        fontSize: "3.6rem",
        textAlign: "left",
        letterSpacing: "-1px",
        maxWidth: "1000px",
        margin: "0 auto",
        padding: "40px",
        '@media screen and (max-width: 900px)': {
            fontSize: "1.8rem",
            // padding: "25px 20px 5px 20px",
            padding: "40px 30px 10px 30px",
            // textAlign: "center"
        }
    },
    fields: {
        maxWidth: "1000px",
        margin: "0 auto",
        marginTop: "15px",
        marginBottom: "15px",
        '@media screen and (min-width: 1200px)': {
            marginTop: "25px"
        }
    },
    field: {
        '@media screen and (max-width: 900px)': {
            padding: "0 20px",
            // margin: "0 auto"
        }
    },
    monthlyPayment: {
        backgroundColor: "#00143d",
        color: "white",
        width: "100vw !important",
        marginTop: "50px",
        marginBottom: "0px",
        // margin: "0 auto",
        // fontFamily: "'CircularStd-Book',Arial,sans-serif!important",
        fontWeight: 400,
        // lineHeight: 1,
        // fontSize: "20px",
    },
    byline: {
        textAlign: "left",
        marginTop: "-20px",
        maxWidth: "1000px",
        margin: "0 auto",
        '@media screen and (max-width: 900px)': {
            fontSize: "14px",
            padding: "20px 30px",
        },
        '@media screen and (min-width: 1200px)': {
            marginBottom: "40px"
        }
    },






    // CHARTS 
    chartHeadingContainer: {
        maxWidth: "350px",
        margin: "0 auto",
        '@media screen and (max-width: 900px)': {
            maxWidth: "100%",
            padding: "30px"
        }
    },
    chartHeading: {
        fontFamily: "'DM Serif Display',Garamond,serif!important",
        fontWeight: 600,
        fontSize: "28px",
        marginBottom: "-15px",
        textAlign: "center"
    },
    chartByline: {
        fontSize: "18px",
        fontWeight: 100,
        // textAlign: "left"
    },
    chartTotal: {
        fontFamily: "'DM Serif Display',Garamond,serif!important",
        fontWeight: 600,
        fontSize: "28px",
        marginTop: "-10px",
        textAlign: "center"
    },
    chartRecomended: {
        fontSize: "15px",
        fontWeight: "100",
        padding: "12px",
        color: "white"
    },
    relative: {
        position: "relative",
        maxWidth: "500px",
        padding: "30px"
    },
    absolutecenter: {
        position: "absolute",
        top: "50%",
        right: "50%",
        transform: "translate(-53%, -50%)",
        textAlign: "center"
    },
    pietext: {
        fontSize: "24px",
        fontWeight: 800
    }



})

class AutoCalculator extends React.Component {
    constructor(props) {
        super(props);
        // let navigation = props.navigation;
        this.state = {
            ...props,
            error: null,
            isLoaded: false,
            state: props.state,
            fullreport: false
        };
    }

    componentDidMount = () => {
        // this.setState({
        //     down_payment_percent: Number((this.state.down_payment / this.state.home_price) * 100),
        //     monthly_interest_rate: (this.state.interest_rate / 12),
        // });
    }


    render() {
        const { classes } = this.props;

        var totalCost = this.state.data.price - this.state.data.down_payment - this.state.data.trade_in - this.state.data.rebates + Number(this.state.data.dealer_fee);
        var salesTax = totalCost * (this.state.data.sales_tax / 100);
        var step1 = ((this.state.data.percentage / 100) / 12);
        var step2 = (step1 * Math.pow((step1 + 1.0), this.state.data.loan_term));
        var step3 = Math.pow((step1 + 1.0), this.state.data.loan_term) - 1;
        var step4 = step2 / step3;
        var final = (totalCost + salesTax) * step4;
        var price = Math.round(parseFloat(final))

        const handleChange = (event) => {
            // console.log(event.target.name)
            this.setState({ [event.target.name]: !this.state[event.target.name] });
        };
        const options = {
            maintainAspectRatio: true,
            responsive: true,
            legend: {
                position: 'right',
                labels: {
                    boxWidth: 15
                },
            },
            centertext: "123"
        }

        var chartColors = ["#99CCFF", "#007CC7", "#4DA8DA", "#203647", "#EEFBFB", "#336699", "#3399FF", "#993300", "#CCCC99", "#666666", "#FFCC66", "#6699CC", "#663366", "#9999CC", "#CCCCCC", "#669999", "#CCCC66", "#CC6600", "#9999FF", "#0066CC", "#99CCCC", "#999999", "#FFCC00", "#009999", "#99CC33", "#FF9900", "#999966", "#66CCCC", "#339966", "#CCCC33", "#003f5c", "#665191", "#a05195", "#d45087", "#2f4b7c", "#f95d6a", "#ff7c43", "#ffa600", "#EF6F6C", "#465775", "#56E39F", "#59C9A5", "#5B6C5D", "#0A2342", "#2CA58D", "#84BC9C", "#CBA328", "#F46197", "#DBCFB0", "#545775"];
        const totalCostPie = {
            maintainAspectRatio: true,
            responsive: true,
            labels: ["Vehicle Price", "Sales Tax", "Trade In", "Down Payment", "Rebates", "Dealer Fee"],
            datasets: [
                {
                    label: 'Total Cost',
                    data: [this.state.data.price, salesTax, this.state.data.trade_in, this.state.data.down_payment, this.state.data.rebates, this.state.data.dealer_fee],
                    backgroundColor: chartColors,
                    hoverBackgroundColor: chartColors
                }
            ],
        };

        const monthlyCostPie = {
            maintainAspectRatio: true,
            responsive: true,
            position: "bottom",
            labels: ["dealer fee", "tax", "interest", "principal"],
            legend: {
                position: 'bottom',
                labels: {
                    boxWidth: 10
                }
            },
            datasets: [
                {
                    label: 'Monthly Breakdown',
                    data: [
                        Math.round(parseFloat((this.state.data.dealer_fee / this.state.data.loan_term))),
                        Math.round(parseFloat((totalCost * (this.state.data.sales_tax / 100) / this.state.data.loan_term))),
                        Math.round(parseFloat((step3 / step2))),
                        Math.round(parseFloat(price - (step3 / step2) - (totalCost * (this.state.data.sales_tax / 100) / this.state.data.loan_term) - (this.state.data.dealer_fee / this.state.data.loan_term)))
                    ],
                    backgroundColor: chartColors,
                    hoverBackgroundColor: chartColors,
                    legendPosition: "bottom",
                    borderWidth: 1,
                }
            ]
        };

        const totalExpenseAffordabilityChart = {
            maintainAspectRatio: true,
            responsive: true,
            position: "bottom",
            labels: ["Vehicle Price", "Housing Cost", "Expenses", "Income"],
            legend: {
                position: 'bottom',
                labels: {
                    boxWidth: 10
                }
            },
            datasets: [
                {
                    label: 'Monthly Breakdown',
                    data: [
                        price,
                        Math.round(parseFloat((this.state.data.housing))),
                        Math.round(parseFloat((this.state.data.expenses))),
                        Math.round(parseFloat((this.state.data.income))),
                    ],
                    backgroundColor: chartColors,
                    hoverBackgroundColor: chartColors,
                    legendPosition: "bottom",
                    borderWidth: 1,
                }
            ]
        };

        const totalVehicleAffordabilityChart = {
            maintainAspectRatio: true,
            responsive: true,
            position: "bottom",
            labels: ["Recommended Budget", "Income"],
            legend: {
                position: 'top',
                labels: {
                    boxWidth: 10
                }
            },
            datasets: [
                {
                    label: 'Monthly Breakdown',
                    data: [
                        Math.round(parseFloat((this.state.data.income * 0.14))),
                        Math.round(parseFloat((this.state.data.income))),
                    ],
                    backgroundColor: chartColors,
                    hoverBackgroundColor: chartColors,
                    legendPosition: "top",
                    borderWidth: 1,
                }
            ]
        };

        // both, monthly, total, can I afford 
        return (
            <Grid container className={classes.root} spacing={2}>
                <Grid container sm={12} className={classes.container}>
                    <Grid item xs={12} style={{ padding: "0 0 25px 0" }}>
                        <h1 className={classes.heading}>{this.state.data.title}</h1>
                        <p className={classes.byline}>{this.state.data.byline}</p>
                        {/*<AdSense.Google
                            client='ca-pub-6183357078733821'
                            slot='7541418211'
                            />*/}
                    </Grid>
                    <Grid item lg={3} md={4} sm={12} xs={12} className={classes.fields}>
                        <Grid container justify="center" spacing={2}>
                            <Grid item md={12}>
                                <TextField
                                    label="Vehicle Price"
                                    variant="outlined"
                                    type="number"
                                    defaultValue={Number(this.state.data.price)}
                                    onChange={event => {
                                        const { value } = event.target;
                                        this.setState({
                                            data: {
                                                ...this.state.data,
                                                price: value
                                            }
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <TextField
                                    label="Down Payment"
                                    variant="outlined"
                                    type="number"
                                    value={this.state.data.down_payment}
                                    defaultValue={this.state.data.down_payment.toLocaleString()}
                                    onChange={event => {
                                        const { value } = event.target;
                                        this.setState({
                                            data: {
                                                ...this.state.data,
                                                down_payment: value
                                            }
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item md={12} sm={12}>
                                <TextField
                                    label="Interest Rate"
                                    variant="outlined"
                                    type="number"
                                    className={classes.field}
                                    defaultValue={this.state.data.percentage}
                                    onChange={event => {
                                        const { value } = event.target;
                                        this.setState({
                                            data: {
                                                ...this.state.data,
                                                percentage: value
                                            }
                                        })
                                    }}
                                />
                            </Grid>
                            {(this.state.data.type !== "affordability") ?
                                <Grid item md={12} sm={12}>
                                    <TextField
                                        label="Loan Term"
                                        variant="outlined"
                                        type="number"
                                        defaultValue={this.state.data.loan_term}
                                        onChange={event => {
                                            const { value } = event.target;
                                            this.setState({
                                                data: {
                                                    ...this.state.data,
                                                    loan_term: value
                                                }
                                            })
                                        }}
                                    />
                                </Grid>
                                : <></>
                            }
                            {(this.state.data.type === "affordability") ?
                                <Grid container spacing={2}>
                                    <Grid item md={12} sm={12}>
                                        <TextField
                                            label="Monthly Income"
                                            variant="outlined"
                                            type="number"
                                            className={classes.field}
                                            defaultValue={this.state.data.income}
                                            onChange={event => {
                                                const { value } = event.target;
                                                this.setState({
                                                    data: {
                                                        ...this.state.data,
                                                        income: value
                                                    }
                                                })
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={12} sm={12}>
                                        <TextField
                                            label="Monthly Housing"
                                            variant="outlined"
                                            type="number"
                                            className={classes.field}
                                            defaultValue={this.state.data.housing}
                                            onChange={event => {
                                                const { value } = event.target;
                                                this.setState({
                                                    data: {
                                                        ...this.state.data,
                                                        housing: value
                                                    }
                                                })
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={12} sm={12}>
                                        <TextField
                                            label="Monthly Expenses"
                                            variant="outlined"
                                            type="number"
                                            className={classes.field}
                                            defaultValue={this.state.data.expenses}
                                            onChange={event => {
                                                const { value } = event.target;
                                                this.setState({
                                                    data: {
                                                        ...this.state.data,
                                                        expenses: value
                                                    }
                                                })
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                : <></>
                            }
                            <Grid item md={12} sm={12}>
                                <FormControlLabel
                                    className={classes.field}
                                    control={<Switch checked={this.state.advanced} onChange={handleChange} name="advanced" color="primary" />}
                                    label="Advanced"
                                />
                            </Grid>
                            <Grid item md={12} sm={12}>
                                <FormControlLabel
                                    className={classes.field}
                                    control={<Switch checked={this.state.fullreport} onChange={handleChange} name="fullreport" color="primary" />}
                                    label="Full Report"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={9} md={8} sm={12}>
                        <Grid container>
                            {(this.state.data.type === "both" || this.state.data.type === "total") ?
                                <Grid item md={6} sm={12}>
                                    <div className={classes.chartHeadingContainer} style={{ minHeight: "180px" }}>
                                        <h2 className={classes.chartHeading}>Total Purchase Price</h2>
                                        <p className={classes.chartByline} style={{ minHeight: "80px" }}>Total purchase cost breakdown including taxes, interest, and dealer fees.</p>
                                        <p className={classes.chartTotal}>${(price * this.state.data.loan_term).toLocaleString()}</p>
                                    </div>
                                    <div className={classes.relative}>
                                        <Doughnut data={totalCostPie} style={{ maxWidth: "200px" }} options={options} />
                                    </div>
                                </Grid>
                                : <></>
                            }
                            {(this.state.data.type === "both" || this.state.data.type === "monthly") ?
                                <Grid item md={6} sm={12}>
                                    <div className={classes.chartHeadingContainer} style={{ minHeight: "180px" }}>
                                        <h2 className={classes.chartHeading}>Total Monthly Payment</h2>
                                        <p className={classes.chartByline} style={{ minHeight: "80px" }}>Estimated monthly payment breakdown including principal, interest, tax, and dealer fees.</p>
                                        <p className={classes.chartTotal}>${price.toLocaleString()}</p>
                                    </div>
                                    <div className={classes.relative}>
                                        <Doughnut data={monthlyCostPie} style={{ maxWidth: "400px" }} options={options} />
                                    </div>
                                </Grid>
                                : <></>
                            }
                            {(this.state.data.type !== "both" && this.state.data.type === "affordability") ?
                                <Grid container>
                                    <Grid item md={6} sm={12}>
                                        <div className={classes.chartHeadingContainer} style={{ minHeight: "180px" }}>
                                            <h2 className={classes.chartHeading}>Monthly Cost ${price.toLocaleString()}</h2>
                                            <p className={classes.chartByline}>This vehicle will cost you roughly <strong>{Math.round(parseFloat((price / this.state.data.income) * 100)) + "%"}</strong> of your monthly income.</p>
                                            {(Math.round(parseFloat((price / this.state.data.income) * 100)) >= 14.8) ? <p style={{ backgroundColor: "#f64c72" }} className={classes.chartRecomended}>Not Recomended</p> : <p style={{ backgroundColor: "#203647" }} className={classes.chartRecomended}>Recommended</p>}
                                        </div>
                                        <div className={classes.relative}>
                                            <Doughnut data={totalExpenseAffordabilityChart} style={{ maxWidth: "400px" }} options={options} />
                                        </div>
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                        <div className={classes.chartHeadingContainer} style={{ minHeight: "180px" }}>
                                            <h2 className={classes.chartHeading}>Recommended Budget</h2>
                                            <p className={classes.chartByline}>It is recommended that indaviduals spend no more than 15% of their monthly income on a vehicle. We recommend that your auto payments should not exceed <strong>${Math.round(parseFloat((this.state.data.income * 0.14)))}</strong> per month.</p>
                                            {/* <p className={classes.chartTotal}>{Math.round(parseFloat((price / this.state.data.income) * 100)) + "% of Monthly Income"}</p> */}
                                        </div>
                                        <div className={classes.relative}>
                                            <Doughnut data={totalVehicleAffordabilityChart} style={{ maxWidth: "400px" }} options={options} />
                                        </div>
                                    </Grid>
                                </Grid>
                                : <></>
                            }
                        </Grid>
                    </Grid>
                    <Grid item sm={12}>
                        {(this.state.advanced === true) ?
                            <div>
                                <Grid container justify="center" spacing={2} className={classes.row}>
                                    {(this.state.data.type === "affordability") ?
                                        <Grid item md={2}>
                                            <TextField
                                                label="Loan Term"
                                                variant="outlined"
                                                type="number"
                                                defaultValue={this.state.data.loan_term}
                                                onChange={event => {
                                                    const { value } = event.target;
                                                    this.setState({
                                                        data: {
                                                            ...this.state.data,
                                                            loan_term: value
                                                        }
                                                    })
                                                }}
                                            />
                                        </Grid>
                                        : <></>
                                    }
                                    <Grid item md={2}>
                                        <TextField
                                            label="Rebates"
                                            variant="outlined"
                                            type="number"
                                            defaultValue={this.state.data.rebates.toLocaleString()}
                                            onChange={event => {
                                                const { value } = event.target;
                                                this.setState({
                                                    data: {
                                                        ...this.state.data,
                                                        rebates: value
                                                    }
                                                })
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={2}>
                                        <TextField
                                            label="Trade In"
                                            variant="outlined"
                                            type="number"
                                            defaultValue={this.state.data.trade_in.toLocaleString()}
                                            onChange={event => {
                                                const { value } = event.target;
                                                this.setState({
                                                    data: {
                                                        ...this.state.data,
                                                        trade_in: value
                                                    }
                                                })
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={2}>
                                        <TextField
                                            label="Sales Tax %"
                                            variant="outlined"
                                            type="number"
                                            defaultValue={this.state.data.sales_tax}
                                            onChange={event => {
                                                const { value } = event.target;
                                                this.setState({
                                                    data: {
                                                        ...this.state.data,
                                                        sales_tax: value
                                                    }
                                                })
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={2}>
                                        <TextField
                                            label="Dealer Fee"
                                            variant="outlined"
                                            type="number"
                                            defaultValue={this.state.data.dealer_fee.toLocaleString()}
                                            onChange={event => {
                                                const { value } = event.target;
                                                this.setState({
                                                    data: {
                                                        ...this.state.data,
                                                        dealer_fee: value
                                                    }
                                                })
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                            : <></>
                        }
                    </Grid>
                </Grid>
                {/* <Grid container xs={12} spacing={3} className={classes.monthlyPayment} style={{ padding: "50px" }}>
                    <Grid item xs={12}>
                        <span>{this.state.editing} Loan Amount: <strong>${Math.round(parseFloat(totalCost + salesTax).toLocaleString())}.00</strong></span><br></br>
                        <span>{this.state.editing} Estimated Monthly Payment: <strong>${price}.00</strong></span>
                    </Grid>
                </Grid> */}
                {(this.state.fullreport === true) ?
                    <Grid container md={12} xs={12} spacing={0} style={{ padding: "50px" }}>
                        <Grid item md={4}>
                            <p>{this.state.editing} Monthly Dealer Fee Payment: <strong>${Math.round(parseFloat((this.state.data.dealer_fee / this.state.data.loan_term)))}</strong></p>
                            <p>{this.state.editing} Monthly Sales Tax Payment: <strong>${Math.round(parseFloat((totalCost * (this.state.data.sales_tax / 100) / this.state.data.loan_term)))}.00</strong></p>
                        </Grid>
                        <Grid item md={4}>
                            <p>{this.state.editing} Monthly Interest Payment: <strong>${Math.round(parseFloat(step3 / step2))}.00</strong></p>
                            <p>{this.state.editing} Monthy Principal Payment: <strong>${Math.round(parseFloat(price - (step3 / step2) - (totalCost * (this.state.data.sales_tax / 100) / this.state.data.loan_term) - (this.state.data.dealer_fee / this.state.data.loan_term)))}.00</strong></p>
                        </Grid>
                        <Grid item md={4}>
                            <p>{this.state.editing} Loan Length: <strong>{this.state.data.loan_term / 12} Years</strong></p>
                            <p>{this.state.editing} Sales Tax: <strong>${Math.round(parseFloat(salesTax))}.00</strong></p>
                        </Grid>
                    </Grid>
                    : <></>
                }
                {/* <Grid item sm={6} xs={12}>
                        <FavoritesButton key={uuidv4()} metrics={""} />
                    </Grid>
                    <Grid item sm={6} xs={12} >
                        <Button variant="outlined" className={classes.share} onClick={() => {
                            navigator.clipboard.writeText(
                                // `${window.location.origin}/mortgage-calculator/${this.state.state}?home_price=${homeprice}&down_payment=${this.state.down_payment}&interest_rate=${this.state.interest_rate}&loan_term=${this.state.loan_term}&monthly_hoa=${this.state.monthly_hoa}&mortgage_insurance_rate=${this.state.mortgage_insurance_rate}&annual_insurance=${this.state.annual_insurance}&annual_tax=${this.state.annual_tax}&additional_principal_payment=${this.state.additional_principal_payment}`
                            )
                        }}>Share  <Share className={classes.shareIcon} /></Button>
                    </Grid> */}
            </Grid >
        )
    }
}
export default withStyles(styles, { withTheme: true })(AutoCalculator);