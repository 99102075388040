import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Link } from '@material-ui/core';

// ANIMATED POST LIST => LIST POST ITEMS TO THE LEFT OF THE FEATURED IMAGE

const styles = theme => ({
    title: {
        fontSize: "16px",
        fontWeight: "800",
        color: "black",
        letterSpacing: "0px",
        textAlign: "left",
        paddingRight: "20px",
        textTransform: "capitalized",
        whiteSpace: "wrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkitLineClamp": 1,
        "-webkitBoxOrient": "vertical"
    },
    description: {
        fontSize: "16px",
        textAlign: "left",
        color: "black",
        textTransform: "capitalized",
    },

})

class Blogroll extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
    }

    // componentDidMount = () => {
    //     console.log(this.state.data.posts)
    // }

    render() {
        const { classes } = this.props;
        return (
            <Grid item md={12}>
                <Link href={window.location.origin + "/article/" + this.state.data.slug} style={{ textDecoration: "none" }}>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item md={3}>
                            <img alt={this.state.data.title} src={(this.state.data.thumbnail.includes('https://')) ? this.state.data.thumbnail : "https://Bankzia.com" + this.state.data.thumbnail} width={100} />
                        </Grid>
                        <Grid item md={9}>
                            <Typography className={classes.title} variant="h5" component="h5" style={{fontWeight: (this.state.active === this.state.data.title) ? 600 : 400 }}>{this.state.data.title}</Typography>
                            <Typography className={classes.description} variant="p" component="p" style={{fontWeight: (this.state.active === this.state.data.title) ? 600 : 400 }}>On: {this.state.data.date}  By: {this.state.data.author}</Typography>
                        </Grid>
                    </Grid>
                </Link>
            </Grid>
        )
    }
}
export default withStyles(styles, { withTheme: true })(Blogroll);