import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Button } from '@material-ui/core';
// import HouseSharpIcon from '@material-ui/icons/HouseSharp';
// import EmojiTransportationSharpIcon from '@material-ui/icons/EmojiTransportationSharp';
// import MonetizationOnSharpIcon from '@material-ui/icons/MonetizationOnSharp';
// import AccountBalanceSharpIcon from '@material-ui/icons/AccountBalanceSharp';
// import { ThreeSixty } from '@material-ui/icons';

const styles = theme => ({
    button: {
        float: "left",
        minWidth: "100%",
        borderRadius: "0px",
        textTransform: "uppercase",
        border: "1px solid white",
        borderBottom: "1px solid #eee",
        color: "black",
        padding: "10px",
        "&:hover": {
            color:"blue",
            border: "1px solid blue"
        }
    },
    icon: {
        fontSize: "30px",
        marginBottom: "-5px",
        marginRight: "5px",
    },
})

class Post extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
    }

    // componentDidMount = () => {
    // }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Button variant="outlined" color="primary" className={classes.button} 
                    href={(this.state.data.slug)? `/topic/${this.state.data.slug}` : null} 
                    style={{ 
                        borderColor: this.state.data.buttonColor, 
                        color: this.state.data.buttonColor, 
                        // minWidth: (this.state.data.buttonWidth && this.state.data.buttonWidth === "full")? "100%" : null,
                        // margin: (this.state.data.buttonWidth && this.state.data.buttonWidth === "full")? "15px 0px" : "15px 10px",
                }}>{this.state.data.title}</Button>
            </div>
        )
    }
}
export default withStyles(styles, { withTheme: true })(Post);