import React from 'react';
import { withStyles } from "@material-ui/core/styles";
// import { Grid, Paper } from '@material-ui/core';
// import HomeHeader from '../components/HomeHeader';
import Meta from "../components/Meta";
// import MortgageCalculator from "mortgage-calculator-react";
import NewMortgageCalculator from "../components/MortgageCalculator";
// import Calculators from "../components/Calculators";
// import Newsletter from "../components/Newsletter";
// import TopicsAndArticles from "../components/TopicsAndArticles";
// import Faqs from "../components/Faqs";
// import { House, AttachMoney, LocalAtm, CreditCard, DriveEta, HomeWork } from '@material-ui/icons';

// import ReactPWAInstallProvider from "react-pwa-install";
// import PWA from "./PWAinstall";

const styles = theme => ({
  grid: {
    // flexGrow: 1,
    // maxWidth: '1000px',
    // margin: '0 auto',
    // padding: "10px"
  },
  paper: {
    // padding: theme.spacing(2),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
  },
  text: {
    fontWeight: "100",
    padding: "25px",
    backgroundColor: "#263238",
    color: "white",
    fontSize: "12px"
  },
  image: {
    height: "auto",
    width: "100vw",
  },
  items: {
    fontWeight: "100",
    fontSize: "12px",
    padding: "8px"
  }
})


class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: '',
      navigation: this.props.location,
      state: (this.props.location !== undefined && this.props.location.pathname !== undefined) ? this.props.location.pathname.split('/')[2].split('/')[0].replace(/-/g, ' ') : null,
      slug: (this.props.location !== undefined && this.props.location.pathname !== undefined) ? this.props.location.pathname.split('/')[1].split('/')[0] : null,
    };
  }

  componentDidMount = () => {
    this.setState({
      isLoaded: true
    })
  }

  render() {
    const { error, isLoaded } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      const { classes } = this.props;
      console.log(this.state.state)
      return (
          (this.state.state === "") ?
            <div className={classes.root}>
              <Meta
                screen={"Mortgage Calculator"}
                title={"Bankzia Mortgage Calculator"}
                description={'The best free mortgage calculator to estimate monthly payments, PMI, taxes, homeowners, HOA, loan rates and more.'}
                keywords={'calculator, mortgage calculator, home, mortgage rates, mortgage payment calculator, simple mortgage calculator, mortgage loan calculator'}
                image={'https://images.unsplash.com/photo-1606787365527-d5aee1f46968?ixid=MXwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'}
                url={`https://bankzia.com/mortgage-calculator/`}
              />
              <NewMortgageCalculator title={`Calculate your next mortgage today.`} byline={'The Bankzia mortgage calculator can help you calculate, save, and share your next mortgage estimate with others. Simply fill in the fields below and expand the advanced section if a more detailed calculation is desired.'} navigation={this.state.navigation} />
            </div>
            :
            <div className={classes.root}>
              <Meta
                screen={"Mortgage Calculator"}
                title={(this.state.state) ? `${this.state.state.replace(/\b[a-z]/g, (x) => x.toUpperCase())} Mortgage Calculator` : 'Mortgage Calculator'}
                description={'The best free mortgage calculator to estimate monthly payments, PMI, taxes, homeowners, HOA, loan rates and more.'}
                keywords={'calculator, mortgage calculator, home, mortgage rates, mortgage payment calculator, simple mortgage calculator, mortgage loan calculator'}
                image={'https://images.unsplash.com/photo-1606787365527-d5aee1f46968?ixid=MXwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'}
                url={`https://bankzia.com/mortgage-calculator/${this.state.state.replace(/ /g, '-')}/`}
                canonical={`https://bankzia.com/mortgage-calculator/${this.state.state.replace(/ /g, '-')}/`}
              />
              <NewMortgageCalculator state={this.state.state.replace(/\b[a-z]/g, (x) => x.toUpperCase())} title={`Compare current mortgage rates for today in ${this.state.state.replace(/\b[a-z]/g, (x) => x.toUpperCase())}.`} navigation={this.state.navigation} />
            </div>
      )
    }
  }
}
export default withStyles(styles, { withTheme: true })(Home);