import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';
// import ReactGA from 'react-ga';

// import { hydrate, render } from "react-dom";

// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   hydrate(<App />, rootElement);
// } else {
//   render(<App />, rootElement);
// }

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// ReactGA.initialize('UA-181751179-1');
// ReactGA.pageview(window.location.pathname + window.location.search);
// reportWebVitals((resp) => {
//   let performance = {
//     category: 'Web Vitals',
//     variable: resp.name,
//     value: Math.round(resp.name === 'CLS' ? resp.value * 1000 : resp.value), // values must be integers
//     label: resp.id, // id unique to current page load
//     nonInteraction: true, // avoids affecting bounce rate,
//   };
//   ReactGA.ga('send', 'pageview', '/mypage');
//   ReactGA.timing(performance);
// });


// let data = JSON.parse(window.performance.timing);
// let metrics = {
//   // everythingLoaded: Number(Date.now())-Number(data.timerStart),
//   // interactive: data.domInteractive - data.domLoading,
//   // dcl: data.domContentLoadedEventStart - data.domLoading,
//   // complete: data.domComplete - data.domLoading,
//   DOMContentLoaded: (data.domContentLoadedEventEnd - data.navigationStart) / 1000,
//   firstByteLoadedBefore: (data.responseStart - data.fetchStart) / 1000,
//   Load: (data.loadEventEnd - data.navigationStart) / 1000
// }
// console.log(metrics)

// ReactGA.timing({
//   category: 'Web Vitals',
//   variable: 'metrics',
//   // value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
//   DOMContentLoaded: (data.domContentLoadedEventEnd - data.navigationStart) / 1000,
//   firstByteLoadedBefore: (data.responseStart - data.fetchStart) / 1000,
//   Load: (data.loadEventEnd - data.navigationStart) / 1000,
//   label: 'test', // id unique to current page load
//   nonInteraction: true, // avoids affecting bounce rate
// });
