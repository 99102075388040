import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Card, CardMedia, Link } from '@material-ui/core';
import Meta from "../components/Meta";
import JsonLD from "../components/JsonLD";
import Markdown from "react-markdown";
import postlist from "../posts.json";
import { Redirect } from "react-router-dom"
import { v4 as uuidv4 } from 'uuid';


// import NewMortgageCalculator from "../components/MortgageCalculator";
// import Calculators from "../components/Calculators";
// import TopicsAndArticles from "../components/TopicsAndArticles";
// import Faqs from "../components/Faqs";
// import { House, AttachMoney, LocalAtm, CreditCard, DriveEta, HomeWork, Policy, MonetizationOn } from '@material-ui/icons';

// import ReactPWAInstallProvider from "react-pwa-install";
// import PWA from "../components/PWAinstall";

const styles = theme => ({
    root: {
        "&*": {
            fontFamily: "'Oxygen',sans-serif",
            fontWeight: "100"
        },
        "& a": {
            textDecoration: "none"
        },
        // "& h1": {
        // fontSize: "40px"
        // },
        "& h2": {
            fontSize: "22px",
        },
        "& h3": {
            fontSize: "20px",
            marginBottom: "-15px",
        },
        "& h4": {
            fontSize: "16px",
            fontWeight: 600,
            marginBottom: "-15px",
        },
        "& h5": {
            fontSize: "14px"
        },
        "& h6": {
            fontSize: "14px"
        },
        "& p": {
            fontSize: "15px"
        },
    },
    media: {
        height: "500px",
        // paddingTop: '56.25%', // 16:9
        width: "900px",
        margin: "0 auto",
        // backgroundAttachment: "fixed",
        // backgroundPosition: "bottom",
        '@media screen and (max-width: 700px)': {
            // height: "350px",
            height: "400px"
        }
    },
    card: {
        height: "500px",
        position: 'relative',
        margin: "0 auto",
        borderRadius: "0px",
        // marginBottom: "15px",
        '@media screen and (max-width: 700px)': {
            // height: "350px",
            height: "400px"
        }
    },
    container: {
        fontWeight: "100",
        fontSize: "15px",
        '@media screen and (max-width: 700px)': {
            fontWeight: "200",
            fontSize: "14px",
        }
    },
    overlayContainer: {
        width: "1300px",
        margin: "0 auto",
    },
    overlay: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        position: 'absolute',
        margin: "0 auto",
        // width: "100vw",
        top: '0px',
        height: "500px",
        // margin: "0 auto",
        // paddingTop: "75px",
        //    left: '70px',
        color: 'white',
        fontSize: "10px",
        padding: "30px",
        //  backgroundColor: 'rgba(250, 250, 250, 0.4) !important',
        // background: 'rgb(38,50,56)',
        // background: 'linear-gradient(180deg, rgba(70,116,139,0.4) 0%, rgba(38,50,56,0.7) 25%, rgba(70,116,139,0.4) 100%) !important',
        background: 'radial-gradient(circle, rgb(14 173 192 / 18%) 0%, rgb(19 156 225 / 56%) 70%, rgb(15 143 172 / 72%) 100%) !important',
        '@media screen and (max-width: 700px)': {
            // paddingTop: "20vh",
            width: "auto !important",
            height: "80vh"
        }
    },
    title: {
        // textShadow: "2px 2px #00143d",
        fontSize: "3rem",
        lineHeight: "1",
        // letterSpacing: "-1px",
        fontWeight: "800",
        textAlign: "left",
        maxWidth: "900px",
        padding: "0px 50px 0 50px",
        // margin: "40px 0px 30px 0px",
        margin: "0 auto",
        marginTop: "40px",
        marginBottom: "30px",
        // lineHeight: "4.0rem",
        letterSpacing: "-2",
        // fontFamily: "'Oxygen',sans-serif",
        fontFamily: "Garamond, Baskerville, Baskerville Old Face, Hoefler Text, Times New Roman, serif",
        '@media screen and (max-width: 700px)': {
            fontSize: "1.8rem",
            lineHeight: "2rem",
            width: "80vw",
            padding: "0px 10px 0 10px",
            margin: "30px 0px 0px 0px",
            // textShadow: "2px 2px #00143d",
        }
    },
    description: {
        // textShadow: "2px 2px #00143d",
        fontSize: "20px",
        marginTop: "10px",
        fontFamily: "'Oxygen',sans-serif",
        // fontFamily: '"DM Serif Display",Garimond,serif',
        // marginLeft: "30px",
        // fontWeight: "600",
        textAlign: "left",
        // maxWidth: "600px",
        padding: "0 50px 0 50px",
        '@media screen and (max-width: 700px)': {
            fontSize: "1rem",
            lineHeight: "1.9rem",
            padding: "0px 10px 0 10px",
            // margin: "-90px 0px 0px 0px",
            // textShadow: "2px 2px #00143d",
        }
    },
    markdown: {
        lineHeight: 1.625,
        fontFamily: "'Oxygen',sans-serif",
        '@media screen and (max-width: 700px)': {
            // fontSize: "1rem",
            // lineHeight: "1.9rem",
            margin: "0px 15px 0 15px",
            // margin: "-90px 0px 0px 0px",
            // textShadow: "2px 2px #00143d",
        }
    },
    excerpt: {
        '@media screen and (max-width: 700px)': {
            // fontSize: "1rem",
            // lineHeight: "1.9rem",
            // margin: "0px 15px 0 15px",
            width: "80%",
            margin: "0 auto"
            // textShadow: "2px 2px #00143d",
        }
    }
})


class Home extends React.Component {
    constructor(props) {
        super(props);
        // console.log(postlist)
        this.state = {
            error: null,
            isLoaded: false,
            items: '',
            slug: (this.props.location !== undefined && this.props.location.pathname !== undefined) ? this.props.location.pathname.split('/')[2].split('/')[0].replace(/ /g, '-').toLowerCase() : null,
            navigation: this.props.match.url,
            posts: postlist
        }
        // this.state.posts.map((post) => (
        //     console.log(post)+
        //     // console.log(this.state.slug)+
        //     console.log('"'+post.title.toLowerCase().replace(/ /g, '-')+'"  "'+this.state.slug+'"')
        //     +
        //     console.log(post.title.toLowerCase().replace(/ /g, '-').includes(this.state.slug))
        //     +
        //     (post.title.toLowerCase().replace(/ /g, '-').includes(this.state.slug)) ?
        //     this.setState({
        //         postTitle: post.title.replace(/(\r\n|\n|\r)/gm, ""),
        //         postAuthor: post.author.replace(/(\r\n|\n|\r)/gm, ""),
        //         postDate: post.date.replace(/(\r\n|\n|\r)/gm, ""),
        //         postId: post.id,
        //         postThumbnail: post.thumbnail,
        //         postContent: post.content,
        //         isLoaded: true
        //     })
        //     : null
        // ))
        // console.log(props)
    }

    componentDidMount = () => {
        // let posts = JSON.parse(postlist);
        console.log(this.state.posts)
        this.state.posts.map((post) => (
            // console.log(post.slug + " " + this.state.slug) +
            // console.log(post),
            // console.log(post)
            (post.slug === this.state.slug && post.status === "Published") ?
                this.setState({
                    isLoaded: true,
                    // slug: (this.props.location !== undefined && this.props.location.pathname !== undefined) ? this.props.location.pathname.split('/')[2].split('/')[0].replace(/ /g, '-').toLowerCase() : null,
                    // navigation: this.props.match.url,
                    // posts: postlist,
                    postTopics: post.topics,
                    postExcerpt: post.excerpt,
                    postTitle: post.title.replace(/(\r\n|\n|\r)/gm, ""),
                    postAuthor: post.author[0].replace(/(\r\n|\n|\r)/gm, ""),
                    postDate: post.date.replace(/(\r\n|\n|\r)/gm, ""),
                    postUpdated: (post.dateupdated)? post.dateupdated.replace(/(\r\n|\n|\r)/gm, "") : null,
                    postId: post.id,
                    postThumbnail: post.thumbnail,
                    postContent: post.content,
                    postKeywords: post.keywords,
                    listTitle: (post.listTitle !== undefined) ? post.listTitle : null,
                    listBody: (post.listBody !== undefined) ? post.listBody : null,
                    listType: (post.listType !== undefined) ? post.listType : null,
                    listNumbered: (post.numbered !== undefined) ? post.numbered : 'no',
                    listReversed: (post.reversed === true) ? post.reversed : false,
                    sections: (post.sections !== undefined) ? post.sections : null
                })
                :
                this.setState({
                    isLoaded: true,
                })
        ))
        // console.log(this.state.posts)

        // console.log(post)
        // console.log(post.title.toLowerCase().replace(/ /g, '-').replace("'", '') + "  |  " + this.state.navigation.pathname.toLowerCase().split('/')[2])
        // &&
        // console.log(post.title.toLowerCase().replace(/ /g, '-').replace("'", '') === this.state.navigation.pathname.toLowerCase().split('/')[2])
        // ))
        // console.log(this.state.navigation.pathname.toLowerCase().split('/')[2])
    }

    render() {
        const { error, isLoaded } = this.state;
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            const { classes } = this.props;
            let sections = [];
            if (this.state.sections !== undefined && this.state.sections !== null){
                this.state.sections.forEach(function(section){
                    sections.push({
                        "@type": "BlogPosting",
                        // "@id": "http://www.example.com/example/#cats",
                        // "url": "",
                        "name": section.title,
                        "headline": section.title,
                        "image": (section.image !== undefined && section.image !== null)? `https://bankzia.com/${section.image}` : "",
                        "articleBody": section.body
                    })
                })
            }
            // console.log(window.location.origin + this.state.postThumbnail)
            // console.log(this.state.postTitle)
            if (this.state.postTitle !== undefined) {
                return (
                    <div className={classes.root}>
                        <Meta
                            screen={"article"}
                            title={this.state.postTitle}
                            description={this.state.postExcerpt}
                            keywords={this.state.postKeywords}
                            image={this.state.postThumbnail}
                            url={`https://bankzia.com/article/${this.state.slug}`}
                        />
                        <JsonLD
                            data={{ 
                                "@context": "https://schema.org", 
                                "@type": "BlogPosting",
                                "headline": this.state.postTitle,
                                "alternativeHeadline": this.state.postExcerpt,
                                "mainEntityOfPage": this.state.postExcerpt,
                                "image": `https://bankzia.com/${this.state.postThumbnail}`,
                                "keywords": this.state.postKeywords,
                                "genre": this.state.postTopics,  
                                "articleSection": this.state.postTopics,
                                "wordcount": this.state.postContent.split(' ').length + JSON.stringify(this.state.listBody).split(' ').length + JSON.stringify(this.state.sections).split(' ').length,
                                "url": `https://bankzia.com/article/${this.state.slug}`,
                                "datePublished": this.state.postDate,
                                "dateCreated": this.state.postDate,
                                "dateModified": this.state.postUpdated,
                                "description": this.state.postExcerpt,
                                "articleBody": this.state.postContent,
                                // "hasPart": sections,
                                "itemListElement": sections,
                                // "award": "Best article ever written",
                                "editor": "Bankzia", 
                                "author": {
                                    "@type": "Person",
                                    "name": this.state.postAuthor
                                },
                                "creator": {
                                    "@type": "Person",
                                    "name": this.state.postAuthor,
                                    // "url": ""
                                },
                                "publisher": {
                                    "@id": "https://bankzia.com/",
                                    "@type": "Organization",
                                    "name": "Bankzia",
                                    "url": "https://bankzia.com",
                                    "logo": {
                                        "@type": "ImageObject",
                                        "url": "https://bankzia.com/logo512.png",
                                        "width":"512",
                                        "height":"512"
                                    }
                                }
                              }}
                        />
                        <Grid container>

                        <Grid item sm={12}>
                                <Typography className={classes.title} variant="h1" component="h1" style={{ color: this.state.cellTextColor }}>{this.state.postTitle}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                                <div className={classes.excerpt} style={{ fontSize: "16px", fontWeight: "200", borderLeft: "5px solid black", textAlign: "left", padding: "0px 5px 0px 20px", maxWidth: "500px", margin: "0 auto", marginBottom: "30px" }}>
                                    <Markdown className={classes.markdown} source={this.state.postExcerpt} escapeHtml={false} />
                                </div>
                            </Grid>
                            <Card className={classes.card}>
                                <CardMedia image={(this.state.postThumbnail.includes('https://')) ? this.state.postThumbnail : "https://bankzia.com" + this.state.postThumbnail} component="img" alt={"test"} className={classes.media} />
                                <div className={classes.overlay}>
                                    <div className={classes.overlayContainer}>
                                    </div>
                                </div>
                            </Card>


                            <Grid item md={12} className={classes.container} style={{ textAlign: "left" }}>
                                <div style={{ textAlign: "left", maxWidth: "900px", margin: "0 auto", marginTop: "0px", padding: "15px" }}>
                                    {/* <p className={classes.markdown}>{JSON.stringify(this.state.data)}</p> */}
                                    {/* <p className={classes.markdown} style={{maxWidth: "900px", width: "900px"}}>{JSON.stringify(this.state.stateData)}</p> */}
                                    {/* <Typography className={classes.markdown} variant="h5" component="h5">{this.state.postTitle}</Typography> */}
                                    {/* <Typography className={classes.markdown} variant="p" component="p">Author: <Link href={`/author/${this.state.postAuthor.toLowerCase().replace(' ', '-')}`}>{this.state.postAuthor}</Link> | Published On: {this.state.postDate}</Typography>
                                    <Typography className={classes.markdown} variant="p" component="p">Categories: {this.state.postTopics.map((topic) => { return (<span key={uuidv4()}><a href={window.location.origin + `/topic/${topic.replace(/ /g, '-')}/`}>{topic}</a>, </span>) })}</Typography> */}
                                    <Grid item sm={12}>
                                        <div style={{fontFamily: "Garamond, Baskerville", fontWeight: "100", borderLeft: "5px solid black", textAlign: "left", padding: "0px 5px 0px 20px", maxWidth: "100%", margin: "0 auto", marginBottom: "10px" }}>
                                        <Typography variant="p" component="p" style={{ fontSize: "13px"}}>Published On: {this.state.postDate}</Typography>
                                        <Typography variant="p" component="p" style={{ fontSize: "13px"}}>Author: <Link href={`/author/${this.state.postAuthor.toLowerCase().replace(' ', '-')}`}>{this.state.postAuthor}</Link></Typography>
                                        <Typography variant="p" component="p" style={{ fontSize: "13px"}}>Categories: {this.state.postTopics.map((topic) => { return (<span key={uuidv4()}><a href={window.location.origin + `/topic/${topic.replace(/ /g, '-').toLowerCase()}/`}>{topic}</a>, </span>) })}</Typography>
                                        </div>
                                    </Grid>

                                    {/* <p className={classes.markdown}>Categories: {this.state.postTopics.map((topic) => { return (<span key={uuidv4()}><a href={window.location.origin + `/topic/${topic.replace(/ /g, '-')}/`}>{topic}</a>, </span>) })}</p> */}
                                    <Markdown className={classes.markdown} source={this.state.postContent} escapeHtml={false} />
                                </div>
                                <Grid item md={12} className={classes.container} style={{ textAlign: "left", maxWidth: "900px", padding: "15px", margin: "0 auto", marginTop: "0px" }} >
                                    <h3>{this.state.listTitle}</h3>
                                    <p>{this.state.listBody}</p>
                                    {/* <p>{this.state.listType}</p> */}
                                    {(this.state.sections !== null && this.state.listReversed !== undefined && this.state.listReversed !== null && this.state.listReversed === true) ?
                                        this.state.sections.reverse().map((item, i) => (
                                            <div key={uuidv4()}>
                                                {(this.state.listNumbered === "yes") ? <h2>{this.state.sections.length - 1 - i + 1}. {item.title}</h2> : <h2>{item.title}</h2>}
                                                <Markdown source={item.body} escapeHtml={false} />
                                            </div>
                                        ))
                                        :
                                        (this.state.sections !== null && this.state.listReversed !== undefined && this.state.listReversed !== null && this.state.listReversed === false) ?
                                            this.state.sections.map((item, i) => (
                                                <div key={uuidv4()}>
                                                    {(this.state.listNumbered === "yes") ? <h2>{i + 1}. {item.title}</h2> : <h2>{item.title}</h2>}
                                                    <Markdown source={item.body} escapeHtml={false} />
                                                </div>
                                            ))
                                            : (this.state.sections !== null)?
                                            this.state.sections.map((item, i) => (
                                                <div key={uuidv4()}>
                                                    <h2>{item.title}</h2>
                                                    <Markdown source={item.body} escapeHtml={false} />
                                                </div>
                                            ))
                                            : <></>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Faqs
                            layout={"expanded"}
                            // title={"Calculators"}
                            // byline={"Nestzia offers a wide variety of financial calculators to help our readers make more educated financial decisions."}
                            backgroundColor={"white"}
                            cellColor={"white"}
                            faqs={JSON.stringify([{
                                title: "Buying a home, refinancing, renting, renovating?",
                                body: "Our relestate calculators make it easy to make smarter financial decision the next time you purchase a home, refinance for a lower rate, rent an apartment, or decide to do any renovations."
                            }])
                            }
                        /> */}
                        {/* <h1>{this.state.postTitle}</h1>
                    <p>Published: {this.state.postDate}</p>
                    <p>By: {this.state.postAuthor}</p>
                    <img src={"https://nestzia.netlify.app"+this.state.postThumbnail} width={400} height={400} />
                    <p>{this.state.postContent}</p> */}
                    <div style={{margin: "40px 0", boxShadow: "0 0 1px 0 rgba(21,21,21,.1), 0 2px 8px 1px rgba(21,21,21,.2)"}}/>
                    </div>
                )
            } else {
                return <Redirect to="/404" />
            }
        }
    }
}
export default withStyles(styles, { withTheme: true })(Home);