import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Button } from '@material-ui/core';

const styles = theme => ({
    root: {
        // flexGrow: 1,
    },
    favorites: {
        // marginTop: "25px",
        color: "white",
        borderColor: "white",
        borderRadius: "0px",
        width: "200px",
        float: "right",
        '&:hover': {
            backgroundColor: 'white',
            color: "#00143d"
        },
        '@media screen and (max-width: 900px)': {
            width: "150px",
            fontSize: "12px",
        },
        '@media screen and (max-width: 600px)': {
            float: "none",
        }
    }
})


class FavoritesButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            state: props.state,
            metrics: props.metrics
        };
    }

    componentDidMount() {
        // console.log(this.state.metrics)
    }

    render() {
        const { classes } = this.props;

        const updateLocalStorage = (e) => {
            let favorites = (localStorage.getItem("favorites") !== null)? JSON.parse(localStorage.getItem("favorites")) : localStorage.setItem("favorites", []);
            let final = {
                date: this.state.metrics.date,
                homeprice: this.state.metrics.homeprice,
                before_home_price: this.state.metrics.before_home_price,
                monthly_home_price: this.state.metrics.monthly_home_price,
                monthly_interest_rate: this.state.metrics.monthly_interest_rate,
                monthly_poperty_tax: this.state.metrics.monthly_poperty_tax,
                monthly_pmi: this.state.metrics.monthly_pmi,
                monthly_insurance: this.state.metrics.monthly_insurance,
                down_payment: this.state.metrics.down_payment,
                interest_rate: this.state.metrics.interest_rate,
                loan_term: this.state.metrics.loan_term,
                additional_principal_payment: this.state.metrics.additional_principal_payment,
                annual_tax: this.state.metrics.annual_tax,
                annual_insurance: this.state.metrics.annual_insurance,
                include_mortgage_instuance: this.state.metrics.include_mortgage_instuance,
                mortgage_insurance_rate: this.state.metrics.mortgage_insurance_rate,
                monthly_hoa: this.state.metrics.monthly_hoa,
                monthly_estimated_price: this.state.metrics.monthly_estimated_price
            };
    
            if (favorites === undefined) {
                localStorage.setItem("favorites", JSON.stringify([final]));
            } else {
                favorites.push(final)
                localStorage.setItem("favorites", JSON.stringify(favorites));
            }
        }

        return (
                <Button className={classes.favorites} variant="outlined" onClick={() => updateLocalStorage()}>Add To Favorites</Button>
        )
    }
}
export default withStyles(styles, { withTheme: true })(FavoritesButton);