import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from '@material-ui/core';
import TopicItem from "./TopicItem";
// import ArticleFeatured from "./article/ArticleFeatured";
import topics from "../../../topics.json";
import { v4 as uuidv4 } from 'uuid';


// WIDGET FOR POPULAR TOPICS

const styles = theme => ({
    root: {
        // marginTop: "30px",
        // marginBottom: "30px",
        maxWidth: "1100px",
        margin: "0 auto",
        flexGrow: 1,
        // border: "1px solid #eee",
        borderRadius: "0px",
        padding: "15px 30px 25px 30px",
        '@media screen and (max-width: 900px)': {
            border: "1px solid #eee",
            marginTop: "20px",
            margin: "0 auto",
            width: "90%",
            padding: "15px 0 20px 0",
        },
    },
    container: {
        padding: "20px 30px"
    },
    title: {
        fontFamily: "Chronicle Display,serif",
        fontSize: "35px",
        maxWidth: "80%",
        textAlign: "left",
        padding: "10px",
        '@media screen and (max-width: 700px)': {
            fontSize: "30px",
            maxWidth: "90%",
            padding: "15px"
        },
    },
    description: {
        fontSize: "15px",
        maxWidth: "70%",
        textAlign: "left",
        fontWeight: "400",
        padding: "10px",
        marginTop: "-15px",
        marginBottom: "15px",
        textTransform: "uppercase",
        '@media screen and (max-width: 700px)': {
            padding: "15px",
            maxWidth: "90%",
        },
    },

})

class Blogroll extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
        };
    }

    // componentDidMount = () => {
        // this.setIndex();
        // this.loop = setInterval(() => this.setIndex(), 5000);
    // }
    // componentWillUnmount() {
        // clearInterval(this.loop);
    // }

    render() {
        const { classes } = this.props;
        // alert(this.state.post)
        return (
            <div className={classes.root} style={{ backgroundColor: this.state.backgroundColor }}>
                <Grid container>
                    <Grid item md={7} sm={12}>
                        <Typography className={classes.title} variant="h2" component="h2" style={{ color: this.state.cellTextColor }}>{this.state.title}</Typography>
                        <Typography className={classes.description} variant="p" component="p" style={{ color: this.state.cellTextColor }}>{this.state.byline}</Typography>
                        <Grid container className={classes.container} spacing={0}>
                            {topics.map((topic, i) => (
                                (topic.status === "Published") ?
                                    <Grid item md={6}>
                                        {/* <Link href={`/topic/${topic.slug}/`}> */}
                                        {/* <p key={uuidv4()} style={{textAlign: "left",}}>{topic.alttitle}</p> */}
                                        <TopicItem key={uuidv4()} data={topic} />
                                        {/* </Link> */}
                                    </Grid>
                                    : <></>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item md={5} sm={12}
                    style={{
                        overflow: "hidden", 
                        maxWidth: "auto", 
                        maxHeight: "400px",
                        margin: "0 auto",
                        position: "relative"
                    }}>
                        <img alt={"Topics"} style={{
                            overflow: "hidden", 
                            float: "right",
                            borderRadius: "0 60px 60px 0px"
                        }} height={"400px"} src="https://images.unsplash.com/photo-1528297506728-9533d2ac3fa4?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80" />
                        {/* {(this.state.post !== null) ?
                            <ArticleFeatured key={uuidv4()} data={this.state.post} />
                        : <></>} */}
                    </Grid>
                </Grid>
            </div>
        )
    }
}
export default withStyles(styles, { withTheme: true })(Blogroll);