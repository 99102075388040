import React, { Component } from 'react';
import Home from './components/Home';
import Widgets from './components/Widgets';

// import Pages from './pages/Pages';
import Pages from './pages/Pages';
import Error from './pages/404';
import Authors from './pages/Authors';
// import ErrorPage from './components/Error';
import Favorites from './components/Favorites';

import Calculators from './pages/AllCalculators';
import MortgageCalculator from './pages/MortgageCalculator';
import AutoCalculator from './pages/AutoCalculator';
// import Topics from './pages/Topics';
import Topics from './pages/Topics';
import StateTopics from './pages/StateTopics';
import Posts from './pages/Posts';
import StatePosts from './pages/StatePosts';
import {BrowserRouter as Router,Switch,Route} from "react-router-dom";

class Routes extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      data: null
    };
  }
  render(){
    return(
      <div>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/demo/" component={Widgets} />
          <Route exact path="/favorites/" component={Favorites} />
          <Route exact path="/calculators/" component={Calculators} />
          <Route exact path="/calculators/:calculator/" component={Calculators} />
          <Route exact path="/mortgage-calculator/" component={MortgageCalculator} />
          <Route exact path="/auto-loan-calculator/" component={AutoCalculator} />
          <Route exact path="/auto-budget-calculator/" component={AutoCalculator} />
          <Route exact path="/mortgage-calculator/:state/" component={MortgageCalculator} />
          <Route exact path="/article/:article/" component={Posts} />
          <Route exact path="/:state/article/:article/" component={StatePosts} />
          <Route exact path="/topic/:topic/" component={Topics} />
          <Route exact path="/:state/topic/:topic/" component={StateTopics} />
          <Route exact path="/author/:author/" component={Authors} />
          <Route path="/*" slugs={[{id: "foo"}, {id: "bar"}]} sitemapIndex={true} component={Pages} />
          <Route path="*" component={Error} status={404} />
        </Switch>
    </Router>
    </div>
    )
  }
}
 
export default Routes;