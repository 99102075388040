import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Grid, CardContent, Card, Typography } from '@material-ui/core';
import {KeyboardArrowDown, KeyboardArrowUp} from '@material-ui/icons';

const styles = theme => ({
    container: {
        maxWidth: "1000px",
        // backgroundColor: "red",
        margin: "0 auto",
        paddingTop: "15px",
        paddingBottom: "25px",
    },
    root: {
        // flexGrow: 1,
        // width: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "#EEE"
    },
    title: {
        fontFamily: "'DM Serif Display',Garamond,serif",
        fontWeight: 600,
        lineHeight: 1,
        fontSize: "2.3rem",
        textAlign: "left",
        letterSpacing: "-1px",
        maxWidth: "800px",
        width: "80%",
        margin: "0 auto",
        padding: "50px 20px 15px 20px",
        color: "black",
    },
    description: {
        borderBottom: "1px solid black",
        textAlign: "left",
        letterSpacing: "-1px",
        maxWidth: "800px",
        width: "80%",
        margin: "0 auto",
        padding: "0px 20px 15px 20px",
        color: "black"
    },
    faq: {
        boxShadow: "0px 0px 4px 0 rgba(21,21,21,.1), 0 2px 8px -1px rgba(21,21,21,.1)",
        borderColor: "transparent",
        borderRadius: "0px",
        borderLeftWidth: "8px",
        borderLeftColor: "black",
        '&:hover': {
            backgroundColor: 'white',
            boxShadow: "0 9px 9px 0 rgba(21,21,21,.14), 0 16px 20px -5px rgba(21,21,21,.18)",
            transition: "border-color .2s ease-in,box-shadow .2s ease-in,color .2s ease-in",
            borderLeftWidth: "8px",
            borderLeftColor: "#0157ff",
            cursor: "pointer",
            color: "#0157ff"
        }
    },
    activeFaq: {
        boxShadow: "0 9px 9px 0 rgba(21,21,21,.14), 0 16px 20px -5px rgba(21,21,21,.18)",
        borderColor: "transparent",
        borderRadius: "0px",
        borderLeftWidth: "8px",
        borderLeftColor: "#0157ff",
        '&:hover': {
            backgroundColor: 'white',
            boxShadow: "0 9px 9px 0 rgba(21,21,21,.14), 0 16px 20px -5px rgba(21,21,21,.18)",
            transition: "border-color .2s ease-in,box-shadow .2s ease-in,color .2s ease-in",
            borderLeftWidth: "8px",
            borderLeftColor: "#0157ff",
            cursor: "pointer",
            color: "#0157ff"
        }
    },
    heading: {
        padding: "10px 0px 0px 20px",
        fontSize: "1.6rem",
        lineHeight: "1.5rem",
        marginBottom: "0px",
        fontFamily: "'DM Serif Display',Garamond,serif",
        textAlign: "left",
        fontWeight: "400",
        maxWidth: "1300px",
        margin: "0 auto",
        // marginBottom: "-10px"
    },
    gridHeading: {
        fontSize: "17px",
        lineHeight: "20px",
        marginBottom: "8px",
        fontFamily: "'CircularStd-Bold',Arial,sans-serif",
        textAlign: "left",
        fontWeight: "600",
        maxWidth: "250px",
        margin: "0 auto"
    },
    gridByline: {
        fontSize: "14px",
        lineHeight: "16px",
        textAlign: "left",
        margin: "0 auto",
        maxWidth: "250px"
    },
    byline: {
        fontFamily: "'CircularStd-Bold',Arial,sans-serif",
        paddingTop: "20px",
        fontSize: "15px",
        lineHeight: "23px",
        textAlign: "left",
        margin: "0 auto",
        maxWidth: "800px",
    },
    expanded: {
        borderRadius: "0px"
    }
})


class Faqs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            state: props.state,
            faqs: JSON.parse(props.faqs),
            layout: props.layout,
            title: props.title,
            byline: props.byline,
            backgroundColor: props.backgroundColor,
            titleTextColor: props.titleTextColor,
            cellColor: props.cellColor,
            cellTextColor: props.cellTextColor
        };
    }

    // componentDidMount() {
    //     console.log(this.state.metrics)
    // }

    render() {
        const { classes } = this.props;
        const handleChange = (event) => {
            console.log(event.target.getAttribute('text'))
            this.setState({ [event.target.getAttribute('text')]: !this.state[event.target.getAttribute('text')] });
        };

        if (this.state.layout === "grid") {
            return (
                <div className={classes.root} style={{backgroundColor: this.state.backgroundColor}}>
                    <Grid container>
                        <Grid item md={12}>
                            <Typography className={classes.title} variant="h2" component="h2" style={{color: this.state.titleTextColor}}>{this.state.title}</Typography>
                            <Typography className={classes.description} variant="p" component="p"  style={{color: this.state.titleTextColor}}>{this.state.byline}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container} spacing={2}>
                        {this.state.faqs.map((faq) => (
                            <Grid key={faq.title}  item md={4} style={{ margin: "10px auto" }}>
                                <Card className={classes.faq} variant="outlined" style={{backgroundColor: this.state.cellColor, color: this.state.cellTextColor, borderColor: this.state.cellTextColor}}>
                                    <CardContent className={classes.cardContent}>
                                        <Typography className={classes.gridHeading} variant="h5" component="h5">{faq.title}</Typography>
                                        <Typography className={classes.gridByline} variant="p" component="p">{faq.body}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            )
        } else if (this.state.layout === "accordion") {
            return (
                <div className={classes.root} style={{backgroundColor: this.state.backgroundColor}}>
                    <Grid container>
                        <Grid item md={12}>
                            <Typography className={classes.title} variant="h2" component="h2" style={{color: this.state.titleTextColor}}>{this.state.title}</Typography>
                            <Typography className={classes.description} variant="p" component="p" style={{color: this.state.titleTextColor, borderColor: this.state.titleTextColor}}>{this.state.byline}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container} spacing={2}>
                        {this.state.faqs.map((faq) => (
                            <Grid item key={faq.title} className={classes.faqItem} md={12} style={{ margin: "8px auto" }}>
                                <Card variant="outlined" className={this.state[faq.title] !== true ? classes.faq : classes.activeFaq} text={faq.title} onClick={handleChange}  style={{backgroundColor: this.state.cellColor, color: this.state.cellTextColor, borderColor: this.state.cellTextColor}}>
                                    <CardContent text={faq.title} onClick={handleChange}>
                                        <Grid item sm={12} text={faq.title} onClick={handleChange}>
                                        <Typography className={classes.heading} variant="h5" component="h5" text={faq.title} onClick={handleChange}>{faq.title}
                                        {this.state[faq.title] !== true ? <KeyboardArrowDown style={{float: "right"}} text={faq.title} onClick={handleChange}/> : <KeyboardArrowUp text={faq.title} onClick={handleChange} style={{float: "right"}}/>}</Typography>
                                        </Grid>
                                        {(this.state[faq.title] === true)?
                                            <Typography className={classes.byline} variant="p" component="p" text={faq.title} onClick={handleChange}>{faq.body}</Typography>
                                            : <></>
                                        }
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            )
        } else if (this.state.layout === "expanded") {
            return (
                <div className={classes.root} style={{backgroundColor: this.state.backgroundColor}}>
                    <Grid container>
                        <Grid item md={12}>
                            <Typography className={classes.title} variant="h2" component="h2" style={{color: this.state.titleTextColor}}>{this.state.title}</Typography>
                            {(this.state.byline !== undefined)? <Typography className={classes.description} variant="p" component="p" style={{color: this.state.titleTextColor, borderColor: this.state.titleTextColor}}>{this.state.byline}</Typography> : <></>}
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container} spacing={2}>
                        {this.state.faqs.map((faq) => (
                            <Grid item key={faq.title} md={12}>
                                <Card variant="outlined" className={classes.expanded} style={{backgroundColor: this.state.cellColor, color: this.state.cellTextColor, borderColor: this.state.cellTextColor}}>
                                    <CardContent>
                                        <Grid item sm={12}>
                                        <Typography className={classes.heading} variant="h5" component="h5">{faq.title}</Typography>
                                        </Grid>
                                            <Typography className={classes.byline} variant="p" component="p">{faq.body}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            )
        } else {
            return (
                <></>
            )
        }
    }
}
export default withStyles(styles, { withTheme: true })(Faqs);