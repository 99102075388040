import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from '@material-ui/core';
import BlogrollPost from "./BlogrollPost";

const styles = theme => ({
    root: {
        marginBottom: "30px",
        maxWidth: "100%"
    },
    container: {
        maxWidth: "70%",
        margin: "0 auto",
        '@media screen and (max-width: 700px)': {
            maxWidth: "99%",
        }
    },
    title: {
        fontFamily: "'DM Serif Display',Garamond,serif",
        fontWeight: 600,
        lineHeight: 1,
        fontSize: "2.3rem",
        textAlign: "left",
        letterSpacing: "-1px",
        maxWidth: "1300px",
        width: "80%",
        margin: "0 auto",
        padding: "50px 20px 15px 20px",
        color: "black",
        textTransform: "capitalize",
        '@media screen and (max-width: 700px)': {
            fontSize: "2rem"
        }
    },
    description: {
        borderBottom: "1px solid black",
        textAlign: "left",
        letterSpacing: "-1px",
        maxWidth: "1300px",
        width: "80%",
        margin: "0 auto",
        marginBottom: "-40px",
        padding: "0px 20px 15px 20px",
        color: "black",
        '@media screen and (max-width: 700px)': {
            fontSize: "15px"
        }
    },

})
class Blogroll extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
    }
    // componentDidMount = () => {
    //     console.log(this.state.data.posts)
    // }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root} style={{width: (this.state.width)? this.state.width : null, margin: "0 auto"}}>
                <Grid container>
                    <Grid item md={12} className={classes.container}>
                        <Typography className={classes.title} variant="h1" component="h1">{(this.state.data.slug)? `${this.state.data.title}` : this.state.data.title}</Typography>
                        {(this.state.data !== undefined && this.state.data.topic !== undefined && this.state.data.topic.excerpt !== undefined)?
                            <Typography className={classes.description} variant="p" component="p">{this.state.data.topic.excerpt}</Typography>
                            : <></>
                        }
                    </Grid>
                </Grid>
                <Grid container justify="center" style={{ marginTop: "80px" }}>
                    {this.state.data.posts.map((post, i) => (
                        <BlogrollPost data={post} state={this.state.data.state} slug={this.state.data.slug} link={(this.state.data.state)? `${window.location.origin}/${this.state.data.state.toLowerCase().replace(/ /g, '-')}/article/${post.slug}/` : `${window.location.origin}/article/${post.slug}/`}/>
                    ))}
                </Grid>
            </div>
        )
    }
}
export default withStyles(styles, { withTheme: true })(Blogroll);
