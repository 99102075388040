import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Typography, Button } from '@material-ui/core';
// import HouseSharpIcon from '@material-ui/icons/HouseSharp';
// import EmojiTransportationSharpIcon from '@material-ui/icons/EmojiTransportationSharp';
// import MonetizationOnSharpIcon from '@material-ui/icons/MonetizationOnSharp';
// import AccountBalanceSharpIcon from '@material-ui/icons/AccountBalanceSharp';
// import { ThreeSixty } from '@material-ui/icons';

const styles = theme => ({
    title: {
        fontFamily: "Chronicle Display,serif",
        fontSize: "27px",
        maxWidth: "80%",
        textAlign: "left",
        padding: "10px",
    },
    byline: {
        fontSize: "15px",
        maxWidth: "70%",
        textAlign: "left",
        fontWeight: "700",
        padding: "10px",
        marginBottom: "-5px",
        textTransform: "uppercase",
    },
    button: {
        float: "left",
        minWidth: "200px",
        borderRadius: "0px",
        textTransform: "uppercase",
        "&:hover": {
            backgroundColor: "white",
        }
    },
    icon: {
        fontSize: "30px",
        marginBottom: "-5px",
        marginRight: "5px",
    },
    black: {
        "&:hover": {
            color: "black !important"
        }
    }
})

class Post extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
    }

    // componentDidMount = () => {
    // }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Typography className={classes.byline} variant="p" component="p" style={{ color: this.state.data.text }}>{this.state.data.byline}</Typography>
                <Typography className={classes.title} variant="h2" component="h2" style={{ color: this.state.data.text }}>{(this.state.data.icon)? <this.state.data.icon className={classes.icon} style={{color: this.state.data.text}}/> : <></>}{this.state.data.title}</Typography>
                <Button variant="outlined" color="primary" className={[classes.button, (this.state.data.buttonColor && this.state.data.buttonColor === "white")? classes.black : ""]} 
                    href={(this.state.data.href)? this.state.data.href : null} 
                    style={{ 
                        borderColor: this.state.data.buttonColor, 
                        color: this.state.data.buttonColor, 
                        minWidth: (this.state.data.buttonWidth && this.state.data.buttonWidth === "full")? "100%" : null,
                        margin: (this.state.data.buttonWidth && this.state.data.buttonWidth === "full")? "15px 0px" : "15px 10px",
                }}>{this.state.data.button}</Button>
            </div>
        )
    }
}
export default withStyles(styles, { withTheme: true })(Post);