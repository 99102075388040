import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Card, CardMedia, Link } from '@material-ui/core';
import Meta from "../components/Meta";
import JsonLD from "../components/JsonLD";
import Markdown from "react-markdown";
import postlist from "../state_posts.json";
import stateData from "../data.json";
import { Redirect } from "react-router-dom"
import { v4 as uuidv4 } from 'uuid';

const styles = theme => ({
    root: {
        "&*": {
            fontFamily: "'Oxygen',sans-serif",
            fontWeight: "100"
        },
        "& a": {
            textDecoration: "none"
        },
        // "& h1": {
        // fontSize: "40px"
        // },
        "& h2": {
            fontSize: "22px",
        },
        "& h3": {
            fontSize: "20px",
            marginBottom: "-15px",
        },
        "& h4": {
            fontSize: "16px",
            fontWeight: 600,
            marginBottom: "-15px",
        },
        "& h5": {
            fontSize: "14px"
        },
        "& h6": {
            fontSize: "14px"
        },
        "& p": {
            fontSize: "15px"
        },
    },
    media: {
        height: "500px",
        // paddingTop: '56.25%', // 16:9
        width: "900px",
        margin: "0 auto",
        // backgroundAttachment: "fixed",
        // backgroundPosition: "bottom",
        '@media screen and (max-width: 700px)': {
            // height: "350px",
            height: "80vh"
        }
    },
    card: {
        height: "500px",
        position: 'relative',
        margin: "0 auto",
        borderRadius: "0px",
        // marginBottom: "15px",
        '@media screen and (max-width: 700px)': {
            // height: "350px",
            height: "80vh"
        }
    },
    container: {
        fontWeight: "100",
        fontSize: "15px",
        '@media screen and (max-width: 700px)': {
            fontWeight: "200",
            fontSize: "14px",
        }
    },
    overlayContainer: {
        width: "1300px",
        margin: "0 auto",
    },
    overlay: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        position: 'absolute',
        margin: "0 auto",
        // width: "100vw",
        top: '0px',
        height: "500px",
        // margin: "0 auto",
        // paddingTop: "75px",
        //    left: '70px',
        color: 'white',
        fontSize: "10px",
        padding: "30px",
        //  backgroundColor: 'rgba(250, 250, 250, 0.4) !important',
        // background: 'rgb(38,50,56)',
        // background: 'linear-gradient(180deg, rgba(70,116,139,0.4) 0%, rgba(38,50,56,0.7) 25%, rgba(70,116,139,0.4) 100%) !important',
        background: 'radial-gradient(circle, rgb(14 173 192 / 18%) 0%, rgb(19 156 225 / 56%) 70%, rgb(15 143 172 / 72%) 100%) !important',
        '@media screen and (max-width: 700px)': {
            // paddingTop: "20vh",
            width: "auto !important",
            height: "80vh"
        }
    },
    title: {
        // textShadow: "2px 2px #00143d",
        fontSize: "3rem",
        lineHeight: "1",
        // letterSpacing: "-1px",
        fontWeight: "800",
        textAlign: "left",
        maxWidth: "900px",
        padding: "0px 50px 0 50px",
        // margin: "40px 0px 30px 0px",
        margin: "0 auto",
        marginTop: "40px",
        marginBottom: "30px",
        // lineHeight: "4.0rem",
        letterSpacing: "-2",
        // fontFamily: "'Oxygen',sans-serif",
        fontFamily: "Garamond, Baskerville, Baskerville Old Face, Hoefler Text, Times New Roman, serif",
        '@media screen and (max-width: 700px)': {
            fontSize: "1.8rem",
            lineHeight: "2rem",
            width: "80vw",
            padding: "0px 10px 0 10px",
            margin: "-100px 0px 0px 0px",
            textShadow: "2px 2px #00143d",
        }
    },
    description: {
        // textShadow: "2px 2px #00143d",
        fontSize: "20px",
        marginTop: "10px",
        fontFamily: "'Oxygen',sans-serif",
        // fontFamily: '"DM Serif Display",Garimond,serif',
        // marginLeft: "30px",
        // fontWeight: "600",
        textAlign: "left",
        // maxWidth: "600px",
        padding: "0 50px 0 50px",
        '@media screen and (max-width: 700px)': {
            fontSize: "1rem",
            lineHeight: "1.9rem",
            padding: "0px 10px 0 10px",
            // margin: "-90px 0px 0px 0px",
            textShadow: "2px 2px #00143d",
        }
    },
    markdown: {
        lineHeight: 1.625,
        fontFamily: "'Oxygen',sans-serif"
    },
})


class Home extends React.Component {
    constructor(props) {
        super(props);
        // console.log(postlist)
        this.state = {
            error: null,
            isLoaded: false,
            items: '',
            slug: (this.props.location !== undefined && this.props.location.pathname !== undefined) ? this.props.location.pathname.split('/')[3].split('/')[0].replace(/ /g, '-').toLowerCase() : null,
            state: (this.props.location !== undefined && this.props.location.pathname !== undefined) ? this.props.location.pathname.split('/')[1].split('/')[0].replace(/ /g, '_').replace(/-/g, '_').toLowerCase() : null,
            navigation: this.props.match.url,
            posts: postlist,
            data: []
        }
    }

    componentDidMount = () => {
        console.log(this.state.posts)
        this.state.posts.map((post) => (
            (post.slug === this.state.slug && post.status === "Published") ?
                this.setState({
                    isLoaded: true,
                    finalState: stateData[this.state.state].state,
                    postTopics: post.topics,
                    postExcerpt: post.excerpt
                        .replace(/{{year}}/g, new Date().getFullYear())
                        .replace(/{{state}}/g, stateData[this.state.state].state)
                        .replace(/{{abrv}}/g, stateData[this.state.state].abrv)
                        .replace(/{{statehood}}/g, stateData[this.state.state].statehood)
                        .replace(/{{capital}}/g, stateData[this.state.state].capital)
                        .replace(/{{capital_since}}/g, stateData[this.state.state].capital_since)
                        .replace(/{{capital_population}}/g, stateData[this.state.state].capital_population)
                        .replace(/{{capital_rank_in_state}}/g, stateData[this.state.state].capital_rank_in_state)
                        .replace(/{{capital_rank_in_us}}/g, stateData[this.state.state].capital_rank_in_us)
                        .replace(/{{nickname}}/g, stateData[this.state.state].nickname.split(',')[0])
                        .replace(/{{population_rank}}/g, stateData[this.state.state].population_rank)
                        .replace(/{{population_estimate}}/g, stateData[this.state.state].population_estimate)
                        .replace(/{{population_percent_of_total}}/g, stateData[this.state.state].population_percent_of_total)
                        .replace(/{{temperature_average}}/g, stateData[this.state.state].temperature_average)
                        .replace(/{{household_income_average}}/g, stateData[this.state.state].household_income_average)
                        .replace(/{{utilities_electricity_per_kwh}}/g, stateData[this.state.state].utilities_electricity_per_kwh)
                        .replace(/{{utilities_electricity_average_monthly_usage_kwh}}/g, stateData[this.state.state].utilities_electricity_average_monthly_usage_kwh)
                        .replace(/{{utilities_electricity_average_monthly_bill}}/g, stateData[this.state.state].utilities_electricity_average_monthly_bill)
                        .replace(/{{utilities_electricity_average_ranking_in_US}}/g, stateData[this.state.state].utilities_electricity_average_ranking_in_US)
                        .replace(/{{household_income_mean}}/g, stateData[this.state.state].household_income_mean)
                        .replace(/{{college_graduate_rate}}/g, stateData[this.state.state].college_graduate_rate)
                        .replace(/{{average_household_size}}/g, stateData[this.state.state].average_household_size)
                        .replace(/{{funeral_cost}}/g, stateData[this.state.state].funeral_cost)
                        .replace(/{{end_of_life_cost}}/g, stateData[this.state.state].end_of_life_cost)
                        .replace(/{{vehicle_registration}}/g, stateData[this.state.state].vehicle_registration)
                        .replace(/{{vehicle_plate_fee}}/g, stateData[this.state.state].vehicle_plate_fee)
                        .replace(/{{vehicle_title_fee}}/g, stateData[this.state.state].vehicle_title_fee)
                        .replace(/{{teacher_salary}}/g, stateData[this.state.state].teacher_salary)
                        .replace(/{{teacher_salary_preschool}}/g, stateData[this.state.state].teacher_salary_preschool)
                        .replace(/{{teacher_salary_kindergarten}}/g, stateData[this.state.state].teacher_salary_kindergarten)
                        .replace(/{{teacher_salary_elementary}}/g, stateData[this.state.state].teacher_salary_elementary)
                        .replace(/{{teacher_salary_middle}}/g, stateData[this.state.state].teacher_salary_middle)
                        .replace(/{{teacher_salary_high}}/g, stateData[this.state.state].teacher_salary_high)
                        .replace(/{{average_monthly_rental}}/g, stateData[this.state.state].average_monthly_rental)
                        .replace(/{{broadband_speed_average}}/g, stateData[this.state.state].broadband_speed_average)
                        .replace(/{{broadband_coverage_average}}/g, stateData[this.state.state].broadband_coverage_average)
                        .replace(/{{annual_child_care_cost}}/g, stateData[this.state.state].annual_child_care_cost)
                        .replace(/{{retirement_savings_required}}/g, stateData[this.state.state].retirement_savings_required)
                        .replace(/{{retirement_age}}/g, stateData[this.state.state].retirement_age)
                        .replace(/{{retirement_life_expectancy}}/g, stateData[this.state.state].retirement_life_expectancy)
                        .replace(/{{retirement_yearly_expenses}}/g, stateData[this.state.state].retirement_yearly_expenses)
                        .replace(/{{vehicle_sales_tax}}/g, stateData[this.state.state].vehicle_sales_tax)
                        .replace(/{{cancer_rates}}/g, stateData[this.state.state].cancer_rates)
                        .replace(/{{retirement_confortable_retirement}}/g, stateData[this.state.state].retirement_confortable_retirement)
                        .replace(/{{state_debt}}/g, stateData[this.state.state].state_debt)
                        .replace(/{{state_debt_per_capita}}/g, stateData[this.state.state].state_debt_per_capita)
                        .replace(/{{vehicle_dealer_fee}}/g, stateData[this.state.state].vehicle_dealer_fee)
                        .replace(/{{fico_median_home_value}}/g, stateData[this.state.state].fico_median_home_value)
                        .replace(/{{average_outstanding_mortgage}}/g, stateData[this.state.state].average_outstanding_mortgage)
                        .replace(/{{diff_between_value_and_mortgage}}/g, stateData[this.state.state].diff_between_value_and_mortgage)
                        .replace(/{{state_tax_weighted_percent_of_income}}/g, stateData[this.state.state].state_tax_weighted_percent_of_income)
                        .replace(/{{state_tax_income_tax}}/g, stateData[this.state.state].state_tax_income_tax)
                        .replace(/{{state_tax_sales_tax}}/g, stateData[this.state.state].state_tax_sales_tax)
                        .replace(/{{state_tax_property_tax}}/g, stateData[this.state.state].state_tax_property_tax)
                        .replace(/{{state_tax_gas}}/g, stateData[this.state.state].state_tax_gas)
                        .replace(/{{employment_rate}}/g, stateData[this.state.state].employment_rate)
                        .replace(/{{drivers_license_cost}}/g, stateData[this.state.state].drivers_license_cost)
                        .replace(/{{drivers_license_years_valid}}/g, stateData[this.state.state].drivers_license_years_valid)
                        .replace(/{{national_auto_premium_average}}/g, stateData[this.state.state].national_auto_premium_average)
                        .replace(/{{state_growth_2021}}/g, stateData[this.state.state].state_growth_2021)
                        .replace(/{{state_growth_since_2010}}/g, stateData[this.state.state].state_growth_since_2010)
                        .replace(/{{age_average}}/g, stateData[this.state.state].age_average)
                        .replace(/{{age_male_average}}/g, stateData[this.state.state].age_male_average)
                        .replace(/{{age_female_average}}/g, stateData[this.state.state].age_female_average)
                        .replace(/{{livable_wage}}/g, stateData[this.state.state].livable_wage)
                        .replace(/{{marital_status_married}}/g, stateData[this.state.state].marital_status_married)
                        .replace(/{{marital_status_divorced}}/g, stateData[this.state.state].marital_status_divorced)
                        .replace(/{{marital_status_widowed}}/g, stateData[this.state.state].marital_status_widowed)
                        .replace(/{{marital_status_seperated}}/g, stateData[this.state.state].marital_status_seperated)
                        .replace(/{{marital_status_never_married}}/g, stateData[this.state.state].marital_status_never_married)
                        .replace(/{{poverty_rate_percent}}/g, stateData[this.state.state].poverty_rate_percent)
                        .replace(/{{poverty_rate_total}}/g, stateData[this.state.state].poverty_rate_total)
                        .replace(/{{public_school_amount_spent_per_pupil}}/g, stateData[this.state.state].public_school_amount_spent_per_pupil)
                        .replace(/{{national_auto_premium_rank}}/g, stateData[this.state.state].national_auto_premium_rank)
                        .replace(/{{national_auto_full_coverage_average}}/g, stateData[this.state.state].national_auto_full_coverage_average)
                        .replace(/{{national_auto_minimum_coverage_average}}/g, stateData[this.state.state].national_auto_minimum_coverage_average)
                        .replace(/{{average_rate_15_fixed}}/g, stateData[this.state.state].average_rate_15_fixed)
                        .replace(/{{average_rate_30_fixed}}/g, stateData[this.state.state].average_rate_30_fixed)
                        .replace(/{{average_rate_5_1_arm_fixed}}/g, stateData[this.state.state].average_rate_5_1_arm_fixed)
                        .replace(/{{public_school_overall_rank_in_US}}/g, stateData[this.state.state].public_school_overall_rank_in_US)
                        .replace(/{{public_school_quality_rank_in_US}}/g, stateData[this.state.state].public_school_quality_rank_in_US)
                        .replace(/{{public_school_safety_rank_in_US}}/g, stateData[this.state.state].public_school_safety_rank_in_US)
                        .replace(/{{retirement_tax_friendliness}}/g, stateData[this.state.state].retirement_tax_friendliness)
                        .replace(/{{fico_2019}}/g, stateData[this.state.state].fico_2019)
                        .replace(/{{fico_2020}}/g, stateData[this.state.state].fico_2020)
                        .replace(/{{fico_change}}/g, stateData[this.state.state].fico_change)
                        .replace(/{{utilities_total}}/g, stateData[this.state.state].utilities_total)
                        .replace(/{{utilities_electric}}/g, stateData[this.state.state].utilities_electric)
                        .replace(/{{utilities_internet}}/g, stateData[this.state.state].utilities_internet)
                        .replace(/{{utilities_natural_gas}}/g, stateData[this.state.state].utilities_natural_gas)
                        .replace(/{{utilities_cable}}/g, stateData[this.state.state].utilities_cable)
                        .replace(/{{utilities_water}}/g, stateData[this.state.state].utilities_water)
                        .replace(/{{mortgage_home_average}}/g, stateData[this.state.state].mortgage_home_average)
                        .replace(/{{motgage_home_top_average}}/g, stateData[this.state.state].motgage_home_top_average)
                        .replace(/{{mortgage_home_median_average}}/g, stateData[this.state.state].mortgage_home_median_average)
                        .replace(/{{mortgage_home_bottom_average}}/g, stateData[this.state.state].mortgage_home_bottom_average)
                        .replace(/{{motgage_condo_average}}/g, stateData[this.state.state].motgage_condo_average)
                        .replace(/{{state_tax_property_tax_average}}/g, stateData[this.state.state].state_tax_property_tax_average),
                    postTitle: post.title
                        .replace(/{{year}}/g, new Date().getFullYear())
                        .replace(/(\r\n|\n|\r)/gm, "")
                        .replace(/{{state}}/g, stateData[this.state.state].state)
                        .replace(/{{abrv}}/g, stateData[this.state.state].abrv)
                        .replace(/{{statehood}}/g, stateData[this.state.state].statehood)
                        .replace(/{{capital}}/g, stateData[this.state.state].capital)
                        .replace(/{{capital_since}}/g, stateData[this.state.state].capital_since)
                        .replace(/{{capital_population}}/g, stateData[this.state.state].capital_population)
                        .replace(/{{capital_rank_in_state}}/g, stateData[this.state.state].capital_rank_in_state)
                        .replace(/{{capital_rank_in_us}}/g, stateData[this.state.state].capital_rank_in_us)
                        .replace(/{{nickname}}/g, stateData[this.state.state].nickname.split(',')[0])
                        .replace(/{{population_rank}}/g, stateData[this.state.state].population_rank)
                        .replace(/{{population_estimate}}/g, stateData[this.state.state].population_estimate)
                        .replace(/{{population_percent_of_total}}/g, stateData[this.state.state].population_percent_of_total)
                        .replace(/{{temperature_average}}/g, stateData[this.state.state].temperature_average)
                        .replace(/{{household_income_average}}/g, stateData[this.state.state].household_income_average)
                        .replace(/{{utilities_electricity_per_kwh}}/g, stateData[this.state.state].utilities_electricity_per_kwh)
                        .replace(/{{utilities_electricity_average_monthly_usage_kwh}}/g, stateData[this.state.state].utilities_electricity_average_monthly_usage_kwh)
                        .replace(/{{utilities_electricity_average_monthly_bill}}/g, stateData[this.state.state].utilities_electricity_average_monthly_bill)
                        .replace(/{{utilities_electricity_average_ranking_in_US}}/g, stateData[this.state.state].utilities_electricity_average_ranking_in_US)
                        .replace(/{{household_income_mean}}/g, stateData[this.state.state].household_income_mean)
                        .replace(/{{college_graduate_rate}}/g, stateData[this.state.state].college_graduate_rate)
                        .replace(/{{average_household_size}}/g, stateData[this.state.state].average_household_size)
                        .replace(/{{funeral_cost}}/g, stateData[this.state.state].funeral_cost)
                        .replace(/{{end_of_life_cost}}/g, stateData[this.state.state].end_of_life_cost)
                        .replace(/{{vehicle_registration}}/g, stateData[this.state.state].vehicle_registration)
                        .replace(/{{vehicle_plate_fee}}/g, stateData[this.state.state].vehicle_plate_fee)
                        .replace(/{{vehicle_title_fee}}/g, stateData[this.state.state].vehicle_title_fee)
                        .replace(/{{teacher_salary}}/g, stateData[this.state.state].teacher_salary)
                        .replace(/{{teacher_salary_preschool}}/g, stateData[this.state.state].teacher_salary_preschool)
                        .replace(/{{teacher_salary_kindergarten}}/g, stateData[this.state.state].teacher_salary_kindergarten)
                        .replace(/{{teacher_salary_elementary}}/g, stateData[this.state.state].teacher_salary_elementary)
                        .replace(/{{teacher_salary_middle}}/g, stateData[this.state.state].teacher_salary_middle)
                        .replace(/{{teacher_salary_high}}/g, stateData[this.state.state].teacher_salary_high)
                        .replace(/{{average_monthly_rental}}/g, stateData[this.state.state].average_monthly_rental)
                        .replace(/{{broadband_speed_average}}/g, stateData[this.state.state].broadband_speed_average)
                        .replace(/{{broadband_coverage_average}}/g, stateData[this.state.state].broadband_coverage_average)
                        .replace(/{{annual_child_care_cost}}/g, stateData[this.state.state].annual_child_care_cost)
                        .replace(/{{retirement_savings_required}}/g, stateData[this.state.state].retirement_savings_required)
                        .replace(/{{retirement_age}}/g, stateData[this.state.state].retirement_age)
                        .replace(/{{retirement_life_expectancy}}/g, stateData[this.state.state].retirement_life_expectancy)
                        .replace(/{{retirement_yearly_expenses}}/g, stateData[this.state.state].retirement_yearly_expenses)
                        .replace(/{{vehicle_sales_tax}}/g, stateData[this.state.state].vehicle_sales_tax)
                        .replace(/{{cancer_rates}}/g, stateData[this.state.state].cancer_rates)
                        .replace(/{{retirement_confortable_retirement}}/g, stateData[this.state.state].retirement_confortable_retirement)
                        .replace(/{{state_debt}}/g, stateData[this.state.state].state_debt)
                        .replace(/{{state_debt_per_capita}}/g, stateData[this.state.state].state_debt_per_capita)
                        .replace(/{{vehicle_dealer_fee}}/g, stateData[this.state.state].vehicle_dealer_fee)
                        .replace(/{{fico_median_home_value}}/g, stateData[this.state.state].fico_median_home_value)
                        .replace(/{{average_outstanding_mortgage}}/g, stateData[this.state.state].average_outstanding_mortgage)
                        .replace(/{{diff_between_value_and_mortgage}}/g, stateData[this.state.state].diff_between_value_and_mortgage)
                        .replace(/{{state_tax_weighted_percent_of_income}}/g, stateData[this.state.state].state_tax_weighted_percent_of_income)
                        .replace(/{{state_tax_income_tax}}/g, stateData[this.state.state].state_tax_income_tax)
                        .replace(/{{state_tax_sales_tax}}/g, stateData[this.state.state].state_tax_sales_tax)
                        .replace(/{{state_tax_property_tax}}/g, stateData[this.state.state].state_tax_property_tax)
                        .replace(/{{state_tax_gas}}/g, stateData[this.state.state].state_tax_gas)
                        .replace(/{{employment_rate}}/g, stateData[this.state.state].employment_rate)
                        .replace(/{{drivers_license_cost}}/g, stateData[this.state.state].drivers_license_cost)
                        .replace(/{{drivers_license_years_valid}}/g, stateData[this.state.state].drivers_license_years_valid)
                        .replace(/{{national_auto_premium_average}}/g, stateData[this.state.state].national_auto_premium_average)
                        .replace(/{{state_growth_2021}}/g, stateData[this.state.state].state_growth_2021)
                        .replace(/{{state_growth_since_2010}}/g, stateData[this.state.state].state_growth_since_2010)
                        .replace(/{{age_average}}/g, stateData[this.state.state].age_average)
                        .replace(/{{age_male_average}}/g, stateData[this.state.state].age_male_average)
                        .replace(/{{age_female_average}}/g, stateData[this.state.state].age_female_average)
                        .replace(/{{livable_wage}}/g, stateData[this.state.state].livable_wage)
                        .replace(/{{marital_status_married}}/g, stateData[this.state.state].marital_status_married)
                        .replace(/{{marital_status_divorced}}/g, stateData[this.state.state].marital_status_divorced)
                        .replace(/{{marital_status_widowed}}/g, stateData[this.state.state].marital_status_widowed)
                        .replace(/{{marital_status_seperated}}/g, stateData[this.state.state].marital_status_seperated)
                        .replace(/{{marital_status_never_married}}/g, stateData[this.state.state].marital_status_never_married)
                        .replace(/{{poverty_rate_percent}}/g, stateData[this.state.state].poverty_rate_percent)
                        .replace(/{{poverty_rate_total}}/g, stateData[this.state.state].poverty_rate_total)
                        .replace(/{{public_school_amount_spent_per_pupil}}/g, stateData[this.state.state].public_school_amount_spent_per_pupil)
                        .replace(/{{national_auto_premium_rank}}/g, stateData[this.state.state].national_auto_premium_rank)
                        .replace(/{{national_auto_full_coverage_average}}/g, stateData[this.state.state].national_auto_full_coverage_average)
                        .replace(/{{national_auto_minimum_coverage_average}}/g, stateData[this.state.state].national_auto_minimum_coverage_average)
                        .replace(/{{average_rate_15_fixed}}/g, stateData[this.state.state].average_rate_15_fixed)
                        .replace(/{{average_rate_30_fixed}}/g, stateData[this.state.state].average_rate_30_fixed)
                        .replace(/{{average_rate_5_1_arm_fixed}}/g, stateData[this.state.state].average_rate_5_1_arm_fixed)
                        .replace(/{{public_school_overall_rank_in_US}}/g, stateData[this.state.state].public_school_overall_rank_in_US)
                        .replace(/{{public_school_quality_rank_in_US}}/g, stateData[this.state.state].public_school_quality_rank_in_US)
                        .replace(/{{public_school_safety_rank_in_US}}/g, stateData[this.state.state].public_school_safety_rank_in_US)
                        .replace(/{{retirement_tax_friendliness}}/g, stateData[this.state.state].retirement_tax_friendliness)
                        .replace(/{{fico_2019}}/g, stateData[this.state.state].fico_2019)
                        .replace(/{{fico_2020}}/g, stateData[this.state.state].fico_2020)
                        .replace(/{{fico_change}}/g, stateData[this.state.state].fico_change)
                        .replace(/{{utilities_total}}/g, stateData[this.state.state].utilities_total)
                        .replace(/{{utilities_electric}}/g, stateData[this.state.state].utilities_electric)
                        .replace(/{{utilities_internet}}/g, stateData[this.state.state].utilities_internet)
                        .replace(/{{utilities_natural_gas}}/g, stateData[this.state.state].utilities_natural_gas)
                        .replace(/{{utilities_cable}}/g, stateData[this.state.state].utilities_cable)
                        .replace(/{{utilities_water}}/g, stateData[this.state.state].utilities_water)
                        .replace(/{{mortgage_home_average}}/g, stateData[this.state.state].mortgage_home_average)
                        .replace(/{{motgage_home_top_average}}/g, stateData[this.state.state].motgage_home_top_average)
                        .replace(/{{mortgage_home_median_average}}/g, stateData[this.state.state].mortgage_home_median_average)
                        .replace(/{{mortgage_home_bottom_average}}/g, stateData[this.state.state].mortgage_home_bottom_average)
                        .replace(/{{motgage_condo_average}}/g, stateData[this.state.state].motgage_condo_average)
                        .replace(/{{state_tax_property_tax_average}}/g, stateData[this.state.state].state_tax_property_tax_average),
                    postAuthor: post.author[0].replace(/(\r\n|\n|\r)/gm, ""),
                    postDate: post.date.replace(/(\r\n|\n|\r)/gm, ""),
                    postUpdated: (post.dateupdated) ? post.dateupdated.replace(/(\r\n|\n|\r)/gm, "") : null,
                    postId: post.id,
                    postThumbnail: post.thumbnail,
                    postContent: post.content
                        .replace(/{{year}}/g, new Date().getFullYear())
                        .replace(/{{state}}/g, stateData[this.state.state].state)
                        .replace(/{{abrv}}/g, stateData[this.state.state].abrv)
                        .replace(/{{statehood}}/g, stateData[this.state.state].statehood)
                        .replace(/{{capital}}/g, stateData[this.state.state].capital)
                        .replace(/{{capital_since}}/g, stateData[this.state.state].capital_since)
                        .replace(/{{capital_population}}/g, stateData[this.state.state].capital_population)
                        .replace(/{{capital_rank_in_state}}/g, stateData[this.state.state].capital_rank_in_state)
                        .replace(/{{capital_rank_in_us}}/g, stateData[this.state.state].capital_rank_in_us)
                        .replace(/{{nickname}}/g, stateData[this.state.state].nickname.split(',')[0])
                        .replace(/{{population_rank}}/g, stateData[this.state.state].population_rank)
                        .replace(/{{population_estimate}}/g, stateData[this.state.state].population_estimate)
                        .replace(/{{population_percent_of_total}}/g, stateData[this.state.state].population_percent_of_total)
                        .replace(/{{temperature_average}}/g, stateData[this.state.state].temperature_average)
                        .replace(/{{household_income_average}}/g, stateData[this.state.state].household_income_average)
                        .replace(/{{utilities_electricity_per_kwh}}/g, stateData[this.state.state].utilities_electricity_per_kwh)
                        .replace(/{{utilities_electricity_average_monthly_usage_kwh}}/g, stateData[this.state.state].utilities_electricity_average_monthly_usage_kwh)
                        .replace(/{{utilities_electricity_average_monthly_bill}}/g, stateData[this.state.state].utilities_electricity_average_monthly_bill)
                        .replace(/{{utilities_electricity_average_ranking_in_US}}/g, stateData[this.state.state].utilities_electricity_average_ranking_in_US)
                        .replace(/{{household_income_mean}}/g, stateData[this.state.state].household_income_mean)
                        .replace(/{{college_graduate_rate}}/g, stateData[this.state.state].college_graduate_rate)
                        .replace(/{{average_household_size}}/g, stateData[this.state.state].average_household_size)
                        .replace(/{{funeral_cost}}/g, stateData[this.state.state].funeral_cost)
                        .replace(/{{end_of_life_cost}}/g, stateData[this.state.state].end_of_life_cost)
                        .replace(/{{vehicle_registration}}/g, stateData[this.state.state].vehicle_registration)
                        .replace(/{{vehicle_plate_fee}}/g, stateData[this.state.state].vehicle_plate_fee)
                        .replace(/{{vehicle_title_fee}}/g, stateData[this.state.state].vehicle_title_fee)
                        .replace(/{{teacher_salary}}/g, stateData[this.state.state].teacher_salary)
                        .replace(/{{teacher_salary_preschool}}/g, stateData[this.state.state].teacher_salary_preschool)
                        .replace(/{{teacher_salary_kindergarten}}/g, stateData[this.state.state].teacher_salary_kindergarten)
                        .replace(/{{teacher_salary_elementary}}/g, stateData[this.state.state].teacher_salary_elementary)
                        .replace(/{{teacher_salary_middle}}/g, stateData[this.state.state].teacher_salary_middle)
                        .replace(/{{teacher_salary_high}}/g, stateData[this.state.state].teacher_salary_high)
                        .replace(/{{average_monthly_rental}}/g, stateData[this.state.state].average_monthly_rental)
                        .replace(/{{broadband_speed_average}}/g, stateData[this.state.state].broadband_speed_average)
                        .replace(/{{broadband_coverage_average}}/g, stateData[this.state.state].broadband_coverage_average)
                        .replace(/{{annual_child_care_cost}}/g, stateData[this.state.state].annual_child_care_cost)
                        .replace(/{{retirement_savings_required}}/g, stateData[this.state.state].retirement_savings_required)
                        .replace(/{{retirement_age}}/g, stateData[this.state.state].retirement_age)
                        .replace(/{{retirement_life_expectancy}}/g, stateData[this.state.state].retirement_life_expectancy)
                        .replace(/{{retirement_yearly_expenses}}/g, stateData[this.state.state].retirement_yearly_expenses)
                        .replace(/{{vehicle_sales_tax}}/g, stateData[this.state.state].vehicle_sales_tax)
                        .replace(/{{cancer_rates}}/g, stateData[this.state.state].cancer_rates)
                        .replace(/{{retirement_confortable_retirement}}/g, stateData[this.state.state].retirement_confortable_retirement)
                        .replace(/{{state_debt}}/g, stateData[this.state.state].state_debt)
                        .replace(/{{state_debt_per_capita}}/g, stateData[this.state.state].state_debt_per_capita)
                        .replace(/{{vehicle_dealer_fee}}/g, stateData[this.state.state].vehicle_dealer_fee)
                        .replace(/{{fico_median_home_value}}/g, stateData[this.state.state].fico_median_home_value)
                        .replace(/{{average_outstanding_mortgage}}/g, stateData[this.state.state].average_outstanding_mortgage)
                        .replace(/{{diff_between_value_and_mortgage}}/g, stateData[this.state.state].diff_between_value_and_mortgage)
                        .replace(/{{state_tax_weighted_percent_of_income}}/g, stateData[this.state.state].state_tax_weighted_percent_of_income)
                        .replace(/{{state_tax_income_tax}}/g, stateData[this.state.state].state_tax_income_tax)
                        .replace(/{{state_tax_sales_tax}}/g, stateData[this.state.state].state_tax_sales_tax)
                        .replace(/{{state_tax_property_tax}}/g, stateData[this.state.state].state_tax_property_tax)
                        .replace(/{{state_tax_gas}}/g, stateData[this.state.state].state_tax_gas)
                        .replace(/{{employment_rate}}/g, stateData[this.state.state].employment_rate)
                        .replace(/{{drivers_license_cost}}/g, stateData[this.state.state].drivers_license_cost)
                        .replace(/{{drivers_license_years_valid}}/g, stateData[this.state.state].drivers_license_years_valid)
                        .replace(/{{national_auto_premium_average}}/g, stateData[this.state.state].national_auto_premium_average)
                        .replace(/{{state_growth_2021}}/g, stateData[this.state.state].state_growth_2021)
                        .replace(/{{state_growth_since_2010}}/g, stateData[this.state.state].state_growth_since_2010)
                        .replace(/{{age_average}}/g, stateData[this.state.state].age_average)
                        .replace(/{{age_male_average}}/g, stateData[this.state.state].age_male_average)
                        .replace(/{{age_female_average}}/g, stateData[this.state.state].age_female_average)
                        .replace(/{{livable_wage}}/g, stateData[this.state.state].livable_wage)
                        .replace(/{{marital_status_married}}/g, stateData[this.state.state].marital_status_married)
                        .replace(/{{marital_status_divorced}}/g, stateData[this.state.state].marital_status_divorced)
                        .replace(/{{marital_status_widowed}}/g, stateData[this.state.state].marital_status_widowed)
                        .replace(/{{marital_status_seperated}}/g, stateData[this.state.state].marital_status_seperated)
                        .replace(/{{marital_status_never_married}}/g, stateData[this.state.state].marital_status_never_married)
                        .replace(/{{poverty_rate_percent}}/g, stateData[this.state.state].poverty_rate_percent)
                        .replace(/{{poverty_rate_total}}/g, stateData[this.state.state].poverty_rate_total)
                        .replace(/{{public_school_amount_spent_per_pupil}}/g, stateData[this.state.state].public_school_amount_spent_per_pupil)
                        .replace(/{{national_auto_premium_rank}}/g, stateData[this.state.state].national_auto_premium_rank)
                        .replace(/{{national_auto_full_coverage_average}}/g, stateData[this.state.state].national_auto_full_coverage_average)
                        .replace(/{{national_auto_minimum_coverage_average}}/g, stateData[this.state.state].national_auto_minimum_coverage_average)
                        .replace(/{{average_rate_15_fixed}}/g, stateData[this.state.state].average_rate_15_fixed)
                        .replace(/{{average_rate_30_fixed}}/g, stateData[this.state.state].average_rate_30_fixed)
                        .replace(/{{average_rate_5_1_arm_fixed}}/g, stateData[this.state.state].average_rate_5_1_arm_fixed)
                        .replace(/{{public_school_overall_rank_in_US}}/g, stateData[this.state.state].public_school_overall_rank_in_US)
                        .replace(/{{public_school_quality_rank_in_US}}/g, stateData[this.state.state].public_school_quality_rank_in_US)
                        .replace(/{{public_school_safety_rank_in_US}}/g, stateData[this.state.state].public_school_safety_rank_in_US)
                        .replace(/{{retirement_tax_friendliness}}/g, stateData[this.state.state].retirement_tax_friendliness)
                        .replace(/{{fico_2019}}/g, stateData[this.state.state].fico_2019)
                        .replace(/{{fico_2020}}/g, stateData[this.state.state].fico_2020)
                        .replace(/{{fico_change}}/g, stateData[this.state.state].fico_change)
                        .replace(/{{utilities_total}}/g, stateData[this.state.state].utilities_total)
                        .replace(/{{utilities_electric}}/g, stateData[this.state.state].utilities_electric)
                        .replace(/{{utilities_internet}}/g, stateData[this.state.state].utilities_internet)
                        .replace(/{{utilities_natural_gas}}/g, stateData[this.state.state].utilities_natural_gas)
                        .replace(/{{utilities_cable}}/g, stateData[this.state.state].utilities_cable)
                        .replace(/{{utilities_water}}/g, stateData[this.state.state].utilities_water)
                        .replace(/{{mortgage_home_average}}/g, stateData[this.state.state].mortgage_home_average)
                        .replace(/{{motgage_home_top_average}}/g, stateData[this.state.state].motgage_home_top_average)
                        .replace(/{{mortgage_home_median_average}}/g, stateData[this.state.state].mortgage_home_median_average)
                        .replace(/{{mortgage_home_bottom_average}}/g, stateData[this.state.state].mortgage_home_bottom_average)
                        .replace(/{{motgage_condo_average}}/g, stateData[this.state.state].motgage_condo_average)
                        .replace(/{{state_tax_property_tax_average}}/g, stateData[this.state.state].state_tax_property_tax_average),
                    postKeywords: post.keywords,
                    listTitle: (post.listTitle !== undefined) ? post.listTitle : null,
                    listBody: (post.listBody !== undefined) ? post.listBody : null,
                    listType: (post.listType !== undefined) ? post.listType : null,
                    listNumbered: (post.numbered !== undefined) ? post.numbered : 'no',
                    listReversed: (post.reversed === true) ? post.reversed : false,
                    sections: (post.sections !== undefined) ? post.sections : null,
                    stateData: stateData[this.state.state]
                })
                :
                this.setState({
                    isLoaded: true,
                })
        ))

    }


    render() {
        const { error, isLoaded } = this.state;
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            const { classes } = this.props;

            if (stateData[this.state.state.replace(/-/g, '_')]) {
                this.state.data.push(stateData[this.state.state])
            }

            let sections = [];
            if (this.state.sections !== undefined && this.state.sections !== null) {
                this.state.sections.forEach(function (section) {
                    sections.push({
                        "@type": "BlogPosting",
                        // "@id": "http://www.example.com/example/#cats",
                        // "url": "",
                        "name": section.title,
                        "headline": section.title,
                        "image": (section.image !== undefined && section.image !== null) ? `https://bankzia.com/${section.image}` : "",
                        "articleBody": section.body
                    })
                })
            }

            if (this.state.postTitle !== undefined && this.state.data.length > 0) {
                return (
                    <div className={classes.root}>
                        <Meta
                            screen={"article"}
                            title={this.state.postTitle}
                            description={this.state.postExcerpt}
                            keywords={this.state.postKeywords}
                            image={this.state.postThumbnail}
                            url={`https://bankzia.com/${this.state.state}/article/${this.state.slug}/`}
                            canonical={`https://bankzia.com/${this.state.state}/article/${this.state.slug}/`}
                        />
                        <JsonLD
                            data={{
                                "@context": "https://schema.org",
                                "@type": "BlogPosting",
                                "headline": this.state.postTitle,
                                "alternativeHeadline": this.state.postExcerpt,
                                "mainEntityOfPage": this.state.postExcerpt,
                                "image": `https://bankzia.com/${this.state.postThumbnail}`,
                                "keywords": this.state.postKeywords,
                                "genre": this.state.postTopics,
                                "articleSection": this.state.postTopics,
                                "wordcount": this.state.postContent.split(' ').length + JSON.stringify(this.state.listBody).split(' ').length + JSON.stringify(this.state.sections).split(' ').length,
                                "url": `https://bankzia.com/${this.state.state}/article/${this.state.slug}`,
                                "datePublished": this.state.postDate,
                                "dateCreated": this.state.postDate,
                                "dateModified": this.state.postUpdated,
                                "description": this.state.postExcerpt,
                                "articleBody": this.state.postContent,
                                // "hasPart": sections,
                                "itemListElement": sections,
                                // "award": "Best article ever written",
                                "editor": "Bankzia",
                                "author": {
                                    "@type": "Person",
                                    "name": this.state.postAuthor
                                },
                                "creator": {
                                    "@type": "Person",
                                    "name": this.state.postAuthor,
                                    // "url": ""
                                },
                                "publisher": {
                                    "@id": "https://bankzia.com/",
                                    "@type": "Organization",
                                    "name": "Bankzia",
                                    "url": "https://bankzia.com",
                                    "logo": {
                                        "@type": "ImageObject",
                                        "url": "https://bankzia.com/logo512.png",
                                        "width": "512",
                                        "height": "512"
                                    }
                                }
                            }}
                        />
                        <Grid container>
                            <Grid item sm={12}>
                                <Typography className={classes.title} variant="h1" component="h1" style={{ color: this.state.cellTextColor }}>{this.state.postTitle}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                                <div style={{ fontSize: "16px", fontWeight: "200", borderLeft: "5px solid black", textAlign: "left", padding: "0px 5px 0px 20px", maxWidth: "500px", margin: "0 auto", marginBottom: "30px" }}>
                                    <Markdown className={classes.markdown} source={this.state.postExcerpt} escapeHtml={false} />
                                </div>
                            </Grid>
                            <Card className={classes.card}>
                                <CardMedia image={(this.state.postThumbnail.includes('https://')) ? this.state.postThumbnail : "https://bankzia.com" + this.state.postThumbnail} component="img" alt={"test"} className={classes.media} />
                                <div className={classes.overlay}>
                                    <div className={classes.overlayContainer}>
                                    </div>
                                </div>
                            </Card>


                            <Grid item md={12} className={classes.container} style={{ textAlign: "left" }}>
                                <div style={{ textAlign: "left", maxWidth: "900px", margin: "0 auto", marginTop: "0px", padding: "15px" }}>
                                    {/* <p className={classes.markdown}>{JSON.stringify(this.state.data)}</p> */}
                                    {/* <p className={classes.markdown} style={{maxWidth: "900px", width: "900px"}}>{JSON.stringify(this.state.stateData)}</p> */}
                                    {/* <Typography className={classes.markdown} variant="h5" component="h5">{this.state.postTitle}</Typography> */}
                                    {/* <Typography className={classes.markdown} variant="p" component="p">Author: <Link href={`/author/${this.state.postAuthor.toLowerCase().replace(' ', '-')}`}>{this.state.postAuthor}</Link> | Published On: {this.state.postDate}</Typography>
                                    <Typography className={classes.markdown} variant="p" component="p">Categories: {this.state.postTopics.map((topic) => { return (<span key={uuidv4()}><a href={window.location.origin + `/topic/${topic.replace(/ /g, '-')}/`}>{topic}</a>, </span>) })}</Typography> */}
                                    <Grid item sm={12}>
                                        <div style={{fontFamily: "Garamond, Baskerville", fontWeight: "100", borderLeft: "5px solid black", textAlign: "left", padding: "0px 5px 0px 20px", maxWidth: "100%", margin: "0 auto", marginBottom: "10px" }}>
                                        <Typography variant="p" component="p" style={{ fontSize: "13px"}}>Published On: {this.state.postDate}</Typography>
                                        <Typography variant="p" component="p" style={{ fontSize: "13px"}}>Author: <Link href={`/author/${this.state.postAuthor.toLowerCase().replace(' ', '-')}`}>{this.state.postAuthor}</Link></Typography>
                                        <Typography variant="p" component="p" style={{ fontSize: "13px"}}>Categories: {this.state.postTopics.map((topic) => { return (<span key={uuidv4()}><a href={window.location.origin + `/${this.state.finalState.replace(/ /g, '-').toLowerCase()}/topic/${topic.replace(/ /g, '-').toLowerCase()}/`}>{topic}</a>, </span>) })}</Typography>
                                        </div>
                                    </Grid>

                                    {/* <p className={classes.markdown}>Categories: {this.state.postTopics.map((topic) => { return (<span key={uuidv4()}><a href={window.location.origin + `/topic/${topic.replace(/ /g, '-')}/`}>{topic}</a>, </span>) })}</p> */}
                                    <Markdown className={classes.markdown} source={this.state.postContent} escapeHtml={false} />
                                </div>
                                <Grid item md={12} className={classes.container} style={{ textAlign: "left", maxWidth: "900px", padding: "15px", margin: "0 auto", marginTop: "0px" }} >
                                    <h3>{this.state.listTitle}</h3>
                                    <p>{this.state.listBody}</p>
                                    {/* <p>{this.state.listType}</p> */}
                                    {(this.state.sections !== null && this.state.listReversed !== undefined && this.state.listReversed !== null && this.state.listReversed === true) ?
                                        this.state.sections.reverse().map((item, i) => (
                                            <div key={uuidv4()}>
                                                {(this.state.listNumbered === "yes") ? <h2>{this.state.sections.length - 1 - i + 1}. {item.title}</h2> : <h2>{item.title}</h2>}
                                                <Markdown source={item.body} escapeHtml={false} />
                                            </div>
                                        ))
                                        :
                                        (this.state.sections !== null && this.state.listReversed !== undefined && this.state.listReversed !== null && this.state.listReversed === false) ?
                                            this.state.sections.map((item, i) => (
                                                <div key={uuidv4()}>
                                                    {(this.state.listNumbered === "yes") ? <h2>{i + 1}. {item.title}</h2> : <h2>{item.title}</h2>}
                                                    <Markdown source={item.body} escapeHtml={false} />
                                                </div>
                                            ))
                                            : (this.state.sections !== null) ?
                                                this.state.sections.map((item, i) => (
                                                    <div key={uuidv4()}>
                                                        <h2>{item.title}</h2>
                                                        <Markdown source={item.body} escapeHtml={false} />
                                                    </div>
                                                ))
                                                : <></>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Faqs
                            layout={"expanded"}
                            // title={"Calculators"}
                            // byline={"Nestzia offers a wide variety of financial calculators to help our readers make more educated financial decisions."}
                            backgroundColor={"white"}
                            cellColor={"white"}
                            faqs={JSON.stringify([{
                                title: "Buying a home, refinancing, renting, renovating?",
                                body: "Our relestate calculators make it easy to make smarter financial decision the next time you purchase a home, refinance for a lower rate, rent an apartment, or decide to do any renovations."
                            }])
                            }
                        /> */}

                        <div style={{ margin: "40px 0", boxShadow: "0 0 1px 0 rgba(21,21,21,.1), 0 2px 8px 1px rgba(21,21,21,.2)" }} />
                    </div>
                )
            } else {
                return <Redirect to="/404" />
            }
        }
    }
}
export default withStyles(styles, { withTheme: true })(Home);