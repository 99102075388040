import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { withStyles } from "@material-ui/core/styles";
import Input from "./fields/Input";
import { Grid, Typography } from '@material-ui/core';

const styles = theme => ({
    root: {
        flexGrow: 1,
        maxWidth: "100vw",
        padding: "50px",
        margin: "0 auto",
        marginBottom: "-60px",
        '@media screen and (max-width: 700px)': {
            padding: "0px 0px 40px 0px",
          }
        // boxShadow: "0 0 1px 0 rgba(21,21,21,.1), 0 2px 8px -1px rgba(21,21,21,.2)"
    },
    container: {
        maxWidth: "900px",
        margin: "0 auto",
        // padding: "35px",
        '@media screen and (max-width: 700px)': {
            padding: "15px 0 0 0",
          }
    },
    title: {
        fontFamily: 'Chronicle Display,serif',
        fontWeight: 400,
        lineHeight: 1,
        fontSize: "35px",
        textAlign: "left",
        letterSpacing: "-1px",
        maxWidth: "800px",
        width: "80%",
        margin: "0 auto",
        // padding: "50px 20px 15px 20px",
        color: "black",
        '@media screen and (max-width: 700px)': {
            width: "80%",
          }
    },
    description: {
        borderBottom: "1px solid black",
        fontSize: "15px",
        textAlign: "left",
        letterSpacing: "-1px",
        maxWidth: "800px",
        width: "80%",
        margin: "0 auto",
        padding: "0px 20px 15px 20px",
        color: "black",
        marginBottom: "30px",
        textTransform: "uppercase",
        '@media screen and (max-width: 700px)': {
            width: "80%",
        }
    },
    thanks: {
        fontFamily: "Chronicle Display,serif",
        fontSize: "26px",
    }
})



class StateList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            state: props.state,
            heading: props.heading,
            recaptcha: false,
            ...props
            // language: "test"
        };
    }

    // componentDidMount() {
    // PLACEHOLDER
    // }

    render() {
        const { classes } = this.props;
        const handleChange = (value) => {
            console.log("Captcha value:", value);
            this.setState({ complete: true });
        };
        const getRecaptcha = () => {
            this.setState({ recaptcha: true });
        }
        return (
            <div className={classes.root} style={{backgroundColor: this.state.background, color: this.state.text, margin: this.state.margin}}>
                {(this.state.heading === undefined || this.state.heading === true)?
                <Grid container>
                    <Grid item md={12}>
                        <Typography className={classes.title} variant="h2" component="h2">Newsletter</Typography>
                        <Typography className={classes.description} variant="p" component="p">Get the latest finanical news, rates, and trips from our experts.</Typography>
                    </Grid>
                </Grid>
                :<></>}
                <Grid container direction="column" alignItems="center" className={classes.container} spacing={0}>
                    <Grid item md={12}>
                        {(this.state.complete !== true) ?
                            <Input
                                label="Email address"
                                onGetRecaptcha={getRecaptcha}
                                width={500}
                                className={classes.field}
                                color={this.state.text}
                            />
                            : 
                            <Typography className={classes.thanks} variant="h4" component="h4">Thanks for subscribing!</Typography>
                        }
                    </Grid>
                    <Grid item md={12}>
                        {(this.state.recaptcha === true && this.state.complete !== true) ?
                            <ReCAPTCHA
                                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                onChange={handleChange}
                            />
                            : <></>
                        }
                    </Grid>
                </Grid>
            </div>
        )
    }
}
export default withStyles(styles, { withTheme: true })(StateList);