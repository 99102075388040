import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from '@material-ui/core';
import Meta from "../components/Meta";
// import NewMortgageCalculator from "../components/MortgageCalculator";
// import Calculators from "../components/Calculators";
// import TopicsAndArticles from "../components/TopicsAndArticles";
import Faqs from "../components/Faqs";
// import { House, AttachMoney, CreditCard, DriveEta, HomeWork, Policy, MonetizationOn } from '@material-ui/icons';

// import ReactPWAInstallProvider from "react-pwa-install";
// import PWA from "../components/PWAinstall";

const styles = theme => ({
    container: {
        maxWidth: "70%",
        margin: "0 auto"
    },
    title: {
        fontFamily: "'DM Serif Display',Garamond,serif",
        fontWeight: 600,
        lineHeight: 1,
        fontSize: "2.3rem",
        textAlign: "left",
        letterSpacing: "-1px",
        maxWidth: "1300px",
        width: "80%",
        margin: "0 auto",
        padding: "50px 20px 15px 20px",
        color: "black",
    },
    description: {
        borderBottom: "1px solid black",
        textAlign: "left",
        letterSpacing: "-1px",
        maxWidth: "1300px",
        width: "80%",
        margin: "0 auto",
        marginBottom: "-60px",
        padding: "0px 20px 15px 20px",
        color: "black"
    }
})


class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: '',
            state: (this.props.location !== undefined && this.props.location.pathname !== undefined) ? this.props.location.pathname.split('/')[1].split('/')[0] : null,
            navigation: this.props.location
        };
    }

    componentDidMount = () => {
        this.setState({
            isLoaded: true
        })
    }

    render() {
        const { error, isLoaded } = this.state;
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            const { classes } = this.props;
            return (
                <div className={classes.root}>
                    <Meta
                        screen={"Bankzia Calculators"}
                        title={"Bankzia Calculators"}
                        description={"Calculate monthly mortgage payments, auto loan payments, affordability, amoritization, lease payments, and more. See how factors like loan terms, down payments and interest rates can affect your monthly payments and determin what fit within your budget."}
                        keywords={'calculator, mortgage calculator, auto calculator, budget calculator, home loan calculator, auto calculator, auto loan calculator, auto loan payment calculator, vehicle calculator, auto payment calculator, truck loan calculator, car loan calculator'}
                        image={'https://images.unsplash.com/photo-1587145820266-a5951ee6f620?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80'}
                        url={`https://bankzia.com/calculators/`}
                    />
                    <Grid container>
                        <Grid item md={12} className={classes.container}>
                            <Typography className={classes.title} variant="h1" component="h1">Financial Calculators</Typography>
                            <Typography className={classes.description} variant="p" component="p">Bankzia offers a wide variety of financial calculators to help our readers make more educated financial decisions.</Typography>
                        </Grid>
                    </Grid>
                    <Faqs
                        layout={"expanded"}
                        // title={"Calculators"}
                        // byline={"Nestzia offers a wide variety of financial calculators to help our readers make more educated financial decisions."}
                        backgroundColor={"white"}
                        cellColor={"white"}
                        faqs={JSON.stringify([{
                            title: "Buying a home, refinancing, renting, renovating?",
                            body: "Our relestate calculators make it easy to make smarter financial decision the next time you purchase a home, refinance for a lower rate, rent an apartment, or decide to do any renovations."
                        }])
                        }
                    />
                    {/* <TopicsAndArticles
                        title={"Home Calculators"}
                        byline={"Price a mortgage, refinance, amoritization, or rental"}
                        loanType={"articles"}
                        layout={"minimal"}
                        titleTextColor={"black"}
                        cellTextColor={"black"}
                        backgroundColor={"white"}
                        cellColor={"white"}
                        direction={'reverse'}
                        minimalTitle={"Get the upperhand by investing smarter"}
                        minimalByline={"Know how much you can afford before you make your next move."}
                        image={"https://images.unsplash.com/photo-1495433324511-bf8e92934d90?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"}
                        data={[{
                            name: "Purchase a home",
                            icon: <House />,
                            href: ""
                        }, {
                            name: "Refinance a home",
                            icon: <AttachMoney />,
                            href: ""
                        }, {
                            name: "Amoritization",
                            icon: <CreditCard />,
                            href: ""
                        }, {
                            name: "Rent / Lease",
                            icon: <HomeWork />,
                            href: ""
                        }, {
                            name: "ARV",
                            icon: <HomeWork />,
                            href: ""
                        }, {
                            name: "Affordability",
                            icon: <HomeWork />,
                            href: ""
                        }]}
                    /> */}
                    <Faqs
                        layout={"expanded"}
                        // title={"Calculators"}
                        // byline={"Nestzia offers a wide variety of financial calculators to help our readers make more educated financial decisions."}
                        backgroundColor={"white"}
                        cellColor={"white"}
                        faqs={JSON.stringify([{
                            title: "Buying a car, leasing a car, or buying insurance?",
                            body: "Don't let the dealer tell you what you're able to afford. Calculate your auto payments before your next trip to the dealership and make the numbers work for you."
                        }])
                        }
                    />
                    {/* <TopicsAndArticles
                        title={"Auto Calculators"}
                        byline={"Price our car payments, lease payements, and insurance payments"}
                        loanType={"articles"}
                        layout={"minimal"}
                        titleTextColor={"black"}
                        cellTextColor={"black"}
                        backgroundColor={"white"}
                        cellColor={"white"}
                        minimalTitle={"Don't let the dealer tell you what you can afford"}
                        minimalByline={"Make sure to crunch the numbers before you commit to your next auto loan, lease, or instance policy."}
                        image={"https://images.unsplash.com/photo-1575090536203-2a6193126514?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1051&q=80"}
                        data={[{
                            name: "Buying a car",
                            icon: <DriveEta />,
                            href: ""
                        }, {
                            name: "Leasing a car",
                            icon: <MonetizationOn />,
                            href: ""
                        }, {
                            name: "Auto Insurance",
                            icon: <Policy />,
                            href: ""
                        }]}
                    /> */}
                </div>
            )
        }
    }
}
export default withStyles(styles, { withTheme: true })(Home);