import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Link, Grid, CardContent, Card, Typography, CardMedia } from '@material-ui/core';
import postlist from "../posts.json";


const styles = theme => ({
    container: {
        maxWidth: "1300px",
        // backgroundColor: "red",
        // height: "400px",
        margin: "0 auto",
        // marginTop: "25px",
        paddingBottom: "25px",
        '@media screen and (max-width: 700px)': {
            margin: "0px",
        }
    },
    root: {
        flexGrow: 1,
        // width: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        // margin: "20px 0px",
        // height: "400px",
        backgroundColor: "#EEE"
    },

    // IMAGE CARD TITLE
    title: {
        // fontFamily: "'DM Serif Display',Garamond,serif",
        fontFamily: 'Chronicle Display,serif',
        fontWeight: 400,
        lineHeight: 1,
        fontSize: "2.1rem",
        textAlign: "left",
        letterSpacing: "-1px",
        maxWidth: "1300px",
        width: "80%",
        margin: "0 auto",
        padding: "65px 20px 5px 20px",
        color: "black",
        '@media screen and (max-width: 900px)': {
            padding: "35px 20px 5px 20px",
            // width: "90%",
        }
    },
    // IMAGE CARD DESCRIPTION
    description: {
        borderBottom: "1px solid black",
        textAlign: "left",
        letterSpacing: "-1px",
        maxWidth: "1300px",
        width: "80%",
        margin: "0 auto",
        padding: "2px 20px 15px 20px",
        color: "black",
    },

    // IMAGE CARD OVERLAY
    cardOverlay: {
        height: '400px',
        // width: "100vw",
        marginTop: "25px",
        marginBottom: "25px",
        backgroundSize: "cover",
        backgroundPosition: "center",
        color: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        '@media screen and (max-width: 900px)': {
            height: '400px',
            marginTop: "0px",
            marginBottom: "0px",
        }
    },
    // MAIN IMAGE CARD
    cardtitle: {
        fontSize: "2.5rem",
        // fontWeight: "600",
        textAlign: "left",
        maxWidth: "450px",
        padding: "0px 50px 0 50px",
        margin: "-30px 0px 0px 0px",
        // lineHeight: "2.5rem",
        // textShadow: "2px 2px #00143d",
        // fontFamily: '"DM Serif Display",Garimond,serif',
        fontFamily: 'Chronicle Display,serif',
        fontWeight: "200",
        '@media screen and (max-width: 900px)': {
            fontSize: "30px",
            padding: "0 30px 10px 30px",
            margin: "0px 0px 0px 0px",
        }
    },
    carddescription: {
        fontSize: "25px",
        // fontWeight: "200",
        textAlign: "left",
        maxWidth: "450px",
        // textShadow: "2px 2px #00143d",
        // fontFamily: 'Chronicle Display,serif',
        padding: "0 50px 0 50px",
        fontWeight: "100",
        '@media screen and (max-width: 900px)': {
            fontSize: "18px",
            fontWeight: "100",
            padding: "0 30px 0 30px",
        }
    },





    // calc: {
    //     boxShadow: "0px 0px 4px 0 rgba(21,21,21,.1), 0 2px 8px -1px rgba(21,21,21,.1)",
    //     // margin: "15px",
    //     minWidth: "300px",
    //     maxWidth: "350px",
    //     height: "auto",
    //     minHeight: "160px",
    //     display: "flex",
    //     flexDirection: "column",
    //     justifyContent: "center",
    //     borderRadius: "0px",
    //     borderLeftWidth: "8px",
    //     borderColor: "transparent",
    //     '&:hover': {
    //         backgroundColor: 'white',
    //         boxShadow: "0 9px 9px 0 rgba(21,21,21,.14), 0 16px 20px -5px rgba(21,21,21,.18)",
    //         transition: "border-color .2s ease-in,box-shadow .2s ease-in,color .2s ease-in",
    //         borderLeftWidth: "8px",
    //         borderLeftColor: "#0157ff",
    //         cursor: "pointer",
    //         color: "#0157ff"
    //     },
    // },
    minimalContainer: {
        maxWidth: "1300px",
        margin: "0 auto",
        // marginTop: "-20px",
        '@media screen and (max-width: 700px)': {
            margin: "0px",
        }
    },

    media: {
        // height: "0",
        paddingTop: '400px', // 16:9
        backgroundAttachment: "static", // "fixed"
        backgroundPosition: "center",
        '@media screen and (max-width: 700px)': {
            height: "400px",
            //   height: "100vh"
        }
    },
    card: {
        height: "400px",
        position: 'relative',
        borderRadius: "0px",
        marginBottom: "15px",
        '@media screen and (max-width: 700px)': {
            height: "400px",
            //   height: "100vh"
        }
    },


    minimalOppositeContainer: {
        maxWidth: "1300px",
        // backgroundColor: "red",
        // margin: "0 auto",
        // marginTop: "-20px",
        // paddingBottom: "25px"
    },
    calcMinimalOpposite: {
        position: "relative",
        right: "15px",
    },
    imageCardOpposite: {
        left: "15px",
    },





    imageCard: {
    // height: "400px",
    // position: 'relative',
    overflow: "inherit",
    borderRadius: "0px",
    // marginBottom: "15px",
    // marginTop: "30px",
    // '@media screen and (max-width: 900px)': {
        // height: "400px",
        // width: "100vw"
        // marginTop: "0px",
    // }
    },
    overlay: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        position: 'absolute',
        width: "100vw",
        top: '0px',
        height: "400px",
        color: 'white',
        fontSize: "10px",
        padding: "30px",
        background: 'radial-gradient(circle, rgba(14, 173, 192, 0.7) 0%, rgba(19, 156, 225, 0.6) 70%, rgba(28, 173, 234, 0.8) 100%) !important',
        textShadow: "2px 2px #00143d",
        '@media screen and (max-width: 700px)': {
            width: "100vw",
            height: "400px",
        }
    },
    // - - - - - 















    // TOPIC MINIMAL
    reverse: {
        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: "center",
    },
    topicMinimal: {
        borderBottom: "1px solid #eee",
        minWidth: "200px",
        maxWidth: "250px",
        paddingLeft: "10px",
        height: "60px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        borderRadius: "0px",
        borderLeftWidth: "8px",
        borderColor: "transparent",
        '&:hover': {
            backgroundColor: 'white',
            boxShadow: "0 9px 9px 0 rgba(21,21,21,.14), 0 16px 20px -5px rgba(21,21,21,.18)",
            transition: "border-color .2s ease-in,box-shadow .2s ease-in,color .2s ease-in",
            borderLeftWidth: "8px",
            borderLeftColor: "#0157ff",
            cursor: "pointer",
            color: "#0157ff",
            position: "inherit",
            zIndex: "100 !important"
        },
        '@media screen and (max-width: 900px)': {
            paddingLeft: "10px",
            minWidth: "75vw",
        }
    },
    topicHeading: {
        fontFamily: "CircularStd-Book,Arial,sans-serif",
        fontSize: "1.0rem",
        lineHeight: "0px",
        float: "left",
        fontWeight: "500",
        maxWidth: "250px",
        marginBottom: "-10px",
    },
    icon: {
        paddingRight: "8px",
        marginBottom: "-12px"
    },
    topicContainer: {
        height: "auto"
    },
    // - - - - - - 









    // ARTICLE GRID
    article: {
        boxShadow: "0px 0px 4px 0 rgba(21,21,21,.1), 0 2px 8px -1px rgba(21,21,21,.1)",
        minWidth: "300px",
        maxWidth: "350px",
        height: "auto",
        minHeight: "160px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        borderRadius: "0px",
        borderColor: "transparent",
        '&:hover': {
            backgroundColor: 'white',
            boxShadow: "0 9px 9px 0 rgba(21,21,21,.14), 0 16px 20px -5px rgba(21,21,21,.18)",
            transition: "border-color .2s ease-in,box-shadow .2s ease-in,color .2s ease-in",
            borderLeftWidth: "8px",
            borderLeftColor: "#0157ff",
            cursor: "pointer",
            color: "#0157ff"
        },
    },
    articleFeaturedImage: {
        // paddingTop: '170px', // 16:9
        maxHeight: "200px",
        backgroundAttachment: "static", // "fixed"
        backgroundPosition: "center",
        '@media screen and (max-width: 700px)': {
            // height: "170px"
        }
    },
    CardContent: {
        minHeight: "70px"
    },
    articleHeading: {
        fontSize: "17px",
        lineHeight: "18px",
        marginBottom: "7px",
        fontFamily: "'CircularStd-Bold',Arial,sans-serif",
        textAlign: "left",
        fontWeight: "600",
        maxWidth: "250px",
        margin: "0 auto"
    },
    articleByline: {
        marginBottom: "-20px",
        fontSize: "14px",
        lineHeight: "16px",
        textAlign: "left",
        margin: "0 auto",
        maxWidth: "250px"
    },
    // - - - - -

    articleMinimal: {
        minWidth: "100%",
        maxWidth: "auto",
        height: "auto",
        // maxHeight: "30px",
        '&:hover': {
            borderBottomWidth: "0px",
            borderBottomColor: "#0157ff",
            color: "#0157ff"
        },
    },
    link: {
        '&:hover': {
            color: "#0157ff !important"
        },
    },
    articleMinimalHeading: {
        fontSize: "14px",
        lineHeight: "14px",
        marginBottom: "-6px",
        fontFamily: "'CircularStd-Bold',Arial,sans-serif",
        textAlign: "left",
        fontWeight: "400",
        padding: "3px 30px",
        // maxWidth: "90%",
        // margin: "0 auto"
        textDecoration: "none",
        '&:hover': {
            color: "#0157ff !important",
            textDecoration: "none"
        },
    },
})


class Calculators extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            state: props.state,
            metrics: props.metrics,
            layout: props.layout,
            title: props.title,
            byline: props.byline,
            loanType: props.loanType,
            minimalTitle: props.minimalTitle,
            minimalByline: props.minimalByline,
            preview: props.image,
            data: props.data,
            backgroundColor: props.backgroundColor,
            titleTextColor: props.titleTextColor,
            cellColor: props.cellColor,
            cellTextColor: props.cellTextColor,
            direction: props.direction
        };
    }

    componentDidMount() {
        console.log(this.state.metrics)
    }

    render() {
        const { classes } = this.props;
        let posts = JSON.parse(JSON.stringify(postlist));

        // GRID POST WIDGET LAYOUT
        if (this.state.layout === "grid") {
            return (
                <div className={classes.root} style={{ backgroundColor: this.state.backgroundColor }}>
                    <Grid container className={classes.container}>
                        <Grid item md={12}>
                            <Typography className={classes.title} variant="h2" component="h2" style={{ color: this.state.cellTextColor }}>{this.state.title}</Typography>
                            <Typography className={classes.description} variant="p" component="p" style={{ color: this.state.cellTextColor }}>{this.state.byline}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container} spacing={2}>
                        {posts.map((article, i) => (
                            (article.status === "Published" && i <= 2) ?
                                <Grid item md={4} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <Link href={window.location.origin + "/article/" + article.slug}>
                                        <Card className={classes.article} variant="outlined" style={{ backgroundColor: this.state.cellColor, color: this.state.cellTextColor }}>
                                            <CardMedia image={(article.thumbnail.includes('https://'))? article.thumbnail : "https://Bankzia.com" + article.thumbnail} component="img" alt={"test"} className={classes.articleFeaturedImage} />
                                            <CardContent className={classes.CardContent}>
                                                {/* <div style={{ backgroundImage: `radial-gradient(circle, rgba(14, 173, 192, 0.7) 0%, rgba(19, 156, 225, 0.6) 70%, rgba(28, 173, 234, 0.8) 100%), url(${"https://Bankzia.com"+ article.thumbnail})` }} className={classes.cardOverlay}> */}
                                                <Typography className={classes.articleHeading} variant="h5" component="h5" style={{ color: this.state.cellTextColor }}>{article.title}</Typography>
                                                <Typography className={classes.articleByline} variant="p" component="p" style={{ color: this.state.cellTextColor }}>By: {article.author} | On: {article.date}</Typography>
                                            </CardContent>
                                            {/* </div> */}
                                        </Card>
                                    </Link>
                                </Grid>
                                : <></>
                        ))}
                    </Grid>
                </div>
            )

            // MINIMAL LAYOUT TYPE - POPULAR TOPICS AND MORE
        } else if (this.state.layout === "minimal") {
            return (
                <div className={classes.root} style={{ backgroundColor: this.state.backgroundColor }}>
                    <Grid container className={[classes.minimalContainer, classes[this.state.direction]]}>
                        <Grid item md={6} sm={12}>
                            <Card className={classes.imageCard}>
                                <div style={{ backgroundImage: `radial-gradient(circle, rgba(14, 173, 192, 0.7) 0%, rgba(19, 156, 225, 0.6) 70%, rgba(28, 173, 234, 0.8) 100%), url(${this.state.preview})` }} className={classes.cardOverlay}>
                                    <Typography className={classes.cardtitle} variant="h2" component="h2">{this.state.minimalTitle}</Typography>
                                    <Typography className={classes.carddescription} variant="h2" component="h2">{this.state.minimalByline}</Typography>
                                </div>
                            </Card>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Grid container>
                                <Grid item md={12} style={{ margin: "0 auto" }}>
                                    <Typography className={classes.title} variant="h2" component="h2">{this.state.title}</Typography>
                                    <Typography className={classes.description} variant="p" component="p">{this.state.byline}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container className={[classes.container, classes.topicContainer]} spacing={2}>
                                {this.state.data.map((topic) => (

                                    // MANUALLY INSERTED TOPIC DATA
                                    (topic.author === undefined) ?
                                        (this.state.data.length > 4) ?
                                            <Grid item md={6} sm={12} style={{ margin: "0 auto" }}>
                                                <Link href={topic.href}>
                                                    <Card className={classes.topicMinimal} style={{ backgroundColor: this.state.cellColor, color: this.state.cellTextColor }}>
                                                        <CardContent>
                                                            <Grid container direction="row" alignItems="center">
                                                                <span className={classes.icon}>{topic.icon}</span>
                                                                <Typography className={classes.topicHeading} variant="h5" component="h5" style={{ color: this.state.cellTextColor }}>{topic.name}</Typography>
                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                </Link>
                                            </Grid>

                                            :
                                            // MANUALLY INSERTED BUT WITH AN AUTHOR ATTRIBUTE - MIGHT NOT BE IN USE ANYMORE
                                            <Grid item md={12} xs={12}>
                                                <Link href={topic.href}>
                                                    <Card className={classes.topicMinimal} variant="outlined" style={{ backgroundColor: this.state.cellColor, color: this.state.cellTextColor }}>
                                                        <CardContent>
                                                            <Grid container direction="row" alignItems="center">
                                                                <span className={classes.icon}>{topic.icon}</span>
                                                                <Typography className={classes.topicHeading} variant="h5" component="h5" style={{ color: this.state.cellTextColor }}>{topic.name}</Typography>
                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                </Link>
                                            </Grid>
                                        :


                                        // GET POSTS.JSON DATA
                                        (topic.author !== undefined && topic.id !== undefined && topic.status === "Published") ?
                                            <Grid item md={12} xs={12}>
                                                <Link href={window.location.origin + "/article/" + topic.slug}>
                                                    <Typography className={classes.articleMinimalHeading} variant="p" component="p" style={{ color: this.state.cellTextColor, textDecoration: "none" }}>• {topic.title}</Typography>
                                                </Link>
                                            </Grid>
                                            :

                                            // GETTING POSTS.JSON DATA, HOWEVER, STATUS IS NOT PUBLISHED
                                            (topic.author !== undefined && topic.id !== undefined && topic.status !== "Published") ?
                                                <></>
                                                :
                                                // ELSE EVERYTHING ELSE
                                                <Grid item md={12} xs={12}>
                                                    <Link href={topic.href} className={classes.link}>
                                                        <Card className={[classes.topicMinimal, classes.articleMinimal]} variant="outlined" style={{ backgroundColor: this.state.cellColor, color: this.state.cellTextColor }}>
                                                            <CardContent>
                                                                <Grid container direction="row" alignItems="center">
                                                                    <Typography className={classes.articleMinimalHeading} variant="h5" component="h5" style={{ color: this.state.cellTextColor }}>{topic.name}</Typography>
                                                                </Grid>
                                                            </CardContent>
                                                        </Card>
                                                    </Link>
                                                </Grid>

                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            )
        }
    }
}
export default withStyles(styles, { withTheme: true })(Calculators);