import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Grid } from '@material-ui/core';

const styles = theme => ({
  root: {
    marginBottom: "0px",
    '@media screen and (max-width: 700px)': {
      marginBottom: "10px",
    }
  },
  cardOverlay: {
    height: '400px',
    width: "100vw",
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "black",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    '@media screen and (max-width: 700px)': {
      height: "400px"
    }
  },
  card: {
    height: "400px",
    position: 'relative',
    borderRadius: "0px",
    '@media screen and (max-width: 700px)': {
      height: "400px"
    }
  },
  title: {
    fontSize: "2.5rem",
    fontWeight: "400",
    textAlign: "center",
    padding: "0px 50px 0 50px",
    margin: "-30px 0px 0px 0px",
    lineHeight: "4.2rem",
    fontFamily: 'Chronicle Display,serif',
    '@media screen and (max-width: 700px)': {
      fontSize: "40px",
      lineHeight: "40px",
      padding: "0px 20px 0 20px",
      width: "90vw"
    },
    '@media screen and (max-width: 400px)': {
      fontSize: "30px",
      lineHeight: "30px",
      padding: "0 20px 0 20px",
      width: "90vw"
    }
  },

  description: {
    fontSize: "23px",
    fontWeight: "100",
    textAlign: "center",
    maxWidth: "600px",
    margin: "0 auto",
    padding: "0 50px 0 50px",
    '@media screen and (max-width: 700px)': {
      fontSize: "20px",
      lineHeight: "20px",
      padding: "0px 20px 0 20px",
      width: "90vw"
    },
    '@media screen and (max-width: 400px)': {
      fontSize: "16px",
      lineHeight: "16px",
      padding: "0 20px 0 20px",
      width: "90vw"
    }
  },
})


class HomeHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      state: props.state,
      // preview: `https://images.unsplash.com/photo-1507919909716-c8262e491cde?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1189&q=80`
      preview: `https://images.unsplash.com/photo-1606787503474-b28a4072fbc5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=750&q=80`
    };
  }

  // componentDidMount() {
  // PLACEHOLDER
  // }

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.root}>
        <div className={classes.card}>
          <Grid item md={12} style={{
            // backgroundImage: `linear-gradient(rgb(223 241 255), rgba(238, 247, 255, 0)), url(${this.state.preview})`
            backgroundImage: `linear-gradient(rgba(223, 241, 255, .4) 0%, rgba(238, 247, 255, .8) 100%), url(${this.state.preview})`
            // backgroundImage: `radial-gradient(circle, rgba(14, 173, 192, 0.7) 0%, rgba(19, 156, 225, 0.6) 70%, rgba(28, 173, 234, 0.8) 100%), url(${this.state.preview})` 
          }} className={classes.cardOverlay}>
            <div className={classes.headerCopy}>
              <h1 className={classes.title}>Guiding you through life's financial journey.</h1>
              <p className={classes.description}>Compare rates and crunch numbers to help select the right mortgage, auto loan, auto lease, rental unit and more.</p>
            </div>
          </Grid>
        </div>
      </Grid>
    )
  }
}
export default withStyles(styles, { withTheme: true })(HomeHeader);