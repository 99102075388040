import React from 'react';
import { withStyles } from "@material-ui/core/styles";
// import { Grid, Paper } from '@material-ui/core';
// import HomeHeader from '../components/HomeHeader';
import Meta from "../components/Meta";
// import MortgageCalculator from "mortgage-calculator-react";
import AutoCalculator from "../components/AutoCalculator";
// import Calculators from "../components/Calculators";
// import Newsletter from "../components/Newsletter";
// import TopicsAndArticles from "../components/TopicsAndArticles";
// import Faqs from "../components/Faqs";
// import { House, AttachMoney, LocalAtm, CreditCard, DriveEta, HomeWork } from '@material-ui/icons';

// import ReactPWAInstallProvider from "react-pwa-install";
// import PWA from "./PWAinstall";

const styles = theme => ({
  grid: {
    // flexGrow: 1,
    // maxWidth: '1000px',
    // margin: '0 auto',
    // padding: "10px"
  },
  paper: {
    // padding: theme.spacing(2),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
  },
  text: {
    fontWeight: "100",
    padding: "25px",
    backgroundColor: "#263238",
    color: "white",
    fontSize: "12px"
  },
  image: {
    height: "auto",
    width: "100vw",
  },
  items: {
    fontWeight: "100",
    fontSize: "12px",
    padding: "8px"
  }
})


class Auto extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: '',
      navigation: this.props.location,
      state: (this.props.location !== undefined && this.props.location.pathname !== undefined) ? this.props.location.pathname.split('/')[2].split('/')[0].replace(/-/g, ' ') : null,
      slug: (this.props.location !== undefined && this.props.location.pathname !== undefined) ? this.props.location.pathname.split('/')[1].split('/')[0] : null,
    };
  }

  componentDidMount = () => {
    this.setState({
      isLoaded: true
    })
  }

  render() {
    const { error, isLoaded } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      const { classes } = this.props;
      return (
          (this.state.slug === "auto-loan-calculator") ?
            <div className={classes.root}>
              <Meta
                screen={"Auto Loan Payment Calculator"}
                title={"Auto Loan Payment Calculator"}
                description={"Estimate your monthly payments with the Bankzia.com auto loan calculator and see how factors like loan term, down payment and interest rate affect payments."}
                keywords={'calculator, auto calculator, auto loan calculator, vehicle calculator, auto payment calculator, truck loan calculator, car loan calculator'}
                image={'https://images.unsplash.com/photo-1531181616225-f8e50c1ab53e?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'}
                url={`https://bankzia.com/auto-loan-calculator/`}
              />
              <AutoCalculator data={
                  {
                      title: "Calculate your next auto loan.",
                      byline: 'Estimate your monthly auto loan payments with the Bankzia.com auto loan calculator and see how factors like loan terms, down payments and interest rates can affect your vehicle loan payments.',
                      navigation: this.state.navigation,
                      type: 'both',
                      price: 15000,
                      percentage: 7,
                      loan_term: 72,
                      sales_tax: 7,
                      dealer_fee: 0,
                      rebates: 0,
                      down_payment: 0,
                      trade_in: 0,
                  }
                }/>
            </div>
            :
            (this.state.slug === "auto-budget-calculator")?
            <div className={classes.root}>
              <Meta
                screen={"Auto Budget Calculator"}
                title={"Auto Budget Calculator"}
                description={"Calculate your monthly vehicle payments and determine which vehicle price bets fits within your monthly budget using the Bankzia.com auto loan budget calculator. See how factors like loan terms, down payments and interest rates affect your monthly payments and fit within your budget."}
                keywords={'calculator, budget calculator, auto calculator, auto loan calculator, auto loan payment calculator, vehicle calculator, auto payment calculator, truck loan calculator, car loan calculator'}
                image={'https://images.unsplash.com/photo-1531181616225-f8e50c1ab53e?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'}
                url={`https://bankzia.com/auto-budget-calculator/`}
              />
              <AutoCalculator data={{
                      title: "Calculate auto loans and see what fits within your budget",
                      byline: 'Calculate your monthly vehicle payments and determine which vehicle price bets fits within your monthly budget using the Bankzia.com auto loan budget calculator. See how factors like loan terms, down payments and interest rates affect your monthly payments and fit within your budget.',
                      navigation: this.state.navigation,
                      type: "affordability",
                      price: 15000,
                      percentage: 7,
                      loan_term: 48,
                      sales_tax: 7,
                      dealer_fee: 0,
                      rebates: 0,
                      down_payment: 0,
                      trade_in: 0,
                      income: 3100,
                      housing: 1100,
                      expenses: 600
              }}/>
            </div>
            : <></>
      )
    }
  }
}
export default withStyles(styles, { withTheme: true })(Auto);