import React from 'react';
import Helmet from "react-helmet";

class Meta extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: props.screen,
      title: props.title,
      description: props.description,
      keywords: props.keywords,
      image: (props.image.includes('http'))? props.image : "https://bankzia.com" + props.image,
      url: props.url,
      canonical: (props.canonical)? props.canonical : props.url
    };
  }

  // componentDidMount() {
  // PLACEHOLDER
  // }


  render() {
    return (
      <Helmet>
        <title data-react-helmet="true">__TITLE__</title>
        <title>{this.state.title}</title>
        <meta name="name" content={`Bankzia | ${this.state.title}`} />
        <meta name="keywords" content={this.state.keywords} />
        <meta
          itemprop="description"
          content={this.state.description}
        />
        <meta
          itemprop="Description"
          content={this.state.description}
        />
        <meta
          itemprop="image"
          content={this.state.image}
        />

        <meta property="og:url" content={this.state.url} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={this.state.title} />
        <meta
          property="og:description"
          content={this.state.description}
        />
        <meta
          property="og:image"
          content={this.state.image}
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={this.state.title} />
        <meta
          name="twitter:description"
          content={this.state.description}
        />
        <meta
          name="twitter:image"
          content={this.state.image}
        />
        <link
          rel="canonical"
          content={this.state.canonical}
        />
      </Helmet>
    )
  }
}
export default Meta;