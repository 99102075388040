import React from 'react';
import { withStyles } from "@material-ui/core/styles";
// import { Grid } from '@material-ui/core';
import HomeHeader from './ui_elements/home/HomeHeader';
import ColorBlocks from './ui_elements/colorblocks/Colorblocks';
import Topics from './ui_elements/topics/TopicsCollection';

import Meta from "./Meta";
import HouseSharpIcon from '@material-ui/icons/HouseSharp';
import EmojiTransportationSharpIcon from '@material-ui/icons/EmojiTransportationSharp';
import MonetizationOnSharpIcon from '@material-ui/icons/MonetizationOnSharp';
import AccountBalanceSharpIcon from '@material-ui/icons/AccountBalanceSharp';

import ArticleGrid from "./ui_elements/articles/ArticleGrid";
import ArticleList from "./ui_elements/articles/ArticleList";

import ReactPWAInstallProvider from "react-pwa-install";
import PWA from "./PWAinstall";

const styles = theme => ({
  root: {
    // flexGrow: 1
  }
})


class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: '',
      state: (this.props.location !== undefined && this.props.location.pathname !== undefined) ? this.props.location.pathname.split('/')[1].split('/')[0] : null,
      navigation: this.props.location
    };
  }

  componentDidMount = () => {
    this.setState({
      isLoaded: true
    })
  }

  render() {
    const { error, isLoaded } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      const { classes } = this.props;
      return (
        <div className={classes.root}>
          <Meta
            screen={"address"}
            title={`Bankzia | Personal Finance Advice & Tools`}
            description={"Check out the web's best free mortgage calculator to save money on your home loan today. Estimate your monthly payments with PMI, taxes, homeowner's insurance, HOA fees, current loan rates & more. Also offers loan performance graphs, biweekly savings comparisons and easy to print amortization schedules. Our calculator includes amoritization tables, bi-weekly savings estimates, refinance info, current rates and helpful tips. This site also provides users with many helpful online financial planning tools."}
            keywords={"calculator, mortgage calculator, home, mortgage rates, mortgage payment calculator, simple mortgage calculator, mortgage loan calculator, refinance calculator, mortgage rate calculator, loan calculator, insurance, amortization, easy, mortgage, assessment, loan, annual, amount, payments, monthly, fixed, PMI, rates"}
            image={"https://images.unsplash.com/photo-1451934403379-ffeff84932da?ixlib=rb-1.2.1&auto=format&fit=crop&w=1242&q=80"}
            url={`https://Bankzia.com`}
          />
          
          <HomeHeader />

          <ColorBlocks top={-50} bottom={10} width={1100} data={[{
              byline: "Home Buying",
              title: 'Mortgage',
              background: "#E3FAF2",
              text: "black",
              button: "Calculate Now",
              buttonColor: "green",
              width: 3,
              href: "/mortgage-calculator/",
              icon: HouseSharpIcon,
              buttonWidth: "full"
            }, {
              byline: "AUTO CALCULATOR",
              title: "Auto Budget",
              background: "#EEF7FF",
              text: "black",
              button: "AFFORDABILITY",
              buttonColor: "blue",
              width: 3,
              href: "/auto-budget-calculator/",
              icon: AccountBalanceSharpIcon,
              buttonWidth: "full"
            }, {
              byline: "Vehicle Purchase",
              title: "Auto",
              background: "#005FB9",
              text: "white",
              button: "Calculate Now",
              buttonColor: "white",
              width: 3,
              href: "/auto-loan-calculator/",
              icon: EmojiTransportationSharpIcon,
              buttonWidth: "full"
            }, {
              byline: "Start Saving",
              title: "Investing",
              background: "#EEE",
              text: "black",
              button: "READ MORE",
              buttonColor: "navy",
              width: 3,
              href: "/topic/home-buying/",
              icon: MonetizationOnSharpIcon,
              buttonWidth: "full"
            }]} />

          <ColorBlocks data={[{
            byline: "KNOW YOUR LOANS",
            title: "Learn the pros and cons of home equity loans and find out if an alternative loan type is right for you.",
            background: "#E3FAF2",
            text: "black",
            button: "PROS & CONS",
            buttonColor: "green",
            width: 7,
            href: "/article/pros-and-cons-of-home-equity-loans/"
          },
          {
            byline: "HOME SWEET HOME",
            title: "What are the different types of home loans?",
            background: "#005FB9",
            text: "white",
            button: "LOAN TYPES",
            buttonColor: "white",
            width: 5,
            href: "/article/what-are-the-different-types-of-home-loans-and-which-mortgage-is-right-for-you/"
          },
          {
            byline: "RETIRE RIGHT",
            title: "How does a reverse mortgage work?",
            background: "#EEE",
            text: "black",
            button: "READ MORE",
            buttonColor: "navy",
            width: 5,
            href: "/article/how-does-a-reverse-mortgage-work/"
          },
          {
            byline: "NEW HOME",
            title: "Common Mistakes Made By First-Time Home Buyers and How to Avoid Them.",
            background: "#EEF7FF",
            text: "black",
            button: "FIRST-TIME BUYERS",
            buttonColor: "blue",
            width: 7,
            href: "/article/12-common-mistakes-made-by-first-time-home-buyers-and-how-to-avoid-them/"
          },
          {
            byline: "CALCULATE RIGHT",
            title: "Calculate your next mortgage today using our free Mortgage Calculator.",
            background: "#E3FAF2",
            text: "black",
            button: "CRUNCH NUMBERS",
            buttonColor: "green",
            width: 6,
            href: "/mortgage-calculator/"
          },
          {
            byline: "KNOW YOUR BUDGET",
            title: "Let our auto loan affordability calculator show you what vehicles you can afford.",
            background: "#005FB9",
            text: "white",
            button: "LET'S DO THIS!",
            buttonColor: "white",
            width: 6,
            href: "/auto-budget-calculator/"
          },
          ]} />
          <ArticleList
            title={"New Articles"}
            byline={"Check out some of our newest articles."}
            contentType={"articles"}
            layout={"grid"}
            titleTextColor={"black"}
            cellTextColor={"black"}
            backgroundColor={"white"}
            cellColor={"white"}
          />
          <ArticleGrid
            title={"New articles published daily"}
            byline={"Read some of our latest articles to help guide you on your next home buying adventure."}
            contentType={"articles"}
            layout={"grid"}
            titleTextColor={"black"}
            cellTextColor={"black"}
            backgroundColor={"white"}
            cellColor={"white"}
          />

          <Topics
            title={"Popular Topics"}
            byline={"Check out some of our most popular topics."}
          />



          {/* <Calculators
            title={"Maximize your savings with our financial calculators"}
            byline={"Our team offers several great calculators to assist with your next home, auto, boat, rental, lease, or affordability calculation."}
            loanType={"home"}
            backgroundColor={"red"}
            layout={"minimal"}
            minimalTitle={"Which mortgage is right for you?"}
            minimalByline={"Is a 15 Year or 30 Year mortgage in your families future?"}
            image={"https://images.unsplash.com/photo-1606787842514-a7998e6bee38?ixid=MXwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"}
            data={[{
              name: "Mortgage",
              byline: "Calculate your future mortgage payments.",
              icon: "",
              type: "home",
              url: ""
            }, {
              name: "Mortgage affordability",
              byline: "Find a home price that best suits your budget.",
              icon: "",
              type: "home"
            }, {
              name: "Amortization",
              byline: "Determine how much of your monthly mortgage payment goes towards interest/",
              icon: "",
              type: "home"
            }, {
              name: "Rental affordability",
              byline: "Find a rental unit that best suits your budget using our rental affordability calculator.",
              icon: "",
              type: "home"
            }, {
              name: "Auto",
              byline: "Calculate your future car loan.",
              icon: "",
              type: "auto"
            }, {
              name: "Auto affordability",
              byline: "Determine what auto loan best fits your budget.",
              icon: "",
              type: "auto"
            }, {
              name: "Auto lease affordability",
              byline: "Find an auto lease that best suits your budget.",
              icon: "",
              type: "auto"
            }]}
          /> */}
          {/* <NewMortgageCalculator title={"Compare current mortgage rates for today."} navigation={this.state.navigation} /> */}


          {/* <TopicsAndArticles
            title={"Popular Topics"}
            byline={"Check out some of our most popular topics."}
            loanType={"articles"}
            layout={"minimal"}
            titleTextColor={"black"}
            cellTextColor={"black"}
            backgroundColor={"white"}
            cellColor={"white"}
            // direction={''}
            minimalTitle={"Get on track with your finances today"}
            minimalByline={"Give one of our free calculators a try to help direct your next financial move."}
            image={"https://source.unsplash.com/1600x900/?loan"}
            data={[{
              name: "Purchase a home",
              icon: <House />,
              href: ""
            }, {
              name: "Refinance a home",
              icon: <AttachMoney />,
              href: ""
            }, {
              name: "Purchase a car",
              icon: <DriveEta />,
              href: ""
            }, {
              name: "Lease a car",
              icon: <CreditCard />,
              href: ""
            }, {
              name: "Save for retirement",
              icon: <LocalAtm />,
              href: ""
            }, {
              name: "Rent or lease a home",
              icon: <HomeWork />,
              href: ""
            }]}
          /> */}
          <ReactPWAInstallProvider enableLogging>
            <PWA />
          </ReactPWAInstallProvider>
          {/* </Grid> */}
          {/* </Grid> */}
        </div>
      )
    }
  }
}
export default withStyles(styles, { withTheme: true })(Home);