import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from '@material-ui/core';
import Meta from "../components/Meta";
// import JsonLD from "../components/JsonLD";
import Markdown from "react-markdown";
import pagelist from "../pages.json";
import stateposts from "../state_posts.json";

// import { Redirect } from "react-router-dom"
import Newsletter from "../components/ui_elements/Newsletter";
import Error from './404';
import stateData from "../data.json";
import { v4 as uuidv4 } from 'uuid';
import Blogroll from "../components/ui_elements/blogroll/Blogroll";

// import firebase from 'firebase';
// var app = firebase.initializeApp({
//     apiKey: '-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCUkuPOlH8u53bX\n3oFQ3A6HKFEAsZt1+9gettlkQ5Pktmj6yAOB9OXRKpIiZTE8HeV3qdYteHit0aCp\n0N/P7ds4bgmPNvKbKVWSp0KDBuJCcvC6BoorO3n6ZXn7LEyLnvj4Fetc36tqnAFr\n5pY8jkrv7u73S6zw3ziZzMaHIlbqufd6DpQfsdCaRhOHF6bl9hJPdofhz1UkmyZb\nm07VU83nayr6vRLSRgmBWM4NK1qF2iVRwiyboGxOkasMOOGM2B2t05Imrzyhq/mm\nnvDlkcwSCk07nv/kfPpe/JzFjoGiSeQXvkefPe7w2m4t6VWTXm3bMjlNK1+/H6DH\n5/v5LeTTAgMBAAECggEAAYaUok1yFOg5lzPovgzUdVzboBcy0n5YlNAp4Va0URfy\ncgjVyGLcbU5zm7jQTZ68+YKb+XGQQ7CwZq4Hblox8x1mKbtVuHQvNcdT7oaGkkod\n4LsqdhExSnn6S7MDwjdKtTl8ZkLBvuGFPjdq+Yo4iMg6Wf3D8+FG8oVg0Ihwvg5Q\n09akw5AHM89UulNJwfzMgVeNgQnjHJ5do5g2+G4RpjHmoAYFr+yy9TubPlcbbSUv\nB+8lAL0xxx0WtTGxhK1zz9nCb9EPf4HLJOYAZBVvUt4Pr2hHfAPojERthtb6PbxM\nc6iVbC77eglQ0ZqWV9zAu6Dl6SnzWzvzRV6YegO42QKBgQDElHxjCWXN7ZmbJtyR\nHzUgko1nOMGwojwoErQ9ypemBRZlRpZ0rGj8iQEP5JBsfV5EamELm+Mb3Tz4KLTe\nCSiEtv3kpVJtU+U0BJcezA3ILFiQe8Ni9UwRiP+fEArP3u7LuX7jC3BMxweHG4qC\nZ9y3QGqPywmegKnkIl1GhZ//9wKBgQDBe6T7X8pjWrARzWVGvcsG/GnBuB6z6aeM\nkHiKI9NUSfelZk5JuO0SUkeV26I+9ZzntLikRsvz5EIgtno7q6M9LQM2/GslDiQZ\nb2I+GNI4RovebPJ6NQhf1HnWDAz7qs5H7sfrs/tot8lytEKySSPpbmpDKSyWkgc7\nkvQiY+IDBQKBgEiS1RUvDAGpnVL1VurQzalX0ZJxFrjiPDlDJn3FhJULPchn9fXf\nDOdmoRi/9j3HTv0NFQTXKVJJLFAlnxh14xqUAEngavznwy+gHjaFePUUwiGdP3YS\nP2Id5NlRb9xsXlaiBeFGgNAnjnTnywv30jlpM64gdE2rDI2nzi3R+U7jAoGAb210\n62wUU7nTsrz2eZIuTdyqT21pCTYkTziYov3U5DMhEzljFuQQbD/EiFKQEvBCp9KV\nj85SASjQyKQuqxTDEyVMN6uRTSrisV9LVfXXg59lR/i4gJbwNrhYXmRcScfputWS\nf3UM8hb+Rjf4PVMQAoybWAhrbF+3GKEeRnv9mr0CgYBBwjLMZg8KBYPcSAHA3csP\nR7vonEuYWcIv7LWYhEAXtiA5Mfy/6578pZ+4o++Hfgpi5KRjtR1XSBFFAyGihQDM\nUpfPaf7T6qGxseWKug7G+Hhd/emRirG0FvGGIv922ksx9clJfnUlpIXs/0nWDZil\nu/iBqCmTNJE1S9/k9ZO0KA==\n-----END PRIVATE KEY-----\n',
//     authDomain: 'https://accounts.google.com/o/oauth2/auth',
//     databaseURL: 'https://bankzia-default-rtdb.firebaseio.com/',
//     projectId: 'bankzia',
//     storageBucket: '<your-storage-bucket>',
//     messagingSenderId: '<your-sender-id>'
//   });

// import NewMortgageCalculator from "../components/MortgageCalculator";
// import Calculators from "../components/Calculators";
// import TopicsAndArticles from "../components/TopicsAndArticles";
// import Faqs from "../components/Faqs";
// import { House, AttachMoney, LocalAtm, CreditCard, DriveEta, HomeWork, Policy, MonetizationOn } from '@material-ui/icons';

// import ReactPWAInstallProvider from "react-pwa-install";
// import PWA from "../components/PWAinstall";

const styles = theme => ({
    body: {
        maxWidth: "1000px",
        marginTop: "80px",
        margin: "0 auto",
        float: "left",
        wordWrap: "break-word",
        textOverflow: "wrap",
        textAlign: "left"
    },
    root: {
        marginBottom: "50px"
    },
    grid: {
        maxWidth: '800px',
        marginTop: '100px',
        margin: '0 auto',
        padding: "10px",
    },
    container: {
        maxWidth: "1300px",
        margin: "0 auto",
        marginTop: "60px",
        padding: "0 20px",
    },
    title: {
        fontFamily: 'Chronicle Display,serif',
        fontWeight: 400,
        lineHeight: 1,
        fontSize: "2.3rem",
        textAlign: "left",
        letterSpacing: "-1px",
        maxWidth: "1300px",
        width: "80%",
        margin: "0 auto",
        padding: "50px 20px 15px 20px",
        color: "black",
    },
    description: {
        borderBottom: "1px solid black",
        textAlign: "left",
        letterSpacing: "-1px",
        maxWidth: "1300px",
        width: "80%",
        margin: "0 auto",
        marginBottom: "-60px",
        padding: "0px 20px 15px 20px",
        color: "black"
    },
})

class PagesV2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: '',
            slug: (this.props.location !== undefined && this.props.location.pathname !== undefined) ? this.props.location.pathname.split('/')[1].replace(/ /g, '-').toLowerCase() : null,
            navigation: this.props.match.url,
            posts: pagelist
        }
    }

    componentDidMount = () => {
        let pages = JSON.parse(JSON.stringify(pagelist));

        // fetch(`/.netlify/functions/animal/meowsalot`, {
        //     method: "GET",
        //     // body: JSON.stringify({
        //     //     slug: this.state.slug
        //     // })
        // })
        // .then(response => response.json())
        // .then(data => console.log(data));

        let prs = JSON.parse(JSON.stringify(stateData));
        for (const [key, value] of Object.entries(prs)) {
            if (key.replace(/_/g, '-') === this.state.slug) {
                this.setState({
                    statePage: key,
                    posts: stateposts,
                    title: value["state"]
                })
            }
        }

        pages.map((page) => (
            (this.state.title === undefined && page.slug === this.state.slug && page.status === "Published") ?
                this.setState({
                    isLoaded: true,
                    pageTitle: page.title.replace(/(\r\n|\n|\r)/gm, ""),
                    pageExcerpt: page.excerpt,
                    pageStatus: page.status,
                    pageId: page.id,
                    pageThumbnail: page.thumbnail,
                    pageContent: page.content,
                    pageKeywords: page.keywords,
                    listTitle: (page.listTitle !== undefined) ? page.listTitle : null,
                    listBody: (page.listBody !== undefined) ? page.listBody : null,
                    listType: (page.listType !== undefined) ? page.listType : null,
                    listNumbered: (page.numbered !== undefined) ? page.numbered : 'no',
                    listReversed: (page.reversed === true) ? page.reversed : false,
                    sections: (page.sections !== undefined) ? page.sections : undefined
                })
                :
                this.setState({
                    isLoaded: true,
                })
        ))
    }

    render() {
        const { error, isLoaded } = this.state;
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            const { classes } = this.props;

            // RENDER EACH STATE PAGE - EXAMPLE: /FLORIDA/
            if (this.state.title !== undefined) {
                return (
                    <div className={classes.root}>
                        <Meta
                            screen={"page"}
                            title={this.state.title}
                            description={`Articles About ${this.state.title}`}
                            keywords={this.state.title}
                            image={'https://images.unsplash.com/photo-1488998527040-85054a85150e?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'}
                            url={`https://bankzia.com/${this.state.slug}`}
                        />
                        <Blogroll width={1200} data={{ posts: this.state.posts, title: `${this.state.title}`, slug: this.state.slug, state: this.state.title }} />
                    </div>
                )
            }

            // ELSE IF IT IS A REGULAR PAGE FOUND IN THE CMS
            else if (this.state.pageTitle !== undefined) {
                let sections = [];
                if (this.state.sections !== undefined && this.state.sections !== null) {
                    this.state.sections.forEach(function (section, i) {
                        sections.push({
                            "@type": "ListItem",
                            "position": i + 1,
                            "item": {
                                // "url": "",
                                "name": section.title,
                                "headline": section.title,
                                "image": (section.image !== undefined && section.image !== null) ? `https://bankzia.com/${section.image}` : "",
                                "text": section.body
                            }
                        })
                    })
                }
                return (
                    <div className={classes.root}>
                        <Meta
                            screen={"page"}
                            title={this.state.pageTitle}
                            description={this.state.pageExcerpt}
                            keywords={this.state.pageKeywords}
                            image={'https://images.unsplash.com/photo-1488998527040-85054a85150e?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'}
                            url={`https://bankzia.com/${this.state.slug}`}
                        />
                        <Grid container className={classes.container}>
                            <Typography className={classes.title} variant="h1" component="h1">{this.state.pageTitle}</Typography>
                            <Typography className={classes.description} variant="p" component="p">{this.state.pageExcerpt}</Typography>
                            <Markdown source={this.state.pageContent} escapeHtml={false} className={classes.body} />
                        </Grid>
                        {(this.state.sections !== undefined) ?
                            <Grid item md={12} className={classes.container} style={{ textAlign: "left", margin: "0 auto", marginTop: "60px" }} >
                                <h3>{this.state.listTitle}</h3>
                                <p>{this.state.listBody}</p>
                                {(this.state.listReversed !== undefined && this.state.listReversed === true) ?
                                    this.state.sections.reverse().map((item, i) => (
                                        <div key={uuidv4()}>
                                            {(this.state.listNumbered === "yes") ? <h2>{this.state.sections.length - 1 - i + 1}. {item.title}</h2> : <h2>{item.title}</h2>}
                                            <p>{item.body}</p>
                                        </div>
                                    ))
                                    :
                                    (this.state.listReversed !== undefined && this.state.listReversed === false) ?
                                        this.state.sections.map((item, i) => (
                                            <div key={uuidv4()}>
                                                {(this.state.listNumbered === "yes") ? <h2>{i + 1}. {item.title}</h2> : <h2>{item.title}</h2>}
                                                <p>{item.body}</p>
                                            </div>
                                        ))
                                        :
                                        this.state.sections.map((item, i) => (
                                            <div key={uuidv4()}>
                                                <h2>{item.title}</h2>
                                                <p>{item.body}</p>
                                            </div>
                                        ))}
                            </Grid>
                            : <></>}
                        <div>
                            {(this.state.slug === 'newsletter') ? <Newsletter heading={false} /> :
                                (this.state.slug === 'contact') ? <Newsletter heading={false} /> :
                                    (this.state.slug === 'faqs') ? <Newsletter heading={false} /> :
                                        (this.state.slug === 'press') ? <Newsletter heading={false} /> :
                                            (this.state.slug === 'advertise-with-us') ? <Newsletter heading={false} /> :
                                                <></>
                            }
                        </div>
                    </div>
                )
            } else {
                return <Error />
            }
        }
    }
}
export default withStyles(styles, { withTheme: true })(PagesV2);