import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { CardMedia, Card, Typography, Link, CardContent } from '@material-ui/core';

// ANIMATED ARTICLE LIST => FEATURED IMAGE 

const styles = theme => ({
    item: {
        margin: "0 auto",
        border: "1px solid #eee",
        borderRadius: "0px",
        height: "500px",
        marginTop: "20px",
        '@media screen and (max-width: 700px)': {
            border: "1px solid #eee",
            margin: "0 auto",
        },
        '&:hover': {
            backgroundColor: 'white',
            boxShadow: "0 9px 9px 0 rgba(21,21,21,.14), 0 13px 15px -9px rgba(21,21,21,.18)",
            transition: "border-color .2s ease-in,box-shadow .2s ease-in,color .2s ease-in",
            borderBottomWidth: "8px",
            borderBottomColor: "#0157ff",
            cursor: "pointer",
            color: "#0157ff",
        },
    },
    articleFeaturedImage: {
        maxHeight: "300px"
    },
    title: {
        fontFamily: "Chronicle Display,serif",
        fontSize: "20px",
        fontWeight: "600",
        letterSpacing: "0px",
        textAlign: "left",
        paddingLeft: "10px",
        textTransform: "capitalized",
        whiteSpace: "wrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkitLineClamp": 2,
        "-webkitBoxOrient": "vertical"
    },
    description: {
        fontSize: "17px",
        maxWidth: "100%",
        textAlign: "left",
        fontWeight: "400",
        padding: "10px",
        textTransform: "capitalized",
        whiteSpace: "wrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkitLineClamp": 4,
        "-webkitBoxOrient": "vertical"
    },

})

class Blogroll extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
    }

    // componentDidMount = () => {
    //     console.log(this.state.data.posts)
    // }

    render() {
        const { classes } = this.props;
        const post = JSON.parse(JSON.stringify(this.state.data));
        return (
            // <Grid item md={3}>
                <Link href={window.location.origin + "/article/" + post.slug} style={{textDecoration: "none"}}>
                    <Card className={classes.item} variant="outlined" style={{ backgroundColor: post.cellColor, color: post.cellTextColor }}>
                        <CardMedia image={(post.thumbnail.includes('https://')) ? post.thumbnail : "https://Bankzia.com" + post.thumbnail} component="img" alt={"test"} className={classes.articleFeaturedImage} />
                        <CardContent className={classes.CardContent}>
                            <Typography className={classes.title} variant="h5" component="h5" style={{ color: this.state.data.cellTextColor }}>{post.title}</Typography>
                            {/* <Typography className={classes.description} variant="p" component="p" style={{ color: this.state.data.cellTextColor }}>By: {post.author}<br></br>On: {post.date}</Typography> */}
                            <Typography className={classes.description} variant="p" component="p" style={{ color: this.state.data.cellTextColor }}>{post.excerpt}</Typography>
                        </CardContent>
                    </Card>
                </Link>
            // </Grid>
        )
    }
}
export default withStyles(styles, { withTheme: true })(Blogroll);