import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from '@material-ui/core';
import ArticleDetail from "./article/ArticleDetail";
import postlist from "../../../posts.json";
import { v4 as uuidv4 } from 'uuid';

const styles = theme => ({
    root: {
        marginTop: "30px",
        marginBottom: "30px",
        maxWidth: "1200px",
        margin: "0 auto",
        flexGrow: 1,
        border: "1px solid #eee",
        borderRadius: "0px",
        padding: "15px 30px 25px 30px",
        '@media screen and (max-width: 900px)': {
            border: "1px solid #eee",
            marginTop: "20px",
            margin: "0 auto",
            width: "90%",
            padding: "15px 0 20px 0",
        },
    },
    title: {
        fontFamily: "Chronicle Display,serif",
        fontSize: "35px",
        maxWidth: "80%",
        textAlign: "left",
        padding: "10px",
        '@media screen and (max-width: 700px)': {
            fontSize: "30px",
            maxWidth: "90%",
            padding: "15px"
        },
    },
    description: {
        fontSize: "15px",
        maxWidth: "70%",
        textAlign: "left",
        fontWeight: "400",
        padding: "10px",
        marginTop: "-15px",
        textTransform: "uppercase",
        '@media screen and (max-width: 700px)': {
            padding: "15px",
            maxWidth: "90%",
        },
    },

})

class Blogroll extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
    }

    // componentDidMount = () => {
    //     console.log(this.state.data.posts)
    // }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root} style={{ backgroundColor: this.state.backgroundColor }}>
                <Grid container>
                    <Grid item md={12}>
                        <Typography className={classes.title} variant="h2" component="h2" style={{ color: this.state.cellTextColor }}>{this.state.title}</Typography>
                        <Typography className={classes.description} variant="p" component="p" style={{ color: this.state.cellTextColor }}>{this.state.byline}</Typography>
                    </Grid>
                </Grid>
                <Grid container className={classes.container} spacing={2}>
                    {postlist.map((article, i) => (
                        (article.status === "Published" && i <= 3) ?
                            <ArticleDetail key={uuidv4()} data={article} />
                            : <></>
                    ))}
                </Grid>
            </div>
        )
    }
}
export default withStyles(styles, { withTheme: true })(Blogroll);