import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Grid, CardContent, Card, Typography } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';

const styles = theme => ({
    container: {
        maxWidth: "1300px",
    },

    // GENERAL COPY
    widgetTitle: {
        // fontFamily: "'DM Serif Display',Garamond,serif",
        // fontFamily: 'Chronicle Display,serif',
        fontWeight: 400,
        lineHeight: 1,
        fontSize: "2.3rem",
        textAlign: "left",
        letterSpacing: "-1px",
        maxWidth: "1300px",
        width: "100%",
        margin: "0 auto",
        padding: "50px 20px 15px 20px",
        color: "black",
        '@media screen and (max-width: 900px)': {
            width: "80%",
            fontSize: "1.7rem",
        }
    },
    widgetDescription: {
        borderBottom: "1px solid black",
        textAlign: "left",
        letterSpacing: "-1px",
        maxWidth: "1300px",
        width: "100%",
        margin: "0 auto",
        padding: "0px 20px 15px 20px",
        color: "black",
        '@media screen and (max-width: 900px)': {
            width: "80%",
            fontSize: "14px",
        }
    },
    heading: {
        fontSize: "17px",
        lineHeight: "20px",
        marginBottom: "8px",
        fontFamily: "'CircularStd-Bold',Arial,sans-serif",
        textAlign: "left",
        fontWeight: "600",
        maxWidth: "250px",
        margin: "0 auto"
    },
    byline: {
        fontSize: "14px",
        lineHeight: "16px",
        textAlign: "left",
        margin: "0 auto",
        maxWidth: "250px"
    },
    calcMinimal: {
        boxShadow: "0px 0px 4px 0 rgba(21,21,21,.1), 0 2px 8px -1px rgba(21,21,21,.1)",
        minWidth: "220px",
        width: "90%",
        maxWidth: "350px",
        height: "auto",
        minHeight: "150px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        borderRadius: "0px",
        borderLeftWidth: "8px",
        borderColor: "transparent",
        // margin: "10px",
        '&:hover': {
            backgroundColor: 'white',
            boxShadow: "0 9px 9px 0 rgba(21,21,21,.14), 0 16px 20px -5px rgba(21,21,21,.18)",
            transition: "border-color .2s ease-in,box-shadow .2s ease-in,color .2s ease-in",
            borderLeftWidth: "8px",
            borderLeftColor: "#0157ff",
            cursor: "pointer",
            color: "#0157ff"
        },
        '@media screen and (max-width: 950px)': {
            width: "96%",
            // left: "0"
            minHeight: "100px",
            maxWidth: "100%"
        }
    },
    reverse: {
        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: "center",
    },
    cardtitle: {
        fontSize: "2.5rem",
        fontWeight: "600",
        textAlign: "left",
        maxWidth: "450px",
        padding: "0px 50px 0 50px",
        margin: "-50px 0px 0px 0px",
        lineHeight: "2.5rem",
        textShadow: "2px 2px #00143d",
        fontFamily: '"DM Serif Display",Garimond,serif',
        '@media screen and (max-width: 700px)': {
            fontSize: "1.6rem",
            maxWidth: "70%",
            padding: "0px 10px 0 10px",
        }
    },
    carddescription: {
        fontSize: "25px",
        fontWeight: "200",
        textAlign: "left",
        maxWidth: "450px",
        padding: "0 50px 0 50px",
        textShadow: "2px 2px #00143d",
        '@media screen and (max-width: 700px)': {
            fontSize: "1.1rem",
            maxWidth: "70%",
            padding: "0 10px 0 10px",
        }
    },
    cardOverlay: {
        height: '400px',
        width: "100%",
        maxWidth: '600px',
        float: "right",
        backgroundSize: "cover",
        backgroundPosition: "center",
        color: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: "25px"
    }
})


class Calculators extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            state: props.state,
            metrics: props.metrics,
            layout: props.layout,
            title: props.title,
            byline: props.byline,
            loanType: props.loanType,
            minimalTitle: props.minimalTitle,
            minimalByline: props.minimalByline,
            preview: props.image,
            data: props.data,
            direction: props.direction
        };
    }

    componentDidMount() {
        console.log(this.state.metrics)
    }

    render() {
        const { classes } = this.props;

        if (this.state.layout === "grid") {
            return (
                <div className={classes.root}>
                    <Grid container>
                        <Grid item md={12}>
                            <Typography className={classes.title} variant="h2" component="h2">{this.state.title}</Typography>
                            <Typography className={classes.description} variant="p" component="p">{this.state.byline}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container} spacing={2}>
                        {this.state.data.map((calc) => (
                            (calc.type === this.state.loanType) ?
                                <Grid item md={4} style={{ margin: "10px auto" }}>
                                    <Card className={classes.calc} variant="outlined">
                                        <CardContent>
                                            <Typography className={classes.heading} variant="h5" component="h5">{calc.name}</Typography>
                                            <Typography className={classes.byline} variant="p" component="p">{calc.byline}</Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                : (this.state.loanType === "all") ?
                                    <Grid item md={4} style={{ margin: "10px auto" }}>
                                        <Card className={classes.calc} variant="outlined">
                                            <CardContent>
                                                <Typography className={classes.heading} variant="h5" component="h5">{calc.name}</Typography>
                                                <Typography className={classes.byline} variant="p" component="p">{calc.byline}</Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                : <></>
                        ))}
                    </Grid>
                </div>
            )
        } else if (this.state.layout === "minimal") {
            return (
                <div>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item md={6} sm={12}>
                            <Typography className={classes.widgetTitle} variant="h2" component="h2">{this.state.title}</Typography>
                            <Typography className={classes.widgetDescription} variant="p" component="p">{this.state.byline}</Typography>
                        </Grid>
                        <Grid container className={[classes.minimalContainer, classes[this.state.direction]]} spacing={4} style={{ paddingTop: "30px" }}>
                            <Grid item md={6} sm={12}>
                                <div style={{ backgroundImage: `radial-gradient(circle, rgba(14, 173, 192, 0.7) 0%, rgba(19, 156, 225, 0.6) 70%, rgba(28, 173, 234, 0.8) 100%), url(${this.state.preview})` }} className={classes.cardOverlay}>
                                    <h1 className={classes.cardtitle}>{this.state.minimalTitle}</h1>
                                    <p className={classes.carddescription}>{this.state.minimalByline}</p>
                                </div>
                            </Grid>
                            <Grid item md={6} s={12} spacing={4}>
                                <Grid container style={{ paddingTop: "20px", maxWidth: "800px" }} spacing={4}>
                                    {this.state.data.map((calc) => (
                                        (calc.type === this.state.loanType) ?
                                            <Grid key={uuidv4()} item md={6} s={12} style={{ margin: "0 auto" }}>
                                                <Card className={classes.calcMinimal} variant="outlined">
                                                    <CardContent>
                                                        <Typography className={classes.heading} variant="h5" component="h5">{calc.name}</Typography>
                                                        <Typography className={classes.byline} variant="p" component="p">{calc.byline}</Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            : (this.state.loanType === "all") ?
                                                <Grid key={uuidv4()} item md={6} s={12} style={{ margin: "0 auto" }}>
                                                    <Card className={classes.calcMinimal} variant="outlined">
                                                        <CardContent>
                                                            <Typography className={classes.heading} variant="h5" component="h5">{calc.name}</Typography>
                                                            <Typography className={classes.byline} variant="p" component="p">{calc.byline}</Typography>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            : <></>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            )
        }
    }
}
export default withStyles(styles, { withTheme: true })(Calculators);