import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import stateData from "../../../data.json";
import { v4 as uuidv4 } from 'uuid';
import { Link, Grid, Paper, Typography } from '@material-ui/core';

const styles = theme => ({
    container: {
        maxWidth: "900px",
        margin: "0 auto",
        padding: "35px"
    },
    control: {
        padding: theme.spacing(2),
    },
    title: {
        fontFamily: 'Chronicle Display,serif',
        fontWeight: 400,
        lineHeight: 1,
        fontSize: "1.7rem",
        textAlign: "left",
        letterSpacing: "-1px",
        maxWidth: "800px",
        width: "80%",
        margin: "0 auto",
        padding: "50px 20px 15px 20px",
        color: "black",
    },
    description: {
        borderBottom: "1px solid black",
        textAlign: "left",
        letterSpacing: "-1px",
        maxWidth: "800px",
        width: "80%",
        margin: "0 auto",
        padding: "0px 20px 15px 20px",
        color: "black"
    },

    paper: {
        color: "blue",
        fontSize: "13px",
        fontWight: "200",
        textAlign: "left",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflowWrap: "break-word",
        overflow: "hidden",
        padding: "0 5px"
    },
    link: {
        color: 'black',
    }

})

class StateList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            states: [],
            ...props
        };
    }

    componentDidMount() {
        let prs = JSON.parse(JSON.stringify(stateData));
        let all = [];
        let keys = [];
        for (const [key, value] of Object.entries(prs)) {
            all.push(`${value[this.state.target]}`);
            keys.push(key)
            // let test = key;
            // return test
        }
        this.setState({
            states: all
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Grid container>
                    <Grid item md={12}>
                        <Typography className={classes.title} variant="h2" component="h2">{this.state.title}</Typography>
                        <Typography className={classes.description} variant="p" component="p">{this.state.byline}</Typography>
                    </Grid>
                </Grid>
                <Grid container className={classes.container} spacing={0}>
                    {this.state.states.map((value) => (
                        <Grid key={uuidv4()} item md={4} sm={6} xs={6}>
                            <Paper className={classes.paper}>
                                <Link className={classes.link} href={this.state.slug + value.replace(/ /g, '-').toLowerCase().replace(" ", "")+"/"}>{value} mortgage rates</Link>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </div>
        )
    }
}
export default withStyles(styles, { withTheme: true })(StateList);