import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import Meta from "../components/Meta";
import Blogroll from "../components/ui_elements/blogroll/Blogroll";
import topiclist from "../topics.json";
import postlist from "../posts.json";
// import statepostlist from "../state_posts.json";
import { Redirect } from "react-router-dom";

const styles = theme => ({
    root: {
        marginBottom: "30px"
    }
})

class Topics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: '',
            slug1: (this.props.location !== undefined && this.props.location.pathname !== undefined) ? this.props.location.pathname.split('/')[1].split('/')[0] : null,
            slug2: (this.props.location !== undefined && this.props.location.pathname !== undefined) ? this.props.location.pathname.split('/')[2].split('/')[0].replace(/-/g, ' ') : null,
            navigation: this.props.location,
            topicPosts: [],
            posts: postlist,
            validTopic: false
        };
    }

    componentDidMount = () => {
        const getData = async () => {

            // IF ITS A VALID TOPIC ON THE CMS
            if (this.state.slug1 === 'topic') {
                topiclist.map((topic) => {
                    if (this.state.slug2.replace(/ /g, '-') === topic.slug) {
                        this.setState({
                            validTopic: true,
                            topic: topic
                        })
                    }
                    return topic
                })
            }

            // CHECK IF ITS A STATE TOPIC PAGE OR A REGULAR TOPIC PAGE
            // REGULAR TOPIC PAGE
            if (this.state.slug1.includes('topic')) {
                postlist.map((post) => (
                    (post.topics !== undefined && post.topics !== null) ?
                        post.topics.map((topic) => {
                            if (String(topic.toLocaleLowerCase()) === String(this.state.slug2)) {
                                this.state.topicPosts.push(post)
                                this.setState({
                                    isLoaded: true,
                                    stateTopicPage: ""
                                })
                                return true
                            }
                            return true
                        })
                        : null
                ))
            }
        }
        getData().then(() => {
            this.setState({
                isLoaded: true,
            })
        })
    }

    render() {
        const { error, isLoaded } = this.state;
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            // const { classes } = this.props;
            if (this.state.validTopic === true) {
                return (
                    <div>
                        <Meta
                            screen={"topic"}
                            title={`Bankzia | Articles - ${this.state.topic.seo_title}`}
                            description={`Bankzia | Articles - ${this.state.topic.seo_description}`}
                            keywords={`Articles - ${this.state.topic.keywords}`}
                            image={this.state.topic.thumbnail}
                            url={`https://bankzia.com/topic/${this.state.slug2}`}
                        />
                        {(this.state.topicPosts.length >= 1) ?
                            <Blogroll width={1200} data={{ posts: this.state.topicPosts, slug: this.state.slug2, title: this.state.slug2, topic: this.state.topic }} />
                            :
                            <div>
                                <Blogroll width={1200} data={{ posts: this.state.topicPosts, slug: this.state.slug2, title: this.state.slug2, topic: this.state.topic }} />
                                <h3>No Posts Available.</h3>
                            </div>
                        }
                    </div>
                )
            } else {
                return <Redirect to="/404" />
            }
        }
    }
}
export default withStyles(styles, { withTheme: true })(Topics);