import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Link, Grid, Paper } from '@material-ui/core';
// import StatesList from "./StatesList";
import StatesList from "./ui_elements/statelinks/StateList";
import Newsletter from "./ui_elements/Newsletter";
// import TopicsAndArticles from "./TopicsAndArticles";

// import ArticleGrid from "./ui_elements/articles/ArticleGrid";
import ArticleList from "./ui_elements/articles/ArticleList";
import Topics from './ui_elements/topics/TopicsCollection';
import ColorBlocks from './ui_elements/colorblocks/Colorblocks';

import HouseSharpIcon from '@material-ui/icons/HouseSharp';
import EmojiTransportationSharpIcon from '@material-ui/icons/EmojiTransportationSharp';
import MonetizationOnSharpIcon from '@material-ui/icons/MonetizationOnSharp';
import AccountBalanceSharpIcon from '@material-ui/icons/AccountBalanceSharp';

// import { House, AttachMoney, LocalAtm, CreditCard, DriveEta, HomeWork } from '@material-ui/icons';
// import postlist from "../posts.json";
import menus from "../menus.json";
import { v4 as uuidv4 } from 'uuid';

const styles = theme => ({
    root: {
        // flexGrow: 1,
        // maxWidth: "1000px",
        // boxShadow: "0 0 1px 0 rgba(21,21,21,.1), 0 2px 8px -1px rgba(21,21,21,.2)",
        backgroundColor: "#fff",
        // margin: "0 auto",
        // maxWidth: "95%",
        // overflowX: "hidden"
    },
    paper: {
        color: "black",
        paddingTop: "5px"
    },
    heading: {
        color: "black",
        borderBottom: "1px solid #cdcfd8",
        fontFamily: "CircularStd-Bold,Arial,sans-serif",
        fontStyle: "normal",
        fontWeight: "600",
        paddingBottom: "8px",
        fontSize: "12px",
        textAlign: "left",
        minWidth: "175px",
        lineHeight: "2px"
    },
    link: {
        fontSize: "12px",
        lineHeight: "1.55",
        fontFamily: "CircularStd-Book,Calibre-Regular,Arial,sans-serif",
        fontStyle: "normal",
        fontWeight: "400",
        color: "black",
        float: "left",
    },
    // control: {
    //     // padding: theme.spacing(2),
    // },
    brand: {
        textAlign: "left",
        fontFamily: "'DM Serif Display',Garamond,serif!important",
        fontWeight: 600,
        paddingLeft: "70px",
        textDecoration: "none",
        color: "black"
    },
    footer: {
        maxWidth: "1000px",
        margin: "0 auto",
        padding: "0px 70px"
    },
    copyright: {
        fontWeight: "100",
        marginTop: "15px",
        fontSize: "11px",
        textAlign: "center",
        // position: "absolute",
        bottom: "0",
        padding: "20px"
    },
    test: {
        margin: "0 auto",
        width: "auto"
    },
    howWeMakeMoney: {
        marginTop: "0px",
        width: "auto",
        margin: "0 auto",
        textAlign: "left"
    }
})


class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            state: props.state,
            location: window.location.pathname
        };
    }

    componentDidMount() {
        // alert(JSON.stringify(this.state.location))
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>

                {/* DONT SHOW CERTAIN WIDGETS ON THE HOMEPAGE AS THEY HAVE ALREADY BEEN DISPLAYED */}
                {(this.state.location !== "/") ?
                    <div>
                        <ColorBlocks top={0} bottom={10} width={1100} data={[
                            {
                                byline: "Home Buying",
                                title: 'Mortgage',
                                background: "#E3FAF2",
                                text: "black",
                                button: "Calculate Now",
                                buttonColor: "green",
                                width: 3,
                                href: "/mortgage-calculator/",
                                icon: HouseSharpIcon,
                                buttonWidth: "full"
                            }, {
                                byline: "Vehicle Purchase",
                                title: "Auto",
                                background: "#005FB9",
                                text: "white",
                                button: "Calculate Now",
                                buttonColor: "white",
                                width: 3,
                                href: "",
                                icon: EmojiTransportationSharpIcon,
                                buttonWidth: "full"
                            }, {
                                byline: "Start Saving",
                                title: "Investing",
                                background: "#EEE",
                                text: "black",
                                button: "SHOW ME NOW",
                                buttonColor: "navy",
                                width: 3,
                                href: "",
                                icon: MonetizationOnSharpIcon,
                                buttonWidth: "full"
                            }, {
                                byline: "HOME SWEET HOME",
                                title: "Banking",
                                background: "#EEF7FF",
                                text: "black",
                                button: "Click now",
                                buttonColor: "blue",
                                width: 3,
                                href: "",
                                icon: AccountBalanceSharpIcon,
                                buttonWidth: "full"
                            }]} />

                        <ArticleList
                            title={"New Articles"}
                            byline={"Check out some of our newest articles."}
                            contentType={"articles"}
                            layout={"grid"}
                            titleTextColor={"black"}
                            cellTextColor={"black"}
                            backgroundColor={"white"}
                            cellColor={"white"}
                        />
                    </div>
                    : <></>}
                <Newsletter
                    margin={"30px 0px"}
                    background={"#EEF7FF"}
                    text={"black"}
                />
                {(this.state.location !== "/") ?
                    <Topics
                        title={"Popular Topics"}
                        byline={"Check out some of our most popular topics."}
                    />
                    : <></>}

                <StatesList 
                    title={"Mortgage Rates By State"}
                    byline={"Calculate your next mortgage using the latest rates in your state."}
                    target={"state"}
                    slug={"/mortgage-calculator/"}
                />
                <Grid container className={classes.root} spacing={0} justify="center" style={{ boxShadow: "0 0 1px 0 rgba(21,21,21,.1), 0 2px 8px -1px rgba(21,21,21,.2)" }}>
                    <div className={classes.footer}>
                        <a className={classes.brand} href={window.location.origin}><h2 className={classes.brand}>Bankzia</h2></a>

                        <Grid container justify="left" spacing={0}>
                            {menus.map((menu, index) => {
                                return (
                                    (menu.position === "Footer") ?
                                        menu["Menu Item"].map((subitem, i) => {
                                            return (
                                                (subitem["List Item"] !== undefined) ?
                                                    <Grid item md={2} sm={12}>
                                                        <h3 className={classes.heading}>{subitem.title}</h3>
                                                        {subitem["List Item"].map((subsubitem, i) => {
                                                            return (
                                                                <Paper key={uuidv4()} className={classes.paper}>
                                                                    <Link className={classes.link} href={(subsubitem.slug.includes('sitemap')) ? `/${subsubitem.slug}` : `/${subsubitem.slug}/`}>{subsubitem.title.toLowerCase()}</Link><br></br>
                                                                </Paper>
                                                            )
                                                        })}
                                                    </Grid>
                                                    :
                                                    <></>
                                            )
                                        })
                                        : null
                                )
                            })}
                            <Grid item md={6} sm={12}>
                                <div className={classes.howWeMakeMoney}>
                                    <h3 className={classes.heading}>How we make money</h3>
                                    <p className={classes.link}>Bankzia.com is an independent financial advising service. Bankzia receives compensation in exchange for our featured content, placement, products, services, or your clicking on links posted on this site. Bankzia.com does not include all available companies or products within a specified market.</p>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <Grid item xs={12} className={classes.copyGrid}>
                        <p className={classes.copyright}>© {new Date().getFullYear()} Bankzia.com. All rights reserved.</p>
                    </Grid>
                </Grid>
            </div>
        )
    }
}
export default withStyles(styles, { withTheme: true })(Footer);