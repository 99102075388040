import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid, TextField, FormControlLabel, Switch } from '@material-ui/core';
import FavoritesButton from './FavoritesButton';
// import { StickyContainer, Sticky } from 'react-sticky';
import { Share } from '@material-ui/icons';
import { v4 as uuidv4 } from 'uuid';

const styles = theme => ({
    root: {
        // flexGrow: 1,
        // maxWidth: "100%",
        // overflowX: "hidden"
    },
    row: {
        marginTop: "15px",
        marginBottom: "15px"
    },
    paper: {
        color: "blue",
        fontSize: "11px",
        fontWight: "200"
    },
    control: {
        padding: theme.spacing(2),
    },
    heading: {
        fontFamily: "'DM Serif Display',Garamond,serif!important",
        fontWeight: 600,
        lineHeight: 1,
        fontSize: "3.6rem",
        textAlign: "left",
        letterSpacing: "-1px",
        maxWidth: "1000px",
        margin: "0 auto",
        padding: "40px",
        '@media screen and (max-width: 900px)': {
            fontSize: "2.0rem",
            padding: "25px 20px 5px 20px",
        }
    },
    fields: {
        maxWidth: "1000px",
        margin: "0 auto",
        marginTop: "15px",
        marginBottom: "15px",
    },
    field: {
        '@media screen and (max-width: 900px)': {
            padding: "0 20px",
            // margin: "0 auto"
        }
    },
    monthlyPayment: {
        backgroundColor: "#00143d",
        color: "white",
        width: "100vw !important",
        marginTop: "50px",
        marginBottom: "0px",
        // margin: "0 auto",
        // fontFamily: "'CircularStd-Book',Arial,sans-serif!important",
        fontWeight: 400,
        // lineHeight: 1,
        // fontSize: "20px",
    },
    byline: {
        textAlign: "left",
        marginTop: "-20px",
        maxWidth: "1000px",
        margin: "0 auto",
        '@media screen and (max-width: 900px)': {
            fontSize: "14px",
            padding: "0 20px"
        }
    },
    share: {
        color: "white",
        borderColor: "white",
        borderRadius: "0px",
        width: "200px",
        float: "left",
        '&:hover': {
            backgroundColor: 'white',
            color: "#00143d"
        },
        '@media screen and (max-width: 900px)': {
            width: "150px",
            fontSize: "12px",
        },
        '@media screen and (max-width: 600px)': {
            float: "none",
        }
    },
    shareIcon: {
        '@media screen and (max-width: 900px)': {
            fontSize: "12px"
        }
    }
})

class MortgageCalculator extends React.Component {
    constructor(props) {
        super(props);
        let old = props.data;
        let navigation = props.navigation;
        let share = (props.navigation !== undefined) ? props.navigation.search : props.navigation;
        // console.log(share)
        this.state = {
            error: null,
            isLoaded: false,
            state: props.state,
            title: props.title,
            home_price: (old !== undefined && old.before_home_price !== undefined) ? old.before_home_price : (navigation !== undefined && share !== "" && share.includes('home_price')) ? share.split('home_price=')[1].split('&')[0] : 300000,
            down_payment: (old !== undefined && old.down_payment !== undefined) ? Number(old.down_payment) : (navigation !== undefined && share !== "" && share.includes('down_payment')) ? share.split('down_payment=')[1].split('&')[0] : Number(18000),
            interest_rate: (old !== undefined && old.interest_rate !== undefined) ? old.interest_rate : (navigation !== undefined && share !== "" && share.includes('interest_rate')) ? share.split('interest_rate=')[1].split('&')[0] : 3,
            loan_term: (old !== undefined && old.loan_term !== undefined) ? old.loan_term : (navigation !== undefined && share !== "" && share.includes('loan_term')) ? share.split('loan_term=')[1].split('&')[0] : 30,
            additional_principal_payment: (old !== undefined && old.additional_principal_payment !== undefined) ? old.additional_principal_payment : (navigation !== undefined && share !== "" && share.includes('additional_principal_payment')) ? share.split('additional_principal_payment=')[1].split('&')[0] : 0,
            annual_tax: (old !== undefined && old.annual_tax !== undefined) ? old.annual_tax : (navigation !== undefined && share !== "" && share.includes('annual_tax')) ? share.split('annual_tax=')[1].split('&')[0] : 2400,
            annual_insurance: (old !== undefined && old.annual_insurance !== undefined) ? old.annual_insurance : (navigation !== undefined && share !== "" && share.includes('annual_insurance')) ? share.split('annual_insurance=')[1].split('&')[0] : 1000,
            mortgage_insurance_rate: (old !== undefined && old.mortgage_insurance_rate !== undefined) ? old.mortgage_insurance_rate : (navigation !== undefined && share !== "" && share.includes('mortgage_insurance_rate')) ? share.split('mortgage_insurance_rate=')[1].split('&')[0] : 0.5,
            include_mortgage_instuance: true,
            monthly_hoa: (old !== undefined && old.monthly_hoa !== undefined) ? old.monthly_hoa : (navigation !== undefined && share !== "" && share.includes('monthly_hoa')) ? share.split('monthly_hoa=')[1].split('&')[0] : 0,
            includePMI: true,
            advanced: false,
            editing: (old !== undefined) ? "Editing" : null,
            byline: (props.byline !== undefined) ? props.byline : null,
        };
        // console.log(props.navigation)
    }

    componentDidMount = () => {
        this.setState({
            down_payment_percent: Number((this.state.down_payment / this.state.home_price) * 100),
            monthly_interest_rate: (this.state.interest_rate / 12),
        });
        // this.history.push(`${window.location.pathname}&page=cat`)
        // console.log((this.state.down_payment / this.state.home_price) * 1)
    }


    render() {
        const { classes } = this.props;
        var homeprice = this.state.home_price - this.state.down_payment - this.state.additional_principal_payment;
        var beforeHomePrice = this.state.home_price;
        var monthly_home_price = homeprice / (this.state.loan_term * 12);
        var monthly_interest_rate = (this.state.interest_rate / 12) * monthly_home_price;
        var monthly_poperty_tax = (this.state.annual_tax / 12);
        var monthly_pmi = monthly_home_price * (this.state.mortgage_insurance_rate / 12);
        var monthly_insurance = (this.state.annual_insurance / 12);
        // var hasPMI = (this.state.down_payment_percent < 20) ? 0.5 : 0;
        // var totalHomePrice = homeprice + (this.state.annual_insurance * 360) + (this.state.mont);
        // var totalOver30 = ;
        const handleChange = (event) => {
            // console.log(event.target.name)
            this.setState({ [event.target.name]: !this.state[event.target.name] });
        };

        let final = {
            date: new Date(),
            homeprice: homeprice,
            before_home_price: beforeHomePrice,
            monthly_home_price: monthly_home_price,
            monthly_interest_rate: monthly_interest_rate,
            monthly_poperty_tax: monthly_poperty_tax,
            monthly_pmi: monthly_pmi,
            monthly_insurance: monthly_insurance,
            down_payment: this.state.down_payment,
            interest_rate: this.state.interest_rate,
            loan_term: this.state.loan_term,
            additional_principal_payment: this.state.additional_principal_payment,
            annual_tax: this.state.annual_tax,
            annual_insurance: this.state.annual_insurance,
            include_mortgage_instuance: this.state.include_mortgage_instuance,
            mortgage_insurance_rate: this.state.mortgage_insurance_rate,
            monthly_hoa: this.state.monthly_hoa,
            monthly_estimated_price: (monthly_home_price + monthly_interest_rate + monthly_poperty_tax + monthly_pmi + monthly_insurance + Number(this.state.monthly_hoa)).toFixed(3).slice(0, -1)
        };
        return (
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                    <h1 className={classes.heading}>{this.state.title} {this.state.slug}</h1>
                    <p className={classes.byline}>{this.state.byline}</p>
                </Grid>
                {/* <Button variant="outlined" color="primary" onClick={() => updateLocalStorage()}>Primary</Button> */}
                <Grid item xs={12} className={classes.fields}>
                    <Grid container justify="center" spacing={2}>
                        <Grid item md={3}>
                            <TextField
                                label="Home Price"
                                variant="outlined"
                                defaultValue={this.state.home_price}
                                onChange={event => {
                                    const { value } = event.target;
                                    this.setState({
                                        home_price: value,
                                        down_payment_percent: Number((this.state.down_payment / value) * 100)
                                    });
                                }}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                label="Down Payment"
                                variant="outlined"
                                value={this.state.down_payment}
                                defaultValue={this.state.down_payment}
                                onChange={event => {
                                    const { value } = event.target;
                                    this.setState({
                                        down_payment: value,
                                        down_payment_percent: Number((value / this.state.home_price) * 100)
                                    });
                                }}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                label="Down Payment Percent"
                                variant="outlined"
                                value={this.state.down_payment_percent}
                                defaultValue={this.state.down_payment_percent}
                                onChange={event => {
                                    const { value } = event.target;
                                    // console.log(value)
                                    this.setState({
                                        down_payment_percent: value,
                                        down_payment: Number(this.state.home_price * Number(value) * 0.01)
                                    });
                                }}
                            />
                        </Grid>
                        <Grid item md={3} sm={12}>
                            <TextField
                                label="Interest Rate"
                                variant="outlined"
                                className={classes.field}
                                defaultValue={this.state.interest_rate}
                                onChange={event => {
                                    const { value } = event.target;
                                    this.setState({ interest_rate: value });
                                }}
                            />
                        </Grid>
                        <Grid item md={3} sm={12}>
                            <FormControlLabel
                                className={classes.field}
                                control={<Switch checked={this.state.advanced} onChange={handleChange} name="advanced" color="primary" />}
                                label="Advanced"
                            />
                        </Grid>
                    </Grid>
                    {(this.state.advanced === true) ?
                        <div>
                            <Grid container justify="center" spacing={2} className={classes.row}
                            >
                                <Grid item md={3}>
                                    <TextField
                                        label="Loan Term"
                                        variant="outlined"
                                        defaultValue={this.state.loan_term}
                                        onChange={event => {
                                            const { value } = event.target;
                                            this.setState({ loan_term: value });
                                        }}
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <TextField
                                        label="Additional Payment"
                                        variant="outlined"
                                        defaultValue={this.state.additional_principal_payment}
                                        onChange={event => {
                                            const { value } = event.target;
                                            this.setState({ additional_principal_payment: value });
                                        }}
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <TextField
                                        label="Property Tax $/yr"
                                        variant="outlined"
                                        defaultValue={this.state.annual_tax}
                                        onChange={event => {
                                            const { value } = event.target;
                                            this.setState({ annual_tax: value });
                                        }}
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <TextField
                                        label="Insurance $/yr"
                                        variant="outlined"
                                        defaultValue={this.state.annual_insurance}
                                        onChange={event => {
                                            const { value } = event.target;
                                            this.setState({ annual_insurance: value });
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container justify="center" spacing={2} className={classes.row}>
                                <Grid item md={3}>
                                    <TextField
                                        label="Monthly HOA"
                                        variant="outlined"
                                        defaultValue={this.state.monthly_hoa}
                                        onChange={event => {
                                            const { value } = event.target;
                                            this.setState({ monthly_hoa: value });
                                        }}
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <FormControlLabel
                                        control={<Switch checked={this.state.includePMI} onChange={handleChange} name="includePMI" color="primary" />}
                                        label="Include PMI?"
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    {(this.state.down_payment_percent < 20 && this.state.checkedA === true) ?
                                        <TextField
                                            className
                                            label="(PMI) Mortgage Insurance Rate"
                                            variant="outlined"
                                            // value={hasPMI}
                                            name="pmi"
                                            defaultValue={this.state.mortgage_insurance_rate}
                                            onChange={event => {
                                                const { value } = event.target;
                                                this.setState({ mortgage_insurance_rate: value });
                                            }}
                                        />
                                        :
                                        <TextField
                                            className
                                            label="(PMI) Mortgage Insurance Rate"
                                            variant="outlined"
                                            disabled
                                            defaultValue={this.state.mortgage_insurance_rate}
                                            name="no pmi"
                                        />
                                    }
                                </Grid>
                            </Grid>
                        </div>
                        : <></>
                    }
                </Grid>
                <Grid container xs={12}  spacing={3} className={classes.monthlyPayment} style={{ padding: "50px" }}>
                    <Grid item xs={12}>
                        <span>{this.state.editing} Estimated Monthly Payment: <strong>${(monthly_home_price + monthly_interest_rate + monthly_poperty_tax + monthly_pmi + monthly_insurance + Number(this.state.monthly_hoa)).toFixed(3).slice(0, -1)}</strong></span>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <FavoritesButton key={uuidv4()} metrics={final} />
                    </Grid>
                    <Grid item sm={6} xs={12} >
                        <Button variant="outlined" className={classes.share} onClick={() => {
                        navigator.clipboard.writeText(
                            `${window.location.origin}/mortgage-calculator/${this.state.state}?home_price=${homeprice}&down_payment=${this.state.down_payment}&interest_rate=${this.state.interest_rate}&loan_term=${this.state.loan_term}&monthly_hoa=${this.state.monthly_hoa}&mortgage_insurance_rate=${this.state.mortgage_insurance_rate}&annual_insurance=${this.state.annual_insurance}&annual_tax=${this.state.annual_tax}&additional_principal_payment=${this.state.additional_principal_payment}`
                        )
                    }}>Share  <Share className={classes.shareIcon} /></Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}
export default withStyles(styles, { withTheme: true })(MortgageCalculator);