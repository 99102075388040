import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Grid } from '@material-ui/core';
import Block from "./Block";
import { v4 as uuidv4 } from 'uuid';

const styles = theme => ({
    root: {
        marginBottom: "30px",
        maxWidth: "1300px",
        margin: "0 auto",
        flexGrow: 1,
    },
    container: {
        maxWidth: "1300px",
        margin: "0 auto",
    },
    item: {
        margin: "0 auto",
        padding: "20px",
        border: "10px solid white",
        '@media screen and (max-width: 900px)': {
            border: "10px solid white",
            margin: "0 auto",
            width: "80vw"
        }

    },
    title: {
        fontFamily: "'DM Serif Display',Garamond,serif",
        fontWeight: 600,
        lineHeight: 1,
        fontSize: "2.3rem",
        textAlign: "left",
        letterSpacing: "-1px",
        maxWidth: "1300px",
        width: "80%",
        padding: "50px 20px 15px 20px",
        color: "black",
        textTransform: "capitalize",
        '@media screen and (max-width: 700px)': {
            fontSize: "2rem"
        }
    },
    description: {
        borderBottom: "1px solid black",
        textAlign: "left",
        letterSpacing: "-1px",
        maxWidth: "1300px",
        width: "80%",
        marginBottom: "-40px",
        padding: "0px 20px 15px 20px",
        color: "black",
        '@media screen and (max-width: 700px)': {
            fontSize: "15px"
        }
    },

})

class Blogroll extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
    }

    // componentDidMount = () => {
    //     console.log(this.state.data.posts)
    // }

    render() {
        const { classes } = this.props;
        return (
                <Grid className={classes.container} style={{maxWidth: this.state.width, marginTop: this.state.top, marginBottom: this.state.bottom, zIndex: 100, position: "relative"}} container spacing={0}>
                    {this.state.data.map((post) => (
                        <Grid item className={classes.item} key={uuidv4()} md={Number(post.width)} sm={6} xs={12} style={{backgroundColor: post.background}}>
                            <Block data={post} />
                        </Grid>
                    ))}
                </Grid>
        )
    }
}
export default withStyles(styles, { withTheme: true })(Blogroll);