import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Grid, CardMedia, Card, Typography, Link, CardContent } from '@material-ui/core';

// GRID LAYOUT ARTICLE CELL SHOWING ALL OF THE POST DETAILS

const styles = theme => ({
    item: {
        margin: "0 auto",
        border: "1px solid #eee",
        borderRadius: "0px",
        '@media screen and (max-width: 700px)': {
            border: "1px solid #eee",
            margin: "0 auto",
            width: "90%"
        },
        '&:hover': {
            backgroundColor: 'white',
            boxShadow: "0 9px 9px 0 rgba(21,21,21,.14), 0 13px 15px -9px rgba(21,21,21,.18)",
            transition: "border-color .2s ease-in,box-shadow .2s ease-in,color .2s ease-in",
            borderBottomWidth: "8px",
            borderBottomColor: "#0157ff",
            cursor: "pointer",
            color: "#0157ff",
            // box-shadow: 0 20px 0 #ddd;

        },
    },
    articleFeaturedImage: {
        maxHeight: "150px"
    },
    title: {
        fontFamily: "Chronicle Display,serif",
        fontSize: "20px",
        fontWeight: "400",
        letterSpacing: "0px",
        // maxWidth: "150px",
        textAlign: "left",
        paddingLeft: "10px",
        textTransform: "capitalized",
        whiteSpace: "wrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkitLineClamp": 2,
        "-webkitBoxOrient": "vertical"
// overflow: hidden
// text-overflow: ellipsis;
    },
    description: {
        // fontFamily: "Chronicle Display,serif",
        fontSize: "13px",
        maxWidth: "70%",
        textAlign: "left",
        fontWeight: "700",
        padding: "10px",
        marginBottom: "-5px",
        textTransform: "capitalized",
    },

})

class Blogroll extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
    }

    // componentDidMount = () => {
    //     console.log(this.state.data.posts)
    // }

    render() {
        const { classes } = this.props;
        return (
            <Grid item md={3}>
                <Link href={window.location.origin + "/article/" + this.state.data.slug} style={{textDecoration: "none"}}>
                    <Card className={classes.item} variant="outlined" style={{ backgroundColor: this.state.data.cellColor, color: this.state.data.cellTextColor }}>
                        <CardMedia image={(this.state.data.thumbnail.includes('https://')) ? this.state.data.thumbnail : "https://Bankzia.com" + this.state.data.thumbnail} component="img" alt={"test"} className={classes.articleFeaturedImage} />
                        <CardContent className={classes.CardContent}>
                            <Typography className={classes.title} variant="h5" component="h5" style={{ color: this.state.data.cellTextColor }}>{this.state.data.title}</Typography>
                            <Typography className={classes.description} variant="p" component="p" style={{ color: this.state.data.cellTextColor }}>By: {this.state.data.author}<br></br>On: {this.state.data.date}</Typography>
                        </CardContent>
                    </Card>
                </Link>
            </Grid>
        )
    }
}
export default withStyles(styles, { withTheme: true })(Blogroll);