import myLogo from "../../src/assets/logo.png";
import React from "react";
import { useReactPWAInstall } from "react-pwa-install";

import {Snackbar, Link} from '@material-ui/core/';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={10} variant="filled" {...props} style={{borderRadius: "0px", zIndex: "10000", backgroundColor: "rgb(238, 238, 238)", color: "black"}} />;
}

// import myLogo from "./logo70.png";

function PWA() {
    const { pwaInstall, supported, isInstalled } = useReactPWAInstall();

    const handleClick = () => {
        pwaInstall({
            title: "Install Web App",
            logo: myLogo,
            features: (
                <ul>
                    <li>Cool feature 1</li>
                    <li>Cool feature 2</li>
                    <li>Even cooler feature</li>
                    <li>Works offline</li>
                </ul>
            ),
            description: "This is how the install dialog looks like. Here you can describe your app briefly.",
        })
            .then(() => alert("App installed successfully or instructions for install shown"))
            .catch(() => alert("User opted out from installing"));
    };

    const [open, setOpen] = React.useState(true);

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };

    return (
        // <div>
            //{/* <p>
                //If your browser is supported and you haven't already installed the app, you should see the install
                //button below:
            //</p> */}
            <div>
                {supported() && !isInstalled() && (
                    <Snackbar open={open} autoHideDuration={66000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="info">
                            {/* <button type="button" onClick={handleClick} style={{ marginBottom: "50px", display: "block" }}>
                                Add our app now!
                            </button> */}
                            <Link onClick={handleClick} style={{color: "white", fontSize: "14px", fontWeight: "400"}}>Add our app now!</Link>
                            {/* <Button variant="outlined" type="button" onClick={handleClick}>
                                Add our app now!
                            </Button> */}
                        </Alert>
                    </Snackbar>
                )}

                {!supported() && (
                        <Snackbar open={open} autoHideDuration={66000} onClose={handleClose} style={{marginTop: "55px"}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                          <Alert onClose={handleClose} severity="info">
                            This site is available as a mobile app! Open it again on your mobile device to install.
                          </Alert>
                        </Snackbar>
                    // <button type="button" onClick={handleClick} style={{ marginBottom: "50px", display: "block" }}>
                    //     Open our site on your mobile device to add our app
                    // </button>
                )}
            </div>
            //{/* <p>supported: {supported() + ""}</p> */}
            //{/* <p>isInstalled: {isInstalled() + ""}</p> */}
        // </div>
    );
}

export default PWA;