import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { TableContainer, TableBody, Table, TableHead, TableRow, TableCell } from '@material-ui/core';
// import MuiAlert from '@material-ui/lab/Alert';
import MortgageCalculator from './MortgageCalculator';
import { Delete, Edit, Share } from '@material-ui/icons';
// import Calculators from "./Calculators";
import { v4 as uuidv4 } from 'uuid';

const styles = theme => ({
    root: {
        // flexGrow: 1,
        width: "100vw"
    },
    table: {
        maxWidth: "90%",
        margin: "0 auto",
        padding: "25px"
    },
    heading: {
        fontFamily: "'DM Serif Display',Garamond,serif!important",
        fontWeight: 600,
        lineHeight: 1,
        fontSize: "3.6rem",
        textAlign: "left",
        letterSpacing: "-1px",
        maxWidth: "1000px",
        margin: "0 auto",
        padding: "40px",
        '@media screen and (max-width: 900px)': {
            fontSize: "2.0rem",
            padding: "25px 20px 5px 20px",
        }
    },
    disclamer: {
        maxWidth: "500px",
        margin: "0 auto",
        textAlign: "left",
        '@media screen and (max-width: 900px)': {
            fontSize: "14px",
            padding: "0 20px"
        }
    },
    favorites: {
        marginTop: "25px",
        color: "white",
        borderColor: "white",
        borderRadius: "0px",
        width: "200px",
        '&:hover': {
            backgroundColor: 'white',
            color: "#00143d"
        },
    },
    remove: {
        '&:hover': {
            color: "red",
            cursor: "pointer"
        }
    },
    calc: {
        marginTop: "40px",
        boxShadow: "0px 0px 1px 0 rgba(21,21,21,.1), 0 2px 8px -1px rgba(21,21,21,.2)",
    }
})


class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            state: props.state,
            metrics: props.metrics,
            favorites: JSON.parse(localStorage.getItem("favorites")),
            navigation: this.props.location,
            slug: (this.props.location !== undefined && this.props.location.pathname !== undefined) ? this.props.location.pathname.split('/')[2].split('/')[0].replace(/-/g, ' ') : null
        };
    }

    componentDidMount() {
        // console.log(this.state.metrics)
    }

    render() {
        const { classes } = this.props;
        const removeItem = (e) => {
            let favorites = JSON.parse(localStorage.getItem("favorites"));
            // console.log(e)
            favorites.forEach(function (item, i) {
                if (item.date === e) {
                    favorites.splice(i, 1)
                }
            })
            if (favorites === undefined || favorites === [null]) {
                localStorage.setItem("favorites", JSON.stringify([""]));
            } else {
                localStorage.setItem("favorites", JSON.stringify(favorites));
            }
            window.location.reload(true)
        }
        // const editItem = (e) => {

        //     let favorites = JSON.parse(localStorage.getItem("favorites"));
        //     favorites.forEach(function (item, i) {
        //         if (item.date === e) {
        //             favorites.splice(i, 1)
        //         }
        //     })
        //     if (favorites === undefined || favorites === [null]) {
        //         localStorage.setItem("favorites", JSON.stringify([""]));
        //     } else {
        //         localStorage.setItem("favorites", JSON.stringify(favorites));
        //     }
        //     window.location.reload(true)
        // }
        const handleChange = (event) => {
            // console.log(event)
            // this.setState({ [event]: !this.state[event] });
            this.setState({ edit: event });
        };
        return (
            <div className={classes.root}>
                <h1 className={classes.heading}>{"Welcome to your favorites."}</h1>
                <p className={classes.disclamer}>All of your Favorites data is stored locally in your browser and has not been sent to ourside servers or sold to third parties. Rest assured that we value your privacy.</p>
                {(this.state.favorites !== undefined && this.state.favorites !== null && this.state.favorites.length > 0) ?
                    <div>
                        <TableContainer className={classes.table}>
                            <Table className={classes.table} aria-label="favorites table" size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Price</TableCell>
                                        <TableCell align="center">Down</TableCell>
                                        <TableCell align="center">Down Percent</TableCell>
                                        <TableCell align="center">Loan Term</TableCell>
                                        <TableCell align="center">HOA</TableCell>
                                        <TableCell align="center">Insurance</TableCell>
                                        <TableCell align="center">Interest</TableCell>
                                        <TableCell align="center">PMI</TableCell>
                                        <TableCell align="center">Tax</TableCell>
                                        <TableCell align="center">Monthly Cost</TableCell>
                                        <TableCell align="center">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.favorites.map((favorite) => (
                                        <TableRow key={uuidv4()}>
                                            <TableCell component="th" scope="row">{"$" + parseFloat(favorite.before_home_price).toLocaleString('en')}</TableCell>
                                            <TableCell align="center">{"$" + parseFloat(favorite.down_payment.toFixed(3).slice(0, -1)).toLocaleString('en')}</TableCell>
                                            <TableCell align="center">{((favorite.down_payment / favorite.before_home_price) * 100).toFixed(3).slice(0, -1) + "%"}</TableCell>
                                            <TableCell align="center">{favorite.loan_term + " Years"}</TableCell>
                                            <TableCell align="center">{"$" + favorite.monthly_hoa}</TableCell>
                                            <TableCell align="center">{"$" + favorite.monthly_insurance.toFixed(3).slice(0, -1)}</TableCell>
                                            <TableCell align="center">{"$" + favorite.monthly_interest_rate.toFixed(3).slice(0, -1)}</TableCell>
                                            <TableCell align="center">{"$" + favorite.monthly_pmi.toFixed(3).slice(0, -1)}</TableCell>
                                            <TableCell align="center">{"$" + favorite.monthly_poperty_tax.toFixed(3).slice(0, -1)}</TableCell>
                                            <TableCell align="center">{"$" + favorite.monthly_estimated_price}</TableCell>
                                            <TableCell align="center">{
                                                <div>
                                                    <span className={classes.remove} onClick={() => removeItem(favorite.date)}><Delete /></span>
                                                    <span className={classes.remove} onClick={() => handleChange(favorite.date)}><Edit /></span>
                                                    <span className={classes.remove} onClick={() => {
                                                        navigator.clipboard.writeText(
                                                            `${window.location.origin}/mortgage-calculator/${this.state.slug}?home_price=${favorite.before_home_price}&monthly_estimated_price=${favorite.monthly_estimated_price}&down_payment=${favorite.down_payment}&interest_rate=${favorite.interest_rate}&loan_term=${favorite.loan_term}&monthly_hoa=${favorite.monthly_hoa}&mortgage_insurance_rate=${favorite.mortgage_insurance_rate}&annual_insurance=${favorite.annual_insurance}&annual_tax=${favorite.annual_tax}&additional_principal_payment=${favorite.additional_principal_payment}`
                                                        )
                                                    }}><Share /></span>
                                                </div>
                                            }</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className={classes.calc}>
                            {this.state.favorites.map((favorite) => (
                                (favorite.date === this.state.edit) ?
                                    <MortgageCalculator key={uuidv4()} byline={"Edited mortgage estimates will be added as a new item to the bottom of the list."} data={favorite} title={"Editing Estimate"} />
                                    :
                                    <></>
                            ))}
                            <MortgageCalculator key={uuidv4()} title={"Would you like to add another?"} />
                        </div>
                    </div>
                    :
                    <div>
                        <h2>We're sorry, it doesn't look like you have any Favorites yet.</h2>
                        <div className={classes.calc}>
                            <MortgageCalculator key={uuidv4()} title={"Would you like to add one?"} />
                        </div>
                    </div>
                }
                {/* <Calculators /> */}
            </div>
        )
    }
}
export default withStyles(styles, { withTheme: true })(Footer);





// {(this.state[favorite.date] === true) ?
//     <table>
//         <tbody>
//             <tr><td>Content</td></tr>
//             <tr><td>Content</td></tr>
//         </tbody>
//     </table>
//     :
//     <></>
// }