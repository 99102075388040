import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { v4 as uuidv4 } from 'uuid';
import { Grid, Card, Link, CardMedia, Typography} from '@material-ui/core';

const styles = theme => ({
    topicContainer: {
        maxWidth: "1000px",
        magin: "0 auto",
    },
    topic: {
        maxWidth: "300px",
        margin: "15px",
    },
    card: {
        minHeight: "440px"
    },
    media: {
        height: '250px', // 16:9
        backgroundAttachment: "static", // "fixed"
        backgroundPosition: "center",
        '@media screen and (max-width: 700px)': {
            height: "250px",
        }
    },
    articleHeading: {
        fontSize: "16px",
        fontWeight: "400",
        letterSpacing: "0px",
        textAlign: "left",
        padding: "10px 15px 4px 15px",
        color: "black",
        textTransform: "capitalize",
    },
    articleByline: {
        fontSize: "13px",
        letterSpacing: "0px",
        textTransform: "capitalize",
        textAlign: "left",
        padding: "5px 15px 1px 15px",
        color: "black"
    },

})

class Post extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
    }
    // componentDidMount = () => {
    // }
    
    render() {
        const { classes } = this.props;
        return (
            <Grid key={uuidv4()} item md={4} xs={12} className={classes.topic}>
                <Card className={classes.card} variant="outlined">
                    <Link href={(this.state.link !== null)? this.state.link : null}>
                        <CardMedia image={(this.state.data.thumbnail.includes('https://')) ? this.state.data.thumbnail : "https://bankzia.com" + this.state.data.thumbnail} className={classes.media} />
                        <div>
                            <Typography className={classes.articleHeading} variant="h5" component="h5">{this.state.data.title.replace(/{{state}}/g, this.state.state).replace(/{{year}}/g, new Date().getFullYear())}</Typography>
                            <Typography className={classes.articleByline} variant="p" component="p"><strong>By:</strong> {this.state.data.author} | <strong>Published:</strong> {this.state.data.date}</Typography>
                            <Typography className={classes.articleByline} variant="p" component="p" style={{ paddingBottom: "25px" }}>{this.state.data.excerpt.substring(0, 100).replace(/{{state}}/g, this.state.state).replace(/{{year}}/g, new Date().getFullYear())}</Typography>
                        </div>
                    </Link>
                </Card>
            </Grid>
        )
    }
}
export default withStyles(styles, { withTheme: true })(Post);
