import React from 'react';
import Helmet from "react-helmet";

class Meta extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      image: "https://bankzia.com" + props.image,
    };
  }

  // componentDidMount() {
  // PLACEHOLDER
  // }


  render() {
    return (
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(this.state.data)}</script>
      </Helmet>
    )
  }
}
export default Meta;


                                // "@context": "https://schema.org",
                                // "@type": "WebPage",
                                // "name": this.state.pageTitle,
                                // "description": this.state.pageExcerpt,
                                // "mainEntityOfPage": this.state.pageExcerpt,
                                // "about": this.state.pageExcerpt,
                                // "headline": this.state.pageTitle,
                                // "alternativeHeadline": this.state.pageExcerpt,
                                // "image": (this.state.pageThumbnail.includes('https')) ? this.state.pageThumbnail : `https://bankzia.com/${this.state.pageThumbnail}`,
                                // "primaryImageOfPage": (this.state.pageThumbnail.includes('https')) ? this.state.pageThumbnail : `https://bankzia.com/${this.state.pageThumbnail}`,
                                // "specialty": this.state.pageKeywords,
                                // "keywords": this.state.pageKeywords,

                                // "genre": this.state.postCategories,  
                                // "wordcount": (this.state.sections !== undefined)? this.state.pageContent.split(' ').length + JSON.stringify(this.state.listBody).split(' ').length + JSON.stringify(this.state.sections).split(' ').lengt : this.state.pageContent.split(' ').length + JSON.stringify(this.state.listBody).split(' ').length,
                            //     "url": `https://bankzia.com/${this.state.slug}`,
                            //     "text": this.state.pageContent,
                            //     "mainContentOfPage": this.state.pageContent,
                            //     // "hasPart": sections,
                            //     "itemListElement": sections,
                            //     "editor": "Bankzia",
                            //     "publisher": {
                            //         "@id": "https://bankzia.com/",
                            //         "@type": "Organization",
                            //         "name": "Bankzia",
                            //         "url": "https://bankzia.com",
                            //         "logo": {
                            //             "@type": "ImageObject",
                            //             "url": "https://bankzia.com/logo512.png",
                            //             "width": "512",
                            //             "height": "512"
                            //         }
                            //     },
                            // }
                            // , {
                            //     "@context": "https://schema.org",
                            //     "@type": "BreadcrumbList",
                            //     "itemListElement": [{
                            //                 "@type": "ListItem",
                            //                 "position": 1,
                            //                 "item":{
                            //                     "@id": "https://bankzia.com/",
                            //                     "name": "Bankzia | Home"
                            //                 }
                            //             },{
                            //                 "@type": "ListItem",
                            //                 "position": 2,
                            //                 "item":{
                            //                     "@id": `https://bankzia.com/${this.state.slug}`,
                            //                     "url": `https://bankzia.com/${this.state.slug}`,
                            //                     "name": this.state.pageTitle
                            //                 }
                            //         }]
                            //     }
                            // }} />}