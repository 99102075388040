import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from '@material-ui/core';
import Meta from "../components/Meta";
// import { Redirect } from "react-router-dom"
// import TopicsAndArticles from "../components/TopicsAndArticles";

const styles = theme => ({
    body: {
        maxWidth: "1000px",
        marginTop: "80px",
        margin: "0 auto",
        float: "left",
        wordWrap: "break-word",
        textOverflow: "wrap",
        textAlign: "left"
    },
    grid: {
        maxWidth: '800px',
        marginTop: '100px',
        margin: '0 auto',
        padding: "10px",
    },
    container: {
        maxWidth: "70%",
        margin: "0 auto",
        marginTop: "100px",
    },
    title: {
        fontFamily: "'DM Serif Display',Garamond,serif",
        fontWeight: 800,
        lineHeight: 1,
        fontSize: "2.3rem",
        textAlign: "left",
        letterSpacing: "-1px",
        maxWidth: "1300px",
        width: "80%",
        margin: "0 auto",
        padding: "50px 20px 15px 20px",
        color: "black",
    },
    description: {
        // borderBottom: "1px solid black",
        textAlign: "left",
        fontSize: "20px",
        letterSpacing: "-1px",
        maxWidth: "1300px",
        width: "80%",
        margin: "0 auto",
        // marginBottom: "-60px",
        padding: "0px 20px 15px 20px",
        color: "black",
        marginBottom: "100px"
    },
})

class PagesV2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: '',
        }
    }

    componentDidMount = () => {
        this.setState({
            isLoaded: true,
        })
    }

    render() {
        const { error, isLoaded } = this.state;
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            const { classes } = this.props;
            return (
                <div className={classes.root}>
                        <Meta
                            screen={"error"}
                            title={'Not Found'}
                            description={'Sorry, we were unable to find what you were looking for.'}
                            keywords={'Bankzia mortgage, loan, and personal finance tools.'}
                            image={'https://images.unsplash.com/photo-1488998527040-85054a85150e?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'}
                            url={`https://bankzia.com/`}
                        />
                    <Grid container className={classes.container}>
                        <Typography className={classes.title} variant="h1" component="h1">404 - Page not found</Typography>
                        <Typography className={classes.description} variant="p" component="p">Sorry, we couldn't find what you were looking for. but please enjoy these articles and topics from our site</Typography>
                    </Grid>
                    {/* <TopicsAndArticles
                        title={"Articles"}
                        byline={"Check out some of our latest articles."}
                        contentType={"articles"}
                        layout={"grid"}
                        titleTextColor={"black"}
                        cellTextColor={"black"}
                        backgroundColor={"#eee"}
                        cellColor={"white"}
                    /> */}
                </div>
            )
        }
    }
}
export default withStyles(styles, { withTheme: true })(PagesV2);