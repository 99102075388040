import React from 'react';
import { Grid, AppBar, Toolbar, Typography, IconButton, SwipeableDrawer, List, ListItem, ListItemText } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import menus from "../menus.json";
// import {Menu, LocationCity, SettingsOutlined, ClearAllOutlined, ColorLensSharp, PermMediaSharp, SupervisorAccountSharp, HistorySharp} from '@material-ui/icons/';
// import logo from '../assets/logo.png';
import { v4 as uuidv4 } from 'uuid';
import { withStyles } from "@material-ui/core/styles";
import postlist from "../posts.json";
import NavbarArticle from "./ui_elements/navbar/NavbarArticle.js";
// import useMediaQuery from '@material-ui/core/useMediaQuery';

// import useMediaQuery from '@material-ui/core/useMediaQuery';

const styles = theme => ({
  grid: {
    // margin: "20px"
  },
  button: {
    width: "250px",
    '&:focus': {
      backgroundColor: "#002884",
      color: "white"
    }
  },
  appbar: {
    backgroundColor: "white",
    boxShadow: "0px 5px 5px 0 rgba(100,100,100,.2), 0 1px 5px -1px rgba(100,100,100,.3)",
    // display: "flex",
    // flexDirection: "row",
    // justifyContent: "center",
    // '@media screen and (max-width: 900px)': {
    // marginBottom: "-200px",
    // margin: "0 auto"
    // }
  },
  navbar: {
    width: '100%',
    // marginBottom: "-5px",
    marginBottom: "60px"
  },
  toolbar: {
    margin: "0 auto",
    '@media screen and (min-width: 900px)': {
      maxWidth: "1600px",
      // margin: "0 auto"
    }
  },
  menuButton: {
    color: "black",
    '&:focus': {
      backgroundColor: "transparent",
      color: "black"
    },
    '@media screen and (min-width: 900px)': {
      display: "none",
    }
  },
  list: {
    width: '100%',
    maxWidth: "320px",
    minWidth: "240px",
    backgroundColor: theme.palette.background.paper,
    paddingTop: "25% !important"
  },
  listItem: {
    color: "black",
    paddingTop: "0px !important",
    paddingBottom: "0px !important"
  },
  nestedListItem: {
    color: "black",
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
    marginLeft: "10px"
  },
  // listItemIcon: {
  //   marginTop: "-5px",
  //   size: "13px"
  // },
  title: {
    textAlign: "center",
    flexDirection: "column",
    flex: "auto",
    fontFamily: "'CircularStd-Bold',Arial,sans-serif",
    letterSpacing: "1px",
    textDecoration: "none",
    color: "black",
    fontWeight: 600,
    fontSize: "1.6rem",
    marginLeft: "-20px",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  name: {
    fontSize: "11px",
    maxWidth: "100px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginLeft: "-45px"
  },
  icon: {
    marginBottom: "-7px",
    marginRight: "4px"
  },
  logo: {
    marginLeft: "-45px",
    margin: "0 auto",
    '@media screen and (min-width: 900px)': {
      justifyContent: "left",
      marginLeft: "0px"
    }
  },

  desktopLinks: {
    textAlign: "right",
    '@media screen and (max-width: 900px)': {
      display: "none",
    }
  },
  desktopItem: {
    color: "black",
    padding: "0px 20px",
    paddingTop: "80px",
    paddingBottom: "18px",
    textDecoration: "none",
    fontSize: "15px",
    '&:hover': {
      backgroundColor: "#f2f7ff",
      borderBottom: "4px solid #0157ff",
      color: "#0157ff",
    },
  }
});

class NavTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      credentials: null,
      isLoaded: false,
    }
  }
  componentDidMount = () => {
    this.setState({
      isLoaded: true,
      left: false
    });
  }


  // DESKTOP MENU ITEM
  menuItem = (prop) => {
    this.setState({ [prop + "_open"]: true, open: true })
    // USE TIMEOUT INCASE THE USER DOESN'T HOVER OVER THE SUBNAV ITEMS
    setTimeout(() => {
      if (this.state[prop + "_open"] === true && this.state[prop + "_hover"] === false) {
        this.setState({
          [prop + "_open"]: this.state[prop + "_open"],
          open: true
        })
      }
    }, 600)
  }
  // DESKTOP MENU ITEM NAVIGATED AWAY
  menuItemLeft = (prop) => {
    setTimeout(() => {
      if (this.state[prop + "_hover"] === false) {
        this.setState({ [prop + "_open"]: false, open: false })
      }
    }, 600)
  }
  // MOBILE MENU ITEM
  mobileMenuItem = (prop) => {
    this.setState({ [prop]: !this.state.prop })
  }

  drawerClicked = (prop) => {
    this.setState({ left: prop })
  }
  
  render() {
    const { classes } = this.props;
    const list = () => (
      <List className={classes.list}>
        <ListItem button component="a" href="/">
          <ListItemText className={classes.listItem} primary="Home" />
        </ListItem>

        {/* MORTGAGE */}
        <ListItem button >
          <ListItemText className={classes.listItem} primary="Mortgage" onClick={() => this.setState({ "mortgage": !this.state.mortgage })} />
          {(this.state.mortgage === true) ? <ExpandMoreIcon /> : <ChevronRightIcon />}
        </ListItem>
        {(this.state.mortgage === true) ?
          <ListItem button component="a" href="/mortgage-calculator/">
            <ListItemText className={classes.nestedListItem} primary="Mortgage Calculator" />
          </ListItem>
          : <></>}

        {/* AUTO */}
        <ListItem button>
          <ListItemText className={classes.listItem} primary="Auto" onClick={() => this.setState({ "auto": !this.state.auto })} />
          {(this.state.auto === true) ? <ExpandMoreIcon /> : <ChevronRightIcon />}
        </ListItem>
        {(this.state.auto === true) ?
          <div>
            <ListItem button component="a" href="/auto-loan-calculator/">
              <ListItemText className={classes.nestedListItem} primary="Auto Loan Calculator" />
            </ListItem>
            <ListItem button component="a" href="/auto-budget-calculator/">
              <ListItemText className={classes.nestedListItem} primary="Auto Budget Calculator" />
            </ListItem>
          </div>
          : <></>}

        <ListItem button component="a" href="/favorites/">
          <ListItemText className={classes.listItem} primary="Favorites" />
        </ListItem>
        <ListItem button component="a" href="/about/">
          <ListItemText className={classes.listItem} primary="About" />
        </ListItem>
        <ListItem button component="a" href="/contact/">
          <ListItemText className={classes.listItem} primary="Contact" />
        </ListItem>
        <ListItem button component="a" href="/privacy-policy/">
          <ListItemText className={classes.listItem} primary="Privacy Policy" />
        </ListItem>
      </List>
    );

    return (
      <div className={classes.navbar}>
        <AppBar position="fixed" className={classes.appbar}>
          <Toolbar>
            <SwipeableDrawer
              open={this.state.left}
              onClose={() => this.drawerClicked(false)}
              onOpen={() => this.drawerClicked(true)}
            >
              {list()}
            </SwipeableDrawer>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={() => this.drawerClicked(true)}>
              <MenuIcon />
            </IconButton>
            <Grid container direction="row" alignItems="center" className={classes.toolbar}>
              <Grid item md={3} s={12} xs={12}>
                <Typography variant="p" button component="a" href="/" className={classes.title}>Bankzia</Typography>
                {/* Motozia leaselia leasezia Truckzia Nestzia Fiscalia BankZia*/}
              </Grid>
              <Grid item md={8} className={classes.desktopLinks}>
                {menus.map((menu, index) => {
                  return (
                    (menu.position === "Header") ?
                      menu["Menu Item"].map((subitem, i) => {
                        return (
                          (subitem["List Item"] !== undefined) ?
                            // ITEM WITH SUB NAV
                            <Typography key={uuidv4()} variant="p" button component="a" href={(subitem.slug !== undefined) ? `/${subitem.title.toLowerCase()}/` : null} className={classes.desktopItem} onMouseEnter={() => this.menuItem(subitem.title.toLowerCase())} onMouseLeave={() => this.menuItemLeft(subitem.title.toLowerCase())}>{subitem.title.toLowerCase()}</Typography>
                            :
                            // SINGLE LINK MENU ITEM
                            <Typography key={uuidv4()} variant="p" button component="a" href={(subitem.slug !== undefined) ? `/${subitem.title.toLowerCase()}/` : null} className={classes.desktopItem}>{subitem.title.toLowerCase()}</Typography>
                        )
                      })
                      : null
                  )
                })}
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        {menus.map((menu, index) => {
          return (
            (menu.position === "Header") ?
              menu["Menu Item"].map((subitem, i) => {
                if (subitem["List Item"] !== undefined) {
                  return (
                    (this.state.open === true && this.state[subitem.title.toLowerCase().concat("_open")] === true) ?
                      <Grid key={uuidv4()} container style={{ boxShadow: "0 0 1px 0 rgba(21,21,21,.1), 0 2px 8px -1px rgba(21,21,21,.2)", position: "fixed", zIndex: 100, backgroundColor: "white", width: "100vw", marginTop: "0px", padding: "20px" }} onMouseEnter={() => this.setState({ [subitem.title.toLowerCase().concat("_open")]: true, [subitem.title.toLowerCase().concat("_hover")]: true })} onMouseLeave={() => this.setState({ open: false, [subitem.title.toLowerCase().concat("_open")]: false, [subitem.title.toLowerCase().concat("_hover")]: false })}>
                        <Grid item key={uuidv4()} md={12}>
                          {subitem["List Item"].map((subsubitem, i) => {
                            return (
                              <Typography key={uuidv4()} variant="p" button component="a" href={`/${subsubitem.slug}/`} className={classes.desktopItem}>{subsubitem.title}</Typography>
                            )
                          })}
                        </Grid>
                        <Grid container direction="row" alignItems="center" style={{ paddingTop: "20px", maxWidth: "1200px", margin: "0 auto" }}>
                          <Grid item md={!2} style={{ width: "100%", textAlign: "left", marginBottom: "-80px" }}>
                            <Typography variant="p">Recent Articles</Typography>
                          </Grid>
                          {postlist.map((post, i) => {
                            // { console.log(JSON.stringify(post.categories).toLowerCase().includes(subitem.title.toLowerCase().replace('s', ''))) }
                            return (
                                (i <= 4 && JSON.stringify(post.menumatch).toLowerCase().includes(subitem.title.toLowerCase())) ?
                                  <NavbarArticle key={uuidv4()} data={post} />
                                : <></>
                            )
                          })}
                        </Grid>
                      </Grid>
                      : <></>
                  )
                } else { return (<></>) }
              })
              : <></>
          )
        })}
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(NavTabs);