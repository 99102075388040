import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { TextField } from '@material-ui/core';

// https://stackoverflow.com/questions/38394015/how-to-pass-data-from-child-component-to-its-parent-in-reactjs

const styles = theme => ({
    textField: {
        [`& fieldset`]: {
            borderRadius: 0,
            backgroundColor: "rgba(255, 255, 255, 0.3)",
            '@media screen and (max-width:500px)': {
                width: "80vw",
            }
        },
    }
})

class Post extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
    }

    // componentDidMount = () => {
    // }


    render() {
        const { classes } = this.props;
        const handleLangChange = e => {
            this.props.onGetRecaptcha();
        }
        return (
            <TextField
                label={this.state.label}
                variant="outlined"
                className={classes.textField}
                style={{
                    width: this.state.width,
                    margin: "0px 20px auto",
                }}
                onChange={event => {
                    // const { value } = event.target;
                    if (event.target.value.includes('@') && event.target.value.includes(".")) {
                        handleLangChange(event.target.value)
                    }
                }}
            />
        )
    }
}
export default withStyles(styles, { withTheme: true })(Post);

                                // onChange={event => {
                                //     const { value } = event.target;
                                //     if (event.target.value.includes('@') && event.target.value.includes(".")) {
                                //         this.setState({ recaptcha: true });
                                //     }
                                // }}